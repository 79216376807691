import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "./Common/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./Routes/Routing";

import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { isLoggedInSelector, isUserLoggedInAction } from "./Redux/user/user";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import useModalWindowControls from "./Hooks/useModal";
import RecheckPaymentMethod from "./Common/PaymentMethod/RecheckPaymentMethod";
import { getUser, getUserID } from "./Service/getLocalStorage";
import * as magnetScreens from "./MagnetScreens";
import * as brightHorizonsScreens from "./BrightHorizonsScreens";
import { getUserProfileEdit } from "./Service/update_profile";
import { pushPageViewEvent } from "./Utils/gtm";
import { usePostHog } from "posthog-js/react";
import * as routeConstent from "./Routes/RouteConstent";
import * as Screens from "./Screens";

// fix posthog bug with MuiDialog tabindex
window.addEventListener("load", () => {
  const isPostHogRunning = document.querySelector("#__POSTHOG_TOOLBAR__");

  if (isPostHogRunning) {
    setInterval(() => {
      const blockPostHogElements = document.querySelectorAll("[tabindex='-1']");

      blockPostHogElements.forEach?.((e: any) => {
        e?.removeAttribute("tabindex");
      });
    }, 1000);
  }
});

function App() {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(isLoggedInSelector);
  const user = getUser();

  React.useEffect(() => {
    const user = localStorage.getItem("Nurture_user_data");
    user && dispatch(isUserLoggedInAction(true));
  }, [dispatch]);

  React.useEffect(() => {
    const getLatestUserData = async () => {
      try {
        const { data }: any = await getUserProfileEdit({
          user_id: getUserID(),
        });

        if (data.success) {
          localStorage.setItem(
            "Nurture_user_data",
            JSON.stringify({
              ...getUser(),
              ...data.about_you,
              family: data.about_your_partner,
              baby: data.about_your_baby,
            })
          );
        }
      } catch (err) {}
    };

    if (isUserLoggedIn) {
      getLatestUserData();
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    const user = getUser();

    if (isUserLoggedIn && user) {
      posthog.identify(user?.id, {
        email: user?.email,
        name: user?.name,

        user_first_name: user?.first_name,
        user_last_name: user?.last_name,
        user_email: user?.email,
        user_phone: user?.phone,
        create_account_expected_due_date: user?.dob_date,
        how_did_you_hear_about_us: user?.hear_about_us,

        user_birthday: user?.dob,
        user_zip_code: user?.zip_code,
        what_best_describes_you: user?.type_mom,
        what_is_your_current_life_status: user?.current_life_status,

        partner_first_name: user?.family?.first_name,
        partner_last_name: user?.family?.last_name,
        partner_email: user?.family?.email,
        are_you_currently_expecting: user?.baby?.pregnant,
        baby_expected_due_date: user?.baby?.due_date,
        is_this_your_first_baby: user?.baby?.is_this_your_first_baby,

        free_membership: user?.is_stripe_membership == 0,
        monthly_membership: user?.is_stripe_membership == 1,
        annual_membership: user?.is_stripe_membership == 2,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  const recheckPaymentMethodModal = useModalWindowControls();

  React.useEffect(() => {
    if (
      isUserLoggedIn &&
      user?.is_email_verify &&
      user?.user_need_recheck_payment
    ) {
      recheckPaymentMethodModal.openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn, user?.is_email_verify, user?.user_need_recheck_payment]);

  const navigate = useNavigate();
  React.useEffect(() => {
    const logOutCB = () => {
      posthog.reset(true);
      window.dispatchEvent(new Event("storage"));
      localStorage.clear();
      dispatch(isUserLoggedInAction(false));

      navigate("/");
    };

    window.addEventListener("userLogOut", logOutCB);

    return () => window.removeEventListener("userLogOut", logOutCB);
  }, [dispatch, navigate]);

  const location = useLocation();

  React.useEffect(() => {
    pushPageViewEvent();
    posthog.capture("$pageview");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (
    magnetScreens.navigateLinksList.some((url: string) =>
      location.pathname.includes(url)
    )
  ) {
    return <magnetScreens.MagnetRouter />;
  }

  if (
    brightHorizonsScreens.navigateLinksList.some((url: string) =>
      location.pathname.includes(url)
    )
  ) {
    return <brightHorizonsScreens.BrightHorizonsRouter />;
  }

  if (location.pathname.startsWith("/bump")) {
    return (
      <>
        <ToastContainer />
        <Routes>
          <Route path={routeConstent.BUMP} element={<Screens.BUMP />} />
        </Routes>
      </>
    );
  }

  return (
    <>
      <ToastContainer />
      {!!(
        isUserLoggedIn &&
        user?.is_email_verify &&
        user?.user_need_recheck_payment
      ) && (
        <RecheckPaymentMethod
          recheckPaymentMethodModal={recheckPaymentMethodModal}
        />
      )}
      <Header />
      <div className="page-inner-section">
        <Routing />
      </div>
    </>
  );
}

export default App;
