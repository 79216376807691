
import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../Assets/img/login/signup.svg"
import Eye from "../../Assets/img/eye.svg"
import { HOME, LOGIN, VERIFYEMAIL } from '../../Routes/RouteConstent';
import { VERIFYEMAILPOPUP } from '../../Routes/RouteConstent';
import { userSignup } from '../../Service/userAuth';
import { emailRegex, passwpordRegex } from '../../Utils/Regex';
import "./Signup.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { phoneRegex } from '../constant';
import { isUserLoggedInAction } from '../../Redux/user/user';
import { useDispatch } from 'react-redux';
import { usePostHog } from 'posthog-js/react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const SignupPopup = (props: any) => {
    const posthog = usePostHog();
    const dispatch = useDispatch();
    let { isSignup, setIsSignup, setIslogin, setIsVerifyEmail, setData, handelClose } = props;
    let Navigate = useNavigate()
    let [signupData, setSignupData] = useState<any>({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        dob_date: null,
    })
    let [error, setError] = useState<any>({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        dob_date: "",
    })
    let [loading, setLoading] = useState(false)
    // const [open, setOpen] = React.useState(false);
    const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
    const [isPasswordShow2, setIsPasswordShow2] = useState<boolean>(false);
    const handleClickOpen = () => {
        setIsSignup(true);
    };
    const toggleIsPasswordShowValue = () => {
        setIsPasswordShow(!isPasswordShow);
    };
    const toggleIsPasswordShowValue2 = () => {
        setIsPasswordShow2(!isPasswordShow2);
    };
    const handleClose = (_e: any, type: any) => {
        if (type === "backdropClick") {
            return;
        }
        setIsSignup(false);
    };

    let handleChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "hear_about_us") {
            if (e.target.checked) {
                setSignupData({
                    ...signupData,
                    [name]: value
                })
            }
            return
        }
        if (name === "phone") {
            if (!phoneRegex.test(value)) {
                setError({
                    ...error,
                    [name]: "Please enter valid contact no."
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        if (name === "first_name") {
            if (value.length < 2) {
                setError({
                    ...error,
                    [name]: 'Minium 2 characters required'
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        if (name === "last_name") {
            if (value.length < 2) {
                setError({
                    ...error,
                    [name]: 'Minimum 2 characters required'
                })
            } else {
                setError({
                    ...error,
                    [name]: ''
                })
            }
        }
        if (name === "email") {
            if (!emailRegex.test(value)) {
                setError({
                    ...error,
                    [name]: "Email in not valid"
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        if (name === "password") {
            if (!passwpordRegex.test(value)) {
                setError({
                    ...error,
                    [name]: "Password should contain 6 to 20 characters including one numeric digit, one uppercase letter, one lowercase letter and one special character"
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        if (name === "password_confirmation") {
            if (value !== signupData.password) {
                setError({
                    ...error,
                    [name]: "Password and confirm password are not matching"
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        setSignupData({
            ...signupData,
            [name]: value
        })
    }
    const errors: any = {}
    let signSubmit = async (e: any) => {
        e.preventDefault();
        try {
            if (!signupData.dob_date || !signupData.first_name || signupData.first_name.length < 2 ||
                !signupData.last_name || signupData.last_name.length < 2 || !signupData.email || !signupData.phone || !phoneRegex.test(signupData.phone) || signupData.phone.length < 10 ||
                !emailRegex.test(signupData.email) || !signupData.password || !passwpordRegex.test(signupData.password)
                || !signupData.password_confirmation || signupData.password !== signupData.password_confirmation) {
                if (signupData.first_name.length < 2) {
                    errors.first_name = "Minimum 2 character required"
                }
                if (!signupData.first_name) {
                    errors.first_name = "First name is Required"
                }
                if (signupData.last_name.length < 2) {
                    errors.last_name = "Minimum 2 character required"
                }
                if (!signupData.last_name) {
                    errors.last_name = "Last name is required"
                }
                if (!emailRegex.test(signupData.email)) {
                    errors.email = "Please enter valid email."
                }
                if (!signupData.email) {
                    errors.email = "Email is required"
                }
                if (!phoneRegex.test(signupData.phone)) {
                    errors.phone = "Enter a valid contact no."
                }
                if (signupData.phone?.length < 10) {
                    errors.phone = "Please enter a valid contact no."
                }
                if (!signupData.phone) {
                    errors.phone = "Contact no. is required"
                }
                if (!signupData.dob_date) {
                    errors.dob_date = "Due Date/Child's Birthday is required."
                }
                if (!passwpordRegex.test(signupData.password)) {
                    errors.password = "Password should contain 6 to 20 characters including one numeric digit, one uppercase letter, one lowercase letter and one special character"
                }
                if (!signupData.password) {
                    errors.password = "Password is required"
                }
                if (signupData.password !== signupData.password_confirmation) {
                    errors.password_confirmation = "Password and Confirm password are not matching"
                }
                if (!signupData.password_confirmation) {
                    errors.password_confirmation = "Confirm password is required"
                }
                await setError(errors)
            } else {
                setLoading(true)
                let res = await userSignup(signupData)
                let isSuccess = res.data.success;
                let message = res.data.message;
                let msg = '';
                for (let i = 0; i < message.length; i++) {
                    msg += message[i];
                }
                if (!isSuccess) {
                    toast.error(msg)
                }
                if (isSuccess) {
                    let user_id = res?.data?.data?.id;
                    localStorage.setItem("Nurture_user_data", JSON.stringify(res?.data?.data));
                    localStorage.setItem("Nurture_user_token", res?.data?.token);
                    let email = res.data.data.email;
                    toast.success(msg)
                    setData({ user_id, email })
                    setIsVerifyEmail(true)
                    setIsSignup(false)
                    window.dispatchEvent(new Event("storage"))
                    // Navigate(VERIFYEMAILPOPUP, { state: { user_id, email } })
                    dispatch(isUserLoggedInAction(true));


                    posthog.capture('user-account-created', {
                        userId: user_id,
                    });

                    const personProperties: {[key: string]: string | boolean} = {
                        user_first_name: res?.data?.data?.first_name,
                        user_last_name: res?.data?.data?.last_name,
                        user_email: res?.data?.data?.email,
                        user_phone: res?.data?.data?.phone,
                        expected_due_date: res?.data?.data?.dob_date,
                        how_did_you_hear_about_us: res?.data?.data?.hear_about_us,
                    };
                    if (localStorage.getItem("userLandedOnBumpPage")) {
                        personProperties.BUMP_user = true;
                        localStorage.removeItem("userLandedOnBumpPage");
                    }
                    posthog.capture("set_person_property/signup_via_popup", {
                        $set: personProperties,
                    });

                    
                }
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    const dateHandler = (value: Date | null) => {
        setSignupData((d: typeof signupData) => ({
            ...d,
            dob_date: value
        }));
        setError((errors: typeof signupData) => ({
            ...errors,
            dob_date: ""
        }));
    };

    let Login = () => {
        setIsSignup(false);
        setIslogin(true);
    }
    const modalClose = () => {
        setIsSignup(false)
        handelClose()
    }
    return (
        <div>

            <div className="login-inner">
                <div>

                    <Dialog
                        open={isSignup}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="signup-poupmodal modal-500"
                    >
                        {/* <DialogTitle id="alert-dialog-title">
                                    {"Use Google's location service?"}
                                </DialogTitle> */}
                        <IconButton sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}  >

                            <CloseIcon onClick={modalClose} />

                        </IconButton>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div>

                                    <div className="container-fluid sign-pop signup-page">
                                        <div className="row">
                                            <div className="login-inner pt-5">
                                                <h1 className="heading">Create an Account</h1>
                                                <form onSubmit={signSubmit} autoComplete="off">
                                                    <div className="form-group mb-3">
                                                        <label>First Name</label>
                                                        <input type="text" name="first_name" autoComplete="off"
                                                            className={`${error.first_name && "error-input"} form-control`} placeholder="Enter first name" value={signupData.first_name} onChange={handleChange} />
                                                        <p className="error">{error.first_name}</p>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label>Last Name</label>
                                                        <input type="text" name="last_name" autoComplete="off"
                                                            className={`${error?.last_name && "error-input"} form-control`} placeholder="Enter last name" value={signupData.last_name} onChange={handleChange} />
                                                        <p className="error">{error?.last_name}</p>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label>Email</label>
                                                        <input type="email" name="email" autoComplete="off"
                                                            className={`${error?.email && "error-input"} form-control`} placeholder="Enter last Email" value={signupData.email} onChange={handleChange} />
                                                        <p className="error">{error?.email}</p>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label>What is your contact number?</label>
                                                        <input type="number" name="phone" min="0" autoComplete="off"
                                                            onChange={handleChange} value={signupData?.phone} className={`${error.phone && "error-input"} form-control`} placeholder="Contact no." />
                                                        <p className='error'>{error?.phone}</p>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label>Due Date/Child's Birthday</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Stack spacing={3}>
                                                                <MobileDatePicker
                                                                    toolbarTitle="Choose date"
                                                                    value={signupData?.dob_date || ""}
                                                                    onChange={dateHandler}
                                                                    renderInput={(params: any) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder="MM-DD-YYYY"
                                                                            className={`${error.dob_date && "MuiInputBase-error-input"} `}
                                                                        />
                                                                    )}
                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>
                                                        <p className='error'>{error.dob_date}</p>
                                                    </div>
                                                    <hr className='custom' />
                                                    <div className="form-group create_pass mb-3">
                                                        <label>Create a Password</label>
                                                        <input
                                                            //type="password"
                                                            autoComplete="off"
                                                            type={isPasswordShow ? 'text' : 'password'}
                                                            name="password"
                                                            className={`${error?.password && "error-input"} form-control`} placeholder="Create a Password" value={signupData.password} onChange={handleChange} />
                                                        <img src={Eye} alt="" onClick={toggleIsPasswordShowValue} className="eye-passimg" />
                                                        <p className="error">{error.password}</p>

                                                    </div>
                                                    <div className="form-group create_pass mb-3">
                                                        <label>Re-Enter a Password</label>
                                                        <input
                                                            //type="password"
                                                            autoComplete="off"
                                                            role="presentation"
                                                            name="password_confirmation"
                                                            type={isPasswordShow2 ? 'text' : 'password'}
                                                            className={`${error?.password_confirmation && "error-input"} form-control`} placeholder="Re-Enter a Password" value={signupData.password_confirmation} onChange={handleChange} />
                                                        <img src={Eye} alt=""
                                                            onClick={toggleIsPasswordShowValue2}
                                                            className="eye-passimg" />
                                                        <p className="error">{error.password_confirmation}</p>
                                                    </div>

                                                    <div className="radio-area mb-3">
                                                        <h4> How did you hear about us? <span className='fw-light'>(optional)</span></h4>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" name='hear_about_us' value="social media" className="form-check-input" id="popUpRadioHearAboutUs1" onChange={handleChange} />
                                                            <label className="custom-control-label" htmlFor="popUpRadioHearAboutUs1">Social media</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" name='hear_about_us' value="internet search" className="form-check-input" id="popUpRadioHearAboutUs2" onChange={handleChange} />
                                                            <label className="custom-control-label" htmlFor="popUpRadioHearAboutUs2">Internet search</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" name='hear_about_us' className="form-check-input" value="print" id="popUpRadioHearAboutUs3" onChange={handleChange} />
                                                            <label className="custom-control-label" htmlFor="popUpRadioHearAboutUs3">Print</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" name='hear_about_us' className="form-check-input" value="friend" id="popUpRadioHearAboutUs4" onChange={handleChange} />
                                                            <label className="custom-control-label" htmlFor="popUpRadioHearAboutUs4">Friend</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" name='hear_about_us' className="form-check-input" value="healthcare professional" id="popUpRadioHearAboutUs5" onChange={handleChange} />
                                                            <label className="custom-control-label" htmlFor="popUpRadioHearAboutUs5">Healthcare professional</label>
                                                        </div>
                                                    </div>

                                                    <hr className='custom' />
                                                    <div className="bottom-createaccount">
                                                        <h2>By proceeding to create your account and use Nuture by Naps,
                                                            you are agreeing to our <a>Privacy Policy</a>  and <a>Terms of Service.</a></h2>
                                                    </div>
                                                    <button type="submit" className="btn next-button primary-blue-btn mt-3" > {loading ? <CircularProgress /> : "Sign Up"}</button>
                                                    <div className="text-center have-account">
                                                        <p>Already have an account? <a onClick={Login} >Sign  In Now</a></p>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div >

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Disagree</Button>
                                    <Button onClick={handleClose} autoFocus>
                                        Agree
                                    </Button> */}
                        </DialogActions>
                    </Dialog>
                </div>

            </div>
        </div>

    )
}

export default SignupPopup

