import "./Membership.scss";
import React, { useState } from 'react'
import "./Membership.scss";
import Congrats from "../../Assets/img/signup/Congratulations.svg";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { emailRegex } from "../../Utils/Regex";

const Gender = [
    { label: 'Boy', value: "Boy", name: 'gender' },
    { label: 'Girl', value: "Girl", name: 'gender' },
]
const GenderNo = [
    { label: 'Boy', value: "Boy", name: 'first_gender' },
    { label: 'Girl', value: "Girl", name: 'first_gender' },
]
const GenderNo2 = [
    { label: 'Boy', value: "Boy", name: 'second_gender' },
    { label: 'Girl', value: "Girl", name: 'second_gender' },
]
const Gender1 = [
    { label: 'Boy', value: "Boy", name: 'first_gender' },
    { label: 'Girl', value: "Girl", name: 'first_gender' },
    { label: 'It’s a Surprise', value: "It’s a Surprise", name: 'first_gender' },
    { label: 'It’s multiples', value: "it’s multiples", name: 'first_gender' },
]
const Gender2 = [
    { label: 'Boy', value: "Boy", name: 'second_gender' },
    { label: 'Girl', value: "Girl", name: 'second_gender' },
    { label: 'It’s a Surprise', value: "It’s a Surprise", name: 'second_gender' },
    { label: 'It’s multiples', value: "it’s multiples", name: 'second_gender' },
]

const Step2 = ({ handleSkip, value, setValue, loading, isSkip,
    handleNext, valueYes, prevValue, error, addOption, setAddOption, setvalueYes, setError, addChild, setAddChild, valueNo, setValueNo, realValue, setRealValue, feedingChoice, setFeedingChoice }: any) => {

    let handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let name = e.target.name;
        let inputValue = e.target.value;
        setError({
            ...error,
            [name]: ""
        })

        if (name === "email") {

            if (inputValue.match(emailRegex)) {
                setError({
                    ...error,
                    [name]: ""
                })

            } else {
                setError({
                    ...error,
                    [name]: "Invalid email"
                })
            }
        }

        setRealValue({
            ...realValue,
            [name]: inputValue
        })
        setValue({
            ...value,
            [name]: inputValue
        })

    }

    function yesHandleChange(event: Date | null | any, dValues?: any): void {
        let name = event?.target?.name;
        let value = event?.target?.value;
        if (event?.$d instanceof Date) {

            setError({
                ...error,
                ["due_date"]: ""
            });
            setvalueYes({
                ...valueYes,
                ["due_date"]: event
            });

            return;

        }

        if (event?.target?.id?.search("Gender") === 0) {
            setError({
                ...error,
                ["expected_baby_gender"]: ""
            })
            setvalueYes({
                ...valueYes,
                ["expected_baby_gender"]: dValues.label
            })
            return
        }
        if (name === "is_this_your_first_baby") {

            if (value === "no") {
                setvalueYes({
                    ...valueYes,
                    [name]: value,
                    ["other_baby"]: [{ name: '', dob: "", gender: "" }]
                })
            } else {
                setvalueYes({
                    ...valueYes,
                    [name]: value,
                    ["other_baby"]: []
                })
            }
            return;
        }
        setError({
            ...error,
            [name]: ""
        })
        setvalueYes({
            ...valueYes,
            [name]: event.target.value
        })


    }

    let changeHandler = (e: any) => {

        let name = e.target.name;
        let inputValue = e.target.value;
        setError({
            ...error,
            [name]: ""
        })

        setRealValue({
            ...realValue,
            [name]: inputValue
        })
        setValue({
            ...value,
            [name]: inputValue
        })

    }


    let noValueHandler = async (e: any, dValues?: any) => {

        if (e?.$d instanceof Date) {
            setError({
                ...error,
                ["dob"]: "Invalid date"
            })
            if (e?.$d) {
                setError({
                    ...error,
                    ["dob"]: ""
                })
                setValueNo({
                    ...valueNo,
                    [dValues]: e
                })
                return;
            } else {
                setValueNo({
                    ...valueNo,
                    ["dob"]: ""
                })
                setError({
                    ...error,
                    ["dob"]: "Invalid date"
                })
                return;
            }
        }
        if (e?.target?.id?.search("Gender") === 0) {

            setError({
                ...error,
                ["gender"]: ""
            })
            setValueNo({
                ...valueNo,
                [dValues?.name]: dValues.label
            })
            return
        }

        let inputValue = e?.target?.value;
        let name = e?.target?.name;

        setError({
            ...error,
            [name]: ""
        })
        setValueNo({
            ...valueNo,
            [name]: inputValue
        })
        await setValue(value)
    }
    const feedingChoiceHandler = (e: any) => {

        let inputValue = e.target.value;
        let name = e.target.name
        let arr: any
        setError({
            ...error,
            [name]: ""
        })
        if (e.target.checked) {
            arr = [...feedingChoice, inputValue]
            setFeedingChoice(arr)
        } else {
            arr = feedingChoice.filter((item: any) => item !== e.target.value)
            setFeedingChoice(arr)
        }
        setValueNo({
            ...valueNo,
            [name]: arr
        })
    }
    const handleAddChild = (val: number) => {
        if (!addChild.includes(val)) {
            setAddChild([val])
        } else if (addChild.length < 2) {
            setAddChild([...addChild, val + 1])
        }
    }
    const handleDelete = (num: number) => {
        let {
            first_child_gender,
            second_child_gender,
            another_child_gender_1,
            another_child_gender_2,
        } = valueYes;

        if (num <= 0) {
            first_child_gender = second_child_gender
        }
        if (num <= 1) {
            second_child_gender = another_child_gender_1
        }
        if (num <= 2) {
            another_child_gender_1 = another_child_gender_2
        }
        if (num <= 3) {
            another_child_gender_2 = ''
        }

        setvalueYes((v: any) => ({
            ...v,
            first_child_gender,
            second_child_gender,
            another_child_gender_1,
            another_child_gender_2,
        }))

        setAddChild((s: string[]) => s.slice(0, s.length - 1))
    }

    const handleRemoveNewChild = (id: any) => {
        let filterData = addOption.filter((rId: any) => rId != id)
        setAddOption(filterData)
        if (id === 1) {
            setValueNo({
                ...valueNo,
                ["baby_first_name1"]: "",
                ["first_gender"]: "",
                ["dob_anothor_child1"]: ""
            })
        }
        if (id === 2) {
            setValueNo({
                ...valueNo,
                ["baby_first_name2"]: "",
                ["second_gender"]: "",
                ["dob_anothor_child2"]: ""
            })
        }
    }

    const handleRemoveMainChild = () => {
        setValueNo((s: any) => ({
            ...s,
            baby_first_name: s.baby_first_name1,
            dob: s.dob_anothor_child1,
            gender: s.first_gender,
            baby_first_name1: s.baby_first_name2,
            dob_anothor_child1: s.dob_anothor_child2,
            first_gender: s.second_gender,
            baby_first_name2: '',
            dob_anothor_child2: '',
            second_gender: '',
        }))

        setAddOption([
            valueNo.baby_first_name2 ||
            valueNo.dob_anothor_child2 ||
            valueNo.second_gender
        ].filter(v => v)
            .map((_, i) => i + 1))
    }

    const handleAddNewChild = (id: number) => {

        if (addOption.includes(1) && addOption.includes(2)) return

        if (addOption.includes(1)) {
            setAddOption([...addOption, 2])
        } else
            if (addOption.includes(2)) {
                setAddOption([...addOption, 1])

            } else {
                setAddOption([1])
            }
        return;
        if (!addOption.includes(id)) {
            setAddOption([id])
        } else if (addOption.length < 2) {
            setAddOption([...addOption, id + 1])
        }
    }

    let handleCheck = async () => {

        if (!realValue.pregnant && !value?.pregnant) {
            value = realValue;
            // await setValue(value)
        }

        if (realValue?.pregnant === "yes" || value?.pregnant === "yes") {
            let obj = { ...realValue, ...valueYes, ...value }
            value = obj;
            // await setValue(value)

        }
        if (realValue?.pregnant === "no" || value?.pregnant === "no") {
            let obj1 = { ...realValue, ...value, ...valueNo }
            value = obj1;
            // await setValue(value)
        }

        await setValue(value)

        const extraChild = () => {

            if (value?.is_this_your_first_baby === "no") {
                let otherError: any = {}
                valueYes?.other_baby.map((element: any, index: any) => {
                    if (!element?.dob) {

                        otherError["dob" + [index]] = "This field is required"
                    }
                    if (!element?.name) otherError["name" + [index]] = "This field is required";
                    if (!element?.gender) otherError["gender" + [index]] = "This field is required";

                });

                if (!otherError || Object.keys(otherError).length === 0) {
                    return false
                } else {
                    return otherError
                }
            }
            return false
        }
        let errors: any = {}
        if (!value.pregnant || value.pregnant === "no" && (!value.baby_first_name || !value.dob
            || !value.feeding_choice || !value.fertility_question || !value.baby_premature) ||
            value.pregnant === "yes" && (!value.due_date || extraChild() || !value?.is_this_your_first_baby || !value.expected_baby_gender) || value.email && !emailRegex.test(value.email)) {

            if (value.email && !emailRegex.test(value.email)) {
                errors.email = "Email in not valid"
            }
            if (!value.pregnant) {
                errors.pregnant = "Please select value"
            }

            if (value.pregnant === "yes" && !value.due_date || !value.gender || !value?.c_section) {

                if (!value.due_date) {
                    errors.due_date = "Please select birth date";
                }
                if (!value.expected_baby_gender) {
                    errors.expected_baby_gender = "Please select baby's gender";
                }
                if (!value?.c_section) {
                    errors.c_section = "This field is required"
                }
                if (!value?.is_this_your_first_baby) {
                    errors.is_this_your_first_baby = "This field is required"
                }
            }
            if (value.pregnant === "no" && (!value.baby_first_name || !value.dob
                || !value.feeding_choice || !value.fertility_question || !value.baby_premature)) {

                if (!value.baby_first_name) {
                    errors.baby_first_name = "Please enter your baby's first name"
                }
                if (!value.dob) {
                    errors.dob = "Please enter birth date"
                }
                if (!value.feeding_choice) {
                    errors.feeding_choice = "Please select any option"
                }
                if (!value.fertility_question) {
                    errors.fertility_question = "Please select any option"
                }
                if (!value.baby_premature) {
                    errors.baby_premature = "Please select any option"
                }
            }
            let errorsBaby = extraChild()
            await setError({ ...errorsBaby, ...errors })
            let errorClass: any = document.getElementsByClassName("error-input")

            errorClass[0]?.scrollIntoView({ behavior: "smooth", block: "center" })
            return;
        } else {
            handleNext(value);
        }
    }

    const handleOhterChild = (param: any, index?: number) => {
        if (param === "remove") {
            setvalueYes((valueYes: any) => ({
                ...valueYes,
                other_baby: valueYes.other_baby.filter((_: any, i: number) => i !== index),
            }))
        }
        if (param === "add") {
            setvalueYes((valueYes: any) => ({ ...valueYes, other_baby: [...valueYes.other_baby, { name: '', dob: "", gender: "" }] }))
        }
    }

    const handleOtherChildData = (e: any, index: number, other?: any) => {
        let name = e?.target?.name;
        let value = e?.target?.value;
        //ish

        if (other === "dob") {
            setError({
                ...error,
                ["dob" + index]: ""
            })
        } else {
            setError({
                ...error,
                [name + index]: ""
            })
        }

        let modify = valueYes?.other_baby.map((res: any, id: number) => {
            if (index === id) {
                if (other === "dob") {
                    res[other] = e?.$d
                } else if (other === "gender") {

                    res[other] = e.label
                } else {
                    res[name] = value
                }
            }
            return res;
        })
        setvalueYes({
            ...valueYes,
            ["other_baby"]: modify
        })
    }

    const handleOtherChildGender = (index: number, value: string) => {
        setError({
            ...error,
            ["gender" + index]: ""
        });
        setvalueYes((valueYes: any) => ({
            ...valueYes,
            other_baby: valueYes.other_baby.map((baby: any, i: number) => i === index ? { ...baby, gender: value } : baby),
        }))
    };

    return (
        <div>
            
            <div className="step-innercontent step4">
                {prevValue?.current_life_status !== "I’m Single" && <div>
                    <h3>Tell us <span className="strong">about your family</span></h3>
                    <p className="mb-5 text-center">Tell us more about your family so  we can include your support person in our programming and better customize the content and events for your parenting stage!</p>
                    <form className="step-form">
                        <div className="form-group mb-3">
                            <label>Partner or Support Person’s First Name (optional)</label>
                            <input type="text" name="first_name" value={value?.first_name} onChange={handleChange} className={`${error?.first_name && "error-input"} form-control`} id="" placeholder="Name" />
                            <h5 className="error-msgtext">{error.first_name}</h5>
                        </div>

                        <div className="form-group mb-3">
                            <label>Partner or Support Person’s Last Name (optional)</label>
                            <input type="text" name="last_name" onChange={handleChange} value={value?.last_name} className={`${error?.last_name && "error-input"} form-control`} id="" placeholder="Last Name" />
                            <h5 className="error-msgtext">{error.last_name}</h5>
                        </div>
                        <div className="form-group mb-3">
                            <label>Partner or Support Person’s Email (optional)</label>
                            <input type="email" name="email"
                                onChange={handleChange} value={value?.email}
                                className={`${error?.email && "error-input"} form-control`} id="" placeholder="Email" />
                            <h5 className="error-msgtext">
                                {error.email}
                            </h5>
                        </div>
                    </form>
                </div>}
                {/* Section 2 */}
                <div>
                    <div className="step-innercontent step5">
                        <div>
                            <h3>Tell us <span className="strong">about your baby</span></h3>
                            <p className="mb-5 text-center">We use this information to better customize your experience. We do not share this information with anyone outside of our organization.</p>
                            <form className="step-form">
                                <div className="radio-area pb-4 ">
                                    <h4>Are you currently expecting?</h4>
                                    <div className="custom-radio custom-control-inline">
                                        <input type="radio" id="radioPregnant1" name="pregnant" value="yes" checked={value.pregnant === "yes"}
                                            className="custom-control-input form-check-input"
                                            onChange={changeHandler}

                                        />
                                        <label className="custom-control-label step_two me-3" htmlFor="radioPregnant1">yes</label>
                                        <input
                                            type="radio"
                                            id="radioPregnant2"
                                            name="pregnant"
                                            checked={value.pregnant === "no"}
                                            onChange={changeHandler}
                                            value="no" className="custom-control-input form-check-input"

                                        />
                                        <label className="custom-control-label step_two" htmlFor="radioPregnant2">no</label>
                                    </div>
                                    <h5 className="error-msgtext">
                                        {error?.pregnant}
                                    </h5>
                                </div>
                                {value?.pregnant === 'yes' ? <div>
                                    <img src={Congrats} alt="" className="mb-4" />
                                    <div className="form-group birthday-box mb-3">
                                        <label>Expected due date</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                                <MobileDatePicker
                                                    disablePast
                                                    label=""
                                                    // className="error-input"
                                                    // inputFormat="MM/dd/yyyy"
                                                    value={valueYes?.due_date || ""}
                                                    onChange={(date: any) => yesHandleChange(date)}
                                                    renderInput={(params: any) => <TextField placeholder="MM/dd/yyyy" {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <h5 className="error-msgtext">
                                            {error?.due_date}
                                        </h5>
                                    </div>
                                    <div className="form-group mb-3 baby-gender">
                                        <label>Baby’s Gender</label>
                                        <Autocomplete
                                            disablePortal
                                            id="Gender"
                                            value={valueYes?.expected_baby_gender}
                                            className={`${error?.gender && "input-errorborder"}`}
                                            options={Gender1}
                                            onChange={yesHandleChange}

                                            renderInput={(params) => <TextField placeholder="Select gender" {...params} name="gender" />}
                                        />
                                        <h4 className="error-msgtext">{error?.expected_baby_gender}</h4>

                                    </div>

                                    {
                                        valueYes?.expected_baby_gender === "It’s multiples" &&
                                        <div className="multiple-main-container">
                                            <div className="d-flex flex-row w-100">
                                                <div className="me-3">
                                                    <label className="row-label">Select First Child Gender</label>
                                                </div>

                                                <div className="d-flex">
                                                    <div className="custom-radio custom-control-inline">
                                                        <input type="radio" className="form-check-input me-0 ms-auto" name="first_child_gender" checked={valueYes?.first_child_gender === "male"} value="male" onChange={yesHandleChange} />
                                                        <label className="custom-control-label step_two me-3" >Boy</label>

                                                        <input type="radio" className="form-check-input me-0 ms-auto" name="first_child_gender" checked={valueYes?.first_child_gender === "female"} value="female" onChange={yesHandleChange} />
                                                        <label className="custom-control-label step_two me-3" >Girl</label>
                                                    </div>
                                                    {!!addChild.length && <DeleteIcon onClick={() => handleDelete(0)} />}
                                                </div>
                                            </div>

                                            <div className="d-flex flex-md-row flex-column w-100">
                                                <div className="me-3">
                                                    <label className="row-label">Select Second Child Gender</label>
                                                </div>

                                                <div className="d-flex">
                                                    <div className="custom-radio custom-control-inline">
                                                        <input type="radio" className="form-check-input me-0 ms-auto" name="second_child_gender" checked={valueYes?.second_child_gender === "male"} value="male" onChange={yesHandleChange} />
                                                        <label className="custom-control-label step_two me-3" >Boy</label>

                                                        <input type="radio" className="form-check-input me-0 ms-auto" name="second_child_gender" checked={valueYes?.second_child_gender === "female"} value="female" onChange={yesHandleChange} />
                                                        <label className="custom-control-label step_two me-3" >Girl</label>
                                                    </div>
                                                    {!!addChild.length && <DeleteIcon onClick={() => handleDelete(1)} />}
                                                </div>
                                            </div>
                                            {
                                                addChild?.includes(1) &&
                                                <div className="d-flex flex-md-row flex-column w-100">
                                                    <div className="me-3">
                                                        <label className="row-label">Select Another Child Gender</label>
                                                    </div>

                                                    <div className="d-flex">
                                                        <div className="custom-radio custom-control-inline">
                                                            <input type="radio" className="form-check-input me-0 ms-auto" checked={valueYes?.another_child_gender_1 === "male"} name="another_child_gender_1" value="male" onChange={yesHandleChange} />
                                                            <label className="custom-control-label step_two me-3" >Boy</label>

                                                            <input type="radio" className="form-check-input me-0 ms-auto" checked={valueYes?.another_child_gender_1 === "female"} name="another_child_gender_1" value="female" onChange={yesHandleChange} />
                                                            <label className="custom-control-label step_two me-3" >Girl</label>
                                                        </div>
                                                    </div>
                                                    <DeleteIcon onClick={() => handleDelete(2)} />
                                                </div>
                                            }
                                            {
                                                addChild?.includes(2) &&
                                                <div className="d-flex flex-md-row flex-column w-100">
                                                    <div className="me-3">
                                                        <label className="row-label">Select Another Child Gender</label>
                                                    </div>

                                                    <div className="d-flex">
                                                        <div className="custom-radio custom-control-inline">
                                                            <input type="radio" className="form-check-input me-0 ms-auto" checked={valueYes?.another_child_gender_2 === "male"} name="another_child_gender_2" value="male" onChange={yesHandleChange} />
                                                            <label className="custom-control-label step_two me-3" >Boy</label>

                                                            <input type="radio" className="form-check-input me-0 ms-auto" checked={valueYes?.another_child_gender_2 === "female"} name="another_child_gender_2" value="female" onChange={yesHandleChange} />
                                                            <label className="custom-control-label step_two me-3" >Girl</label>
                                                        </div>
                                                    </div>
                                                    <DeleteIcon onClick={() => handleDelete(3)} />
                                                </div>
                                            }
                                            {
                                                addChild.length < 2 &&
                                                <a className="add-child-info secondary_hyperlink" onClick={() => handleAddChild(1)} >+ Add child</a>
                                            }


                                        </div>
                                    }
                                    <div className="radio-area mb-3">
                                        <h4>How are you welcoming your baby?</h4>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="radioWelcomeBaby1" name="c_section" checked={valueYes?.c_section === "Natural conception"} onChange={yesHandleChange} value="Natural conception" className="custom-control-input form-check-input" />
                                            <label className="custom-control-label step_two" htmlFor="radioWelcomeBaby1">Natural conception</label>

                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="radioWelcomeBaby2" name="c_section" onChange={yesHandleChange} checked={valueYes?.c_section === "Fertility assisted methods"} value="Fertility assisted methods" className="custom-control-input form-check-input" />
                                            <label className="custom-control-label step_two" htmlFor="radioWelcomeBaby2">Conception through IUI, IVF, or other fertility assisted methods</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="radioWelcomeBaby3" name="c_section" onChange={yesHandleChange} checked={valueYes?.c_section === "Surrogacy"} value="Surrogacy" className="custom-control-input form-check-input" />
                                            <label className="custom-control-label step_two" htmlFor="radioWelcomeBaby3">Surrogacy</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="radioWelcomeBaby4" name="c_section" onChange={yesHandleChange} checked={valueYes?.c_section === "Adoption"} value="Adoption" className="custom-control-input form-check-input" />
                                            <label className="custom-control-label step_two" htmlFor="radioWelcomeBaby4">Adoption</label>
                                        </div>

                                        <h5 className="error-msgtext">
                                            {error?.c_section}
                                        </h5>
                                    </div>
                                    <div className="radio-area pb-4 first-baby">
                                        <h4>Is this your first baby?</h4>
                                        <div className="custom-radio custom-control-inline">
                                            <input type="radio" id="fbaby1" name="is_this_your_first_baby"
                                                value="yes"
                                                checked={valueYes?.is_this_your_first_baby === "yes"}
                                                className="custom-control-input form-check-input"
                                                onChange={yesHandleChange}

                                            />
                                            <label className="custom-control-label step_two me-3" htmlFor="fbaby1">yes</label>
                                            <input
                                                type="radio"
                                                id="fbaby2"
                                                name="is_this_your_first_baby"
                                                checked={valueYes?.is_this_your_first_baby === "no"}
                                                onChange={yesHandleChange}
                                                value="no" className="custom-control-input form-check-input"

                                            />
                                            <label className="custom-control-label step_two" htmlFor="fbaby2">no</label>
                                        </div>

                                        {/* //ishtiyaq*/}
                                        {

                                            valueYes?.is_this_your_first_baby === "no" && Array.isArray(valueYes?.other_baby) &&
                                            valueYes?.other_baby.map((baby: any, index: number) => (
                                                <div key={index}>
                                                    <h4>Child {index + 1}</h4>

                                                    <div className="form-group mb-3">
                                                        <label>Baby’s First Name</label>
                                                        <input type="text" name="name" value={baby?.name}
                                                            onChange={(e) => handleOtherChildData(e, index)}
                                                            className="form-control" id=""
                                                            placeholder="First-name" />
                                                        <h5 className="error-msgtext">
                                                            {error?.["name" + index]}
                                                        </h5>
                                                    </div>

                                                    <div className="form-group birthday-box mb-3">
                                                        <label>Baby’s Birthdate</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Stack spacing={3}>
                                                                <MobileDatePicker
                                                                    disableFuture
                                                                    label=""
                                                                    // inputFormat="MM/dd/yyyy"
                                                                    value={baby?.dob || ""}
                                                                    onChange={(date: any) => handleOtherChildData(date, index, 'dob')}
                                                                    renderInput={(params: any) => <TextField placeholder="mm/dd/yyyy" {...params} />}
                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>
                                                        <h5 className="error-msgtext">
                                                            {error?.["dob" + index]}
                                                        </h5>
                                                    </div>

                                                    <div className="form-group mb-3 baby-gender">
                                                        <label>Baby’s Gender</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="Gender"
                                                            value={baby?.gender}
                                                            className={`${error?.gender && "input-errorborder"}`}
                                                            options={Gender.map(({ value }) => value)}
                                                            onChange={(e: any, val) => { handleOtherChildGender(index, val); }}
                                                            renderInput={(params) => <TextField {...params} name="gender" />}
                                                        />
                                                        <h5 className="error-msgtext">
                                                            {error?.["gender" + index]}
                                                        </h5>
                                                    </div>
                                                    {valueYes?.other_baby?.length > 1 && <p><a className="add-child-info secondary_hyperlink" onClick={() => handleOhterChild("remove", index)}>- Remove child</a></p>}
                                                </div>
                                            ))
                                        }
                                        {
                                            valueYes?.is_this_your_first_baby === "no" && <>
                                                {valueYes?.other_baby?.length < 3 && <p><a className="add-child-info secondary_hyperlink" onClick={() => handleOhterChild("add")}>+ Add child</a></p>}
                                            </>
                                        }

                                    </div>


                                </div>
                                    : value?.pregnant === 'no' &&
                                    <div>
                                        <div className="form-group mb-3">
                                            <label>Baby’s First Name</label>
                                            <input type="text" name="baby_first_name" value={valueNo?.baby_first_name}
                                                onChange={noValueHandler} className="form-control" id=""
                                                placeholder="First-name" />
                                            <h5 className="error-msgtext">
                                                {error?.baby_first_name}
                                            </h5>
                                        </div>

                                        <div className="form-group birthday-box mb-3">
                                            <label>Baby’s Birthdate</label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack spacing={3}>
                                                    <MobileDatePicker
                                                        disableFuture
                                                        label=""
                                                        // inputFormat="MM/dd/yyyy"
                                                        value={valueNo?.dob || ""}
                                                        onChange={(date: any) => noValueHandler(date, 'dob')}
                                                        renderInput={(params: any) => <TextField placeholder="mm/dd/yyyy" {...params} />}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                            <h5 className="error-msgtext">
                                                {error?.dob}
                                            </h5>
                                        </div>

                                        <div className="form-group mb-3 baby-gender">
                                            <label>Baby’s Gender</label>
                                            <Autocomplete
                                                disablePortal
                                                id="Gender"
                                                value={valueNo?.gender || ""}
                                                className={`${error?.gender && "input-errorborder"}`}
                                                options={Gender}
                                                onChange={noValueHandler}

                                                renderInput={(params) => <TextField {...params} name="gender" />}
                                            />
                                            <h4 className="error-msgtext">{error?.gender}</h4>
                                        </div>
                                        {
                                            !!addOption.length &&
                                            <p>
                                                <Link
                                                    to=''
                                                    className="add-child-info secondary_hyperlink"
                                                    onClick={handleRemoveMainChild}
                                                >
                                                    - Remove child
                                                </Link>
                                            </p>
                                        }
                                        {
                                            addOption?.includes(1) &&
                                            <>
                                                <div className="form-group mb-3">
                                                    <label>Baby’s First Name</label>
                                                    <input type="text" name="baby_first_name1" value={valueNo?.baby_first_name1}
                                                        onChange={noValueHandler} className="form-control" id=""
                                                        placeholder="First-name" />
                                                    <h5 className="error-msgtext">
                                                        {error?.baby_first_name}
                                                    </h5>
                                                </div>

                                                <div className="form-group birthday-box mb-3">
                                                    <label>Baby’s Birthdate</label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Stack spacing={3}>
                                                            <MobileDatePicker
                                                                disableFuture
                                                                label=""
                                                                // inputFormat="MM/dd/yyyy"
                                                                value={valueNo?.dob_anothor_child1 || ""}
                                                                onChange={(date: any) => noValueHandler(date, 'dob_anothor_child1')}
                                                                renderInput={(params: any) => <TextField placeholder="mm/dd/yyyy" {...params} />}
                                                            />
                                                        </Stack>
                                                    </LocalizationProvider>
                                                    <h5 className="error-msgtext">
                                                        {error?.dob}
                                                    </h5>
                                                </div>

                                                <div className="form-group mb-3 baby-gender">
                                                    <label>Baby’s Gender</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="Gender"
                                                        value={valueNo?.first_gender || ""}
                                                        className={`${error?.gender && "input-errorborder"}`}
                                                        options={GenderNo}
                                                        onChange={noValueHandler}

                                                        renderInput={(params) => <TextField {...params} name="first_gender" />}
                                                    />
                                                    <h4 className="error-msgtext">{error?.gender}</h4>
                                                </div>
                                                <p>  <Link to={''} className="add-child-info secondary_hyperlink" onClick={() => handleRemoveNewChild(1)}>- Remove child</Link></p>
                                            </>
                                        }

                                        {
                                            addOption?.includes(2) &&
                                            <>
                                                <div className="form-group mb-3">
                                                    <label>Baby’s First Name</label>
                                                    <input type="text" name="baby_first_name2" value={valueNo?.baby_first_name2}
                                                        onChange={noValueHandler} className="form-control" id=""
                                                        placeholder="First-name" />
                                                    <h5 className="error-msgtext">
                                                        {error?.baby_first_name}
                                                    </h5>
                                                </div>

                                                <div className="form-group birthday-box mb-3">
                                                    <label>Baby’s Birthdate</label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Stack spacing={3}>
                                                            <MobileDatePicker
                                                                disableFuture
                                                                label=""
                                                                // inputFormat="MM/dd/yyyy"
                                                                value={valueNo?.dob_anothor_child2 || ""}
                                                                onChange={(date: any) => noValueHandler(date, 'dob_anothor_child2')}
                                                                renderInput={(params: any) => <TextField placeholder="mm/dd/yyyy" {...params} />}
                                                            />
                                                        </Stack>
                                                    </LocalizationProvider>
                                                    <h5 className="error-msgtext">
                                                        {error?.dob}
                                                    </h5>
                                                </div>

                                                <div className="form-group mb-3 baby-gender">
                                                    <label>Baby’s Gender</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="Gender"
                                                        value={valueNo?.second_gender || ""}
                                                        className={`${error?.gender && "input-errorborder"}`}
                                                        options={GenderNo2}
                                                        onChange={noValueHandler}

                                                        renderInput={(params) => <TextField {...params} name="second_gender" />}
                                                    />
                                                    <h4 className="error-msgtext">{error?.gender}</h4>
                                                </div>


                                                <p><Link to={''} className="add-child-info secondary_hyperlink" onClick={() => handleRemoveNewChild(2)}>- Remove child</Link></p>

                                            </>
                                        }

                                        {
                                            addOption?.length < 2 &&
                                            <a className="add-child-info secondary_hyperlink" onClick={() => handleAddNewChild(1)}>+ Add child</a>
                                        }
                                        <div className="checkbox-controler mb-3">
                                            <h4>How are you feeding your baby (check all that apply)? </h4>
                                            <div className="d-flex w-100 justify-content-start flex-wrap flex-sm-row flex-column">
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" id="radioFeeding1" name="feeding_choice" onChange={feedingChoiceHandler}
                                                        checked={Array.isArray(feedingChoice) && feedingChoice.some((res: any) => res === "Breastfeeding")}
                                                        value="Breastfeeding" className="custom-control-input form-check-input" />
                                                    <label className="custom-control-label step_two" htmlFor="radioFeeding1">Breast-feeding</label>
                                                </div>

                                                <div className="custom-checkbox">
                                                    <input type="checkbox" id="radioFeeding2" name="feeding_choice"
                                                        checked={Array.isArray(feedingChoice) && feedingChoice.some((res: any) => res === "Pumping")}
                                                        onChange={feedingChoiceHandler} value="Pumping" className="custom-control-input form-check-input" />
                                                    <label className="custom-control-label step_two" htmlFor="radioFeeding2">Pumping</label>
                                                </div>
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" id="radioFeeding3"
                                                        checked={Array.isArray(feedingChoice) && feedingChoice.some((res: any) => res === "Formulafeeding")}

                                                        name="feeding_choice" onChange={feedingChoiceHandler} value="Formulafeeding" className="custom-control-input form-check-input" />
                                                    <label className="custom-control-label step_two" htmlFor="radioFeeding3">Formula Feeding</label>
                                                </div>
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" id="radioFeeding4" name="feeding_choice"
                                                        checked={Array.isArray(feedingChoice) && feedingChoice.some((res: any) => res === "Donorbreastmilk")}
                                                        onChange={feedingChoiceHandler} value="Donorbreastmilk" className="custom-control-input form-check-input" />
                                                    <label className="custom-control-label step_two" htmlFor="radioFeeding4">Donor Breastmilk</label>
                                                </div>
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" id="radioFeeding5" name="feeding_choice"
                                                        checked={Array.isArray(feedingChoice) && feedingChoice.some((res: any) => res === "solid_foods")}
                                                        onChange={feedingChoiceHandler} value="solid_foods" className="custom-control-input form-check-input" />
                                                    <label className="custom-control-label step_two" htmlFor="radioFeeding5">Solid Foods</label>
                                                </div>
                                            </div>

                                            <h5 className="error-msgtext">
                                                {error?.feeding_choice}
                                            </h5>
                                        </div>

                                        <div className="radio-area mb-3">
                                            <h4>Was your baby born prematurely (prior to 37 weeks)?</h4>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioPrematurely1" name="baby_premature" onChange={noValueHandler} checked={valueNo?.baby_premature === "yes"} value="yes" className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="radioPrematurely1">yes</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioPrematurely2" name="baby_premature" onChange={noValueHandler} value="no" checked={valueNo?.baby_premature === "no"} className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="radioPrematurely2">no</label>
                                            </div>
                                            <h5 className="error-msgtext">
                                                {error?.baby_premature}
                                            </h5>
                                        </div>

                                        <div className="radio-area mb-3">
                                            <h4>How did you welcome your baby?</h4>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioDidWelcomeBaby1" name="c_section" checked={valueNo?.c_section === "Natural conception"} onChange={noValueHandler} value="Natural conception" className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="radioDidWelcomeBaby1">Natural conception</label>

                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioDidWelcomeBaby2" name="c_section" onChange={noValueHandler} checked={valueNo?.c_section === "Fertility assisted methods"} value="Fertility assisted methods" className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="radioDidWelcomeBaby2">Conception through IUI, IVF, or other fertility assisted methods</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioDidWelcomeBaby3" name="c_section" onChange={noValueHandler} checked={valueNo?.c_section === "Surrogacy"} value="Surrogacy" className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="radioDidWelcomeBaby3">Surrogacy</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioDidWelcomeBaby4" name="c_section" onChange={noValueHandler} checked={valueNo?.c_section === "Adoption"} value="Adoption" className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="radioDidWelcomeBaby4">Adoption</label>
                                            </div>

                                            <h5 className="error-msgtext">
                                                {error?.c_section}
                                            </h5>
                                        </div>

                                        <div className="radio-area mb-3">
                                            <h4>Did you struggle with fertility?</h4>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="fertilityRadioYes" name="fertility_question" onChange={noValueHandler} checked={valueNo?.fertility_question === "yes"} value="yes" className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="fertilityRadioYes">yes</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="fertilityRadioNo" name="fertility_question" onChange={noValueHandler} value="no" checked={valueNo?.fertility_question === "no"} className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="fertilityRadioNo">no</label>
                                            </div>
                                            <h5 className="error-msgtext">
                                                {error?.fertility_question}
                                            </h5>
                                        </div>

                                        {/* <div className="radio-area mb-3">
                                            <h4>Did you have a C-Section?</h4>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioCSection1" name="c_section" onChange={noValueHandler} value="yes" checked={valueNo?.c_section === "yes"} className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two " htmlFor="radioCSection1">yes</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioCSection2" name="c_section" value="no" onChange={noValueHandler} checked={valueNo?.c_section === "no"} className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="radioCSection2">no</label>
                                            </div>
                                            <h5 className="error-msgtext">
                                                {error?.c_section}
                                            </h5>
                                        </div>
                                        <div className="radio-area mb-3">
                                            <h4>Fertility questions</h4>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioFertility0" name="fertility_question" checked={valueNo?.fertility_question === "Breastmilk"} onChange={noValueHandler} value="Breastmilk" className="custom-control-input form-check-input" />
                                                Baby’s Gender  <label className="custom-control-label step_two" htmlFor="radioFertility0">Breastmilk</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioFertility1" name="fertility_question" checked={valueNo?.fertility_question === "Formula"} onChange={noValueHandler} value="Formula" className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="radioFertility1">Formula</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="radioFertility2" name="fertility_question" checked={valueNo?.fertility_question === "Both"} onChange={noValueHandler} value="Both" className="custom-control-input form-check-input" />
                                                <label className="custom-control-label step_two" htmlFor="radioFertility2">Both</label>
                                            </div>
                                            <h5 className="error-msgtext">
                                                {error?.fertility_question}
                                            </h5>
                                        </div> */}
                                        {/* <div className="pt-0">
                                <a href="" className="twins-link">Twins?</a>
                            </div> */}


                                    </div>
                                }
                            </form>

                            <button className="btn next-buttonbtn primary-blue-btn mb-5 mt-3" disabled={loading} onClick={handleCheck}>{loading ? <CircularProgress /> : "Next Step"}</button>
                        </div>

                    </div>
                </div>
            </div>
            {isSkip === "I’m Single" && <div className="skip-buttondiv">
                <button className="btn skip-button" onClick={() => handleSkip()}>Skip</button>
            </div>}
        </div>
    )
}

export default Step2
