import React, { useEffect, useRef, useState } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, CircularProgress } from '@mui/material';
import Camera from '../../../Assets/img/icons/camera.svg'
import Eye from "../../../Assets/img/eye.svg"
import Profile from '../../../Assets/img/signup/profile.png';
import ConvertFileTobase64 from '../../../Utils/ConverBase64';
import { accountSettingAPI, profileAboutUpdate, profilebabyUpdate, profileFamilyUpdate } from '../../../Service/update_profile';
import { emailRegex, passwpordRegex } from '../../../Utils/Regex';
import { toast } from 'react-toastify';
import getUserProfileUpdateAPI from '../../../Utils/profileUpdateAPI';
import { Link, useNavigate } from 'react-router-dom';
import { EDITPROFILE } from '../../../Routes/RouteConstent';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import ImagePopup from '../../../Utils/imageCrop/imagePopup';
import { usePostHog } from 'posthog-js/react';

function Step1({ formNo, profileData, formData1, setFormData1, formData2, setFormData2, formData3,
  setFormData3, formData4, setFormData4, error, setError, setOpenPop, openPop, getProfileBaby, feedingChoice, setFeedingChoice }: any) {

  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
  const [isPasswordShow1, setIsPasswordShow1] = useState<boolean>(false);
  const [isPasswordShow2, setIsPasswordShow2] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>({})
  const [imageDetail, setImageDetail] = useState<any>()
  const [isChild, setIsChild] = useState<any>({
    child1: false,
    child2: false
  })
  const [noGender, setNoGender] = useState<any>({
    gender1: false,
    gender2: false
  })
  const [isCrop, setIsCrop] = useState(false)
  let [image, setImage] = useState<any>("")
  const [loading, setLoading] = useState(false)
  const posthog = usePostHog();

  let userImageref: any = useRef()

  let user: any = localStorage.getItem('Nurture_user_data')
  user = JSON.parse(user)
  const Navigate = useNavigate()


  const Scroll = () => {
    window.scroll({
      top: 10,
      left: 0,
      //@ts-ignore
      behavior: "instant"
    })
  }
  function handleDateChange(date: Date | any, name: string) {

    setError({
      ...error,
      [name]: ""
    })
    if (name === 'dob') {
      setFormData1({
        ...formData1,
        [name]: date?.$d ? date?.$d : date
      })
    }
    if (name === 'due_date') {
      setFormData2({
        ...formData2,
        [name]: date?.$d ? date?.$d : date
      })
    }
    if (name === 'baby_dob') {
      setFormData2({
        ...formData2,
        [name]: date?.$d ? date?.$d : date
      })
    }
  }
  function handleDateChangeOther(date: Date | any, name: string) {
    setError({
      ...error,
      [name]: ""
    })
    setFormData2({
      ...formData2,
      [name]: date?.$d
    })
  }
  const toggleIsCurrPassShowValue = () => {
    setIsPasswordShow(!isPasswordShow);
  };
  const toggleIsPassShowValue = () => {
    setIsPasswordShow1(!isPasswordShow1);
  };
  const toggleIsConPassShowValue = () => {
    setIsPasswordShow2(!isPasswordShow2);
  };

  const handleCancel = (e: any) => {
    setFormData1({
      ...formData1,
      ['first_name']: profileData?.about_you?.first_name,
      ['last_name']: profileData?.about_you?.last_name,
      ['phone']: profileData?.about_you?.phone,
      ['dob']: profileData?.about_you?.dob?.$d ? moment(profileData?.about_you?.dob?.$d).format("YYYY-MM-DD") : "",
      ['zip_code']: profileData?.about_you?.zip_code ?? '',
      ['current_life_status']: profileData?.about_you?.current_life_status ?? '',
      ['type_mom']: profileData?.about_you?.type_mom ?? '',
      ["gender"]: profileData?.about_you?.gender ?? '',
      photo: null,
    })
    setImage("");
    setError({
      ...error,
      ["phone"]: ""
    })


  }
  const handleBabyNoCond = () => {

    if (formData2?.baby_first_name1 && formData2?.baby_first_name2) {
      setNoGender({
        gender1: true,
        gender2: true
      })
    } else if (formData2?.baby_first_name1) {
      setNoGender({
        gender1: true,
        gender2: false
      })
    } else if (formData2?.baby_first_name2) {
      setNoGender({
        gender1: false,
        gender2: true
      })
    } else {
      setNoGender({
        gender1: false,
        gender2: false
      })
    }
  }
  const handleCancel1 = (e: any) => {
    if (formData2?.pregnant === 'no') {
      handleBabyNoCond()
    }
    if (profileData?.about_your_baby?.pregnant === "no" && formData2?.pregnant === 'yes' || profileData?.about_your_baby?.pregnant === "yes" && formData2?.pregnant === 'no') {
      Scroll()
    }

    setFormData2({
      ...profileData?.about_your_baby
    })
  }

  const handleCancel2 = (e: any) => {

    setFormData3({
      ['family_first_name']: profileData?.about_your_partner?.family_first_name ? profileData?.about_your_partner?.family_first_name : "",
      ['family_last_name']: profileData?.about_your_partner?.family_last_name ? profileData?.about_your_partner?.family_last_name : "",
      ['family_email']: profileData?.about_your_partner?.family_email ? profileData?.about_your_partner?.family_email : ""
    })
  }

  const handleCancel3 = (e: any) => {
    setFormData4({
      ...formData4,
      ['current_password']: "",
      ['password']: "",
      ['password_confirmation']: ""
    })
    setError({
      ...error,
      ['current_password']: "",
      ['password']: "",
      ['password_confirmation']: ""
    })
  }
  const handleSelectOption = async (e: any, value: any, name: string) => {
    if (name === 'gender') {
      setFormData1({
        ...formData1,
        ["gender"]: value?.value
      })
    }

    if (name === 'photo') {
      let base64: any = await ConvertFileTobase64(e.target.files[0])

      await setImage(base64)
      setImageDetail(e?.target?.files[0])
      setIsCrop(true)
    }

    if (name === 'baby_gender') {
      setError({ ...error, [name]: "" })
      setFormData2({
        ...formData2,
        [name]: value.value
      })
    }
    if (name === 'expected_baby_gender') {
      setError({
        ...error,
        [name]: ""
      })
      setFormData2({
        ...formData2,
        [name]: value.value
      })
    }
  }
  const handleSelectOption1 = async (e: any, value: any, name: string) => {

    setError({
      ...error,
      [name]: ""
    })
    setFormData2({
      ...formData2,
      [name]: value?.value
    })
  }
  const handleInputText2 = (e: any) => {
    let { name, value } = e.target;
    setError({
      ...error,
      [name]: ""
    })
    setFormData2({
      ...formData2,
      [name]: value
    })
  }
  const handleInputText = (e: any) => {
    let { name, value } = e.target;
    if (name === "phone") {
      if (value.length < 10 || value.length > 10) {
        setError({
          ...error,
          [name]: "Contact number should be of 10 digits"
        })
      } else {
        setError({
          ...error,
          [name]: ""
        })
      }
    }

    if (name === "zip_code") {
      if (value.length === 5) {
        setError({
          ...error,
          [name]: ""
        })
      } else {
        setError({
          ...error,
          [name]: "Please enter valid zip code"
        })
      }
    }
    setFormData1({
      ...formData1,
      [name]: value
    })

    if (name === 'baby_first_name') {
      setFormData2({
        ...formData2,
        [name]: value
      })
    }
    if (name === 'family_first_name') {
      setFormData3({
        ...formData3,
        [name]: value
      })
    }
    if (name === 'family_last_name') {
      setFormData3({
        ...formData3,
        [name]: value
      })
    }
    if (name === 'family_email') {
      setFormData3({
        ...formData3,
        [name]: value
      })
    }
    if (name === 'current_password') {
      setFormData4({
        ...formData4,
        [name]: value
      })
    }
    if (name === 'password') {
      setFormData4({
        ...formData4,
        [name]: value
      })
    }
    if (name === 'password_confirmation') {
      setFormData4({
        ...formData4,
        [name]: value
      })
    }
    if (name === "password") {
      if (!passwpordRegex.test(value)) {
        setError({
          ...error,
          [name]: "Password should contain 6 to 20 characters including one numeric digit, one uppercase letter, one lowercase letter and one special character"
        })
      } else {
        setError({
          ...error,
          [name]: ""
        })
      }
    }
    if (name === "password_confirmation") {
      if (value !== formData4?.password) {
        setError({
          ...error,
          [name]: "Please make sure your Passwords match"
        })
      }
      else {
        setError({
          ...error,
          [name]: ""
        })
      }
    }

  }

  const handleRadioBtn = (e: any) => {
    setError({
      ...error,
      [e.target.name]: ""
    })
    if (e.target.name === 'pregnant') {
      setFormData2({
        ...formData2,
        [e.target.name]: e.target.value
      })
    }
    if (e?.target?.name === "is_this_your_first_baby") {

      if (e?.target?.value === "no") {
        setFormData2({
          ...formData2,
          [e?.target?.name]: e?.target?.value,
          ["other_baby"]: [{}]
        })
      } else {
        setFormData2({
          ...formData2,
          [e?.target?.name]: e?.target?.value,
          ["other_baby"]: []
        })
      }
      return;
    }
    if (e.target.name === 'type_mom') {

      if (e.target.checked) {
        if (!formData1.type_mom) {
          setFormData1({
            ...formData1,
            ["type_mom"]: []
          })
        }
        let newData = [...formData1.type_mom]
        newData.push(e.target.value)
        setFormData1({
          ...formData1,
          [e.target.name]: newData
        })
      } else {
        let newValue = formData1.type_mom.filter((res: any) => res != e.target.value)
        setFormData1({
          ...formData1,
          [e.target.name]: newValue
        })
      }
    }
    if (e.target.name === 'current_life_status') {
      setFormData1({
        ...formData1,
        [e.target.name]: e.target.value
      })
    }
    if (e.target.name === 'feeding_choice') {
      setFormData2({
        ...formData2,
        [e.target.name]: e.target.value
      })
    }
    if (e.target.name === 'baby_premature') {
      setFormData2({
        ...formData2,
        [e.target.name]: e.target.value
      })
    }
    if (e.target.name === 'c_section') {
      setFormData2({
        ...formData2,
        [e.target.name]: e.target.value
      })
    }
    if (e.target.name === 'fertility_question') {
      setFormData2({
        ...formData2,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleFeedingChoice = (e: any) => {
    let inputValue = e.target.value;
    let name = e.target.name
    setError({
      ...error,
      ["feeding_choice"]: ""
    })
    if (e.target.checked) {

      if (!formData2.feeding_choice) {
        setFormData2({
          ...formData2,
          ["feeding_choice"]: []
        })
      }

      let feedingChoose = [...formData2.feeding_choice]
      feedingChoose.push(inputValue)
      setFormData2({ ...formData2, ["feeding_choice"]: feedingChoose })

    } else {

      let newValue = formData2.feeding_choice.filter((result: any) => result !== inputValue)
      setFormData2({
        ...formData2,
        ["feeding_choice"]: newValue
      })
    }

  }

  const handleAboutYou = async (e: any) => {
    e.preventDefault();
    try {

      let errors: any = {}
      if (formData1?.first_name?.length < 3 ||
        formData1?.zip_code !== 'null' && formData1?.zip_code && formData1?.zip_code?.length !== 5 || formData1?.last_name?.length < 3 || (formData1?.phone?.length < 10 || formData1?.phone?.length > 10)) {
        if (formData1?.first_name?.length < 3) {
          errors.first_name = 'Minimum 3 char required'
        }
        if (formData1?.last_name?.length < 3) {
          errors.last_name = 'Minimum 3 char required'
        }
        if (formData1?.phone?.length < 10 || formData1?.phone?.length > 10) {
          errors.phone = 'Contact number should be of 10 digits'
        }
        if (formData1?.zip_code && formData1?.zip_code?.length !== 5) {
          errors.zip_code = 'Please enter valid zip code'
        }
        setError(errors)

      } else {
        setLoading(true)
        let formData = new FormData()
        formData.append('user_id', userData?.id)
        formData.append('first_name', formData1?.first_name)
        formData.append('last_name', formData1?.last_name)
        formData.append('email', userData?.email)
        formData.append('phone', formData1?.phone)
        formData.append('dob', moment(formData1?.dob?.$d).format("YYYY-MM-DD"))
        formData.append('zip_code', formData1?.zip_code ?? '')
        formData.append('gender', formData1?.gender ?? '')
        formData.append('type_mom', formData1?.type_mom ?? '')
        formData.append('current_life_status', formData1?.current_life_status ?? '')
        formData1?.photo && formData.append('photo', formData1?.photo)

        let res: any = await profileAboutUpdate(formData)

        let message = res?.data?.message
        let msg = "";
        for (let i = 0; i < message.length; i++) {
          msg += message[i]
        }
        if (res?.data?.success) {
          posthog.capture("set_person_property/edit_user", {
            $set: {
              email: res?.data?.data?.email,
              name: res?.data?.data?.name,
              user_first_name: res?.data?.data?.first_name,
              user_last_name: res?.data?.data?.last_name,
              user_email: res?.data?.data?.email,
              user_phone: res?.data?.data?.phone,
            },
          });

          localStorage.setItem("Nurture_user_data", JSON.stringify(res?.data?.data))
          getProfileBaby()
          await getUserProfileUpdateAPI()
          toast.success(msg)
          setLoading(false)
          localStorage.setItem('profile_data_update', JSON.stringify(res?.data?.data))
          window.dispatchEvent(new Event("profile"))
        } else {
          setLoading(false);
          toast.error(msg);
        }
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleBabyProfile = async (e: any) => {
    e.preventDefault();
    try {
      const extraChild = () => {

        if (formData2?.is_this_your_first_baby === "no") {
          let otherError: any = {}
          formData2?.other_baby.map((element: any, index: any) => {
            if (!element?.dob) {

              otherError["dob" + [index]] = "This field is required"
            }
            if (!element?.name) otherError["name" + [index]] = "This field is required";
            if (!element?.gender) otherError["gender" + [index]] = "This field is required";

          });

          if (!otherError || Object.keys(otherError).length === 0) {
            return false
          } else {
            return otherError
          }
        }
        return false
      }
      if (formData2?.pregnant === "no" && (!formData2?.baby_first_name || !formData2?.baby_dob || !formData2?.baby_gender
        || !formData2?.baby_premature || !formData2?.c_section
        || !formData2?.fertility_question || !formData2?.feeding_choice ||
        noGender.gender1 && (!formData2?.baby_first_name1 || !formData2?.first_gender || !formData2?.dob_anothor_child1) ||
        noGender.gender2 && (!formData2?.baby_first_name2 || !formData2?.second_gender || !formData2?.dob_anothor_child2)) || formData2?.pregnant === "yes" && (!formData2?.due_date || extraChild() || !formData2?.expected_baby_gender || !formData2?.c_section
        )) {
        let errors: any = {}
        if (formData2?.pregnant === "no") {
          if (noGender?.gender1) {
            if (!formData2?.baby_first_name1) {
              errors.baby_first_name1 = "This field is required"
            }
            if (!formData2?.first_gender) {
              errors.first_gender = "This field is required"
            }
            if (!formData2?.dob_anothor_child1) {
              errors.dob_anothor_child1 = "This field is required"
            }
          }
          if (!formData2?.baby_first_name) {
            errors.baby_first_name = "Please input the value";
          }
          if (!formData2?.baby_dob) {
            errors.baby_dob = "Please input the value";
          }
          if (!formData2?.baby_gender) {
            errors.baby_gender = "Please input the value";
          }
          if (!formData2?.baby_premature) {
            errors.baby_premature = "Please input the value";
          }
          if (!formData2?.c_section) {
            errors.c_section = "Please input the value";
          }
          if (!formData2?.fertility_question) {
            errors.fertility_question = "Please input the value";
          }
          if (!formData2?.feeding_choice) {
            errors.feeding_choice = "Please input the value";
          }


          if (noGender?.gender2) {
            if (!formData2?.baby_first_name2) {
              errors.baby_first_name2 = "Please input the value"
            }
            if (!formData2?.second_gender) {
              errors.second_gender = "Please input the value"
            }
            if (!formData2?.dob_anothor_child2) {
              errors.dob_anothor_child2 = "Please input the value"
            }
          }
        }
        if (formData2?.pregnant === "yes") {
          if (!formData2?.due_date) {
            errors.due_date = "Please input the value"
          }
          if (!formData2?.expected_baby_gender) {
            errors.expected_baby_gender = "Please input the value"
          }
          if (!formData2?.c_section) {
            errors.c_section = "Please input value"
          }

        }
        let errorsBaby = extraChild()

        setError({ ...errorsBaby, ...errors })
      } else {

        setLoading(true)
        let data: any = {
          user_id: userData?.id,
          ...formData2
        }
        let res: any = await profilebabyUpdate(data)

        let message = res?.data?.message
        let msg = "";
        for (let i = 0; i < message.length; i++) {
          msg += message[i]
        }
        if (res?.data?.success) {
          getProfileBaby()
          toast.success(msg)
          setLoading(false)

          posthog.capture("set_person_property/edit_baby", {
            $set: {
              are_you_currently_expecting: res?.data?.data?.pregnant,
              baby_expected_due_date: res?.data?.data?.due_date || null,
              is_this_your_first_baby: res?.data?.data?.is_this_your_first_baby || null,
            },
          });
        }
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const handleProfileFamily = async (e: any) => {
    e.preventDefault();
    try {
      let errors: any = {}
      if (formData3?.family_first_name?.length < 3 || formData3?.family_last_name?.length < 3 ||
        !emailRegex.test(formData3?.family_email)) {
        if (formData3?.family_first_name?.length < 3) {
          errors.family_first_name = 'Minimum 3 char required'
        }
        if (formData3?.family_last_name?.length < 3) {
          errors.family_last_name = 'Minimum 3 char required'
        }
        if (!emailRegex.test(formData3?.family_email)) {
          errors.family_email = 'Please enter valid email'
        }

        setError(errors)
      } else {
        let data: any = {
          user_id: userData?.id,
          ...formData3
        }
        setLoading(true)
        let res: any = await profileFamilyUpdate(data)
        // debugger
        let message = res?.data?.message
        let msg = "";
        for (let i = 0; i < message.length; i++) {
          msg += message[i]
        }
        if (res?.data?.success) {
          toast.success(msg)
          setLoading(false)

          posthog.capture("set_person_property/edit_partner", {
            $set: {
              partner_first_name: res?.data?.data?.first_name,
              partner_last_name: res?.data?.data?.last_name,
              partner_email: res?.data?.data?.email,
            },
          });
        }
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleChangePass = async (e: any) => {
    e.preventDefault();
    try {
      let errors: any = {}
      if (!formData4?.current_password || !formData4?.password || !passwpordRegex.test(formData4?.password) ||
        !formData4?.password_confirmation || formData4?.password !== formData4?.password_confirmation) {

        if (!formData4?.current_password) {
          errors.current_password = 'Please enter current password'
        }
        if (!passwpordRegex.test(formData4?.password)) {
          errors.password = 'Password should contain 6 to 20 characters including one numeric digit, one uppercase letter, one lowercase letter and one special character'
        }
        if (!formData4?.password) {
          errors.password = 'Please enter new password'
        }


        if (!formData4?.password_confirmation) {
          errors.password_confirmation = 'Please enter confirm password'
        }

        if (formData4?.password !== formData4?.password_confirmation) {
          errors.password_confirmation = 'Please make sure your Passwords match'
        }
        setError(errors)

      } else {
        setLoading(true)
        let data: any = {
          user_id: userData?.id,
          ...formData4
        }
        let res: any = await accountSettingAPI(data)

        let message = res?.data?.message
        if (!res?.data?.success) {
          setError(message)
          setLoading(false)
        }
        if (res?.data?.success) {
          let msg = "";
          for (let i = 0; i < message.length; i++) {
            msg += message[i]
          }
          toast.success(msg)
          setLoading(false)
        }
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    let user: any = localStorage.getItem("Nurture_user_data");
    user = JSON.parse(user);
    setUserData(user)
  }, [])

  useEffect(() => {
    setFeedingChoice(Array.isArray(formData2?.feeding_choice) && formData2?.feeding_choice)
  }, [formData2])

  const handleRadioBtn2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({
      ...error,
      [e.target.name]: ""
    })
    setFormData2({
      ...formData2,
      [e.target.name]: e.target.value
    })
  }
  const handleAddChild = () => {
    if (!isChild?.child1 || !isChild?.child2) {
      if (!isChild?.child1 && !isChild?.child2) {
        setIsChild({
          ...isChild,
          ["child1"]: true
        })
      } else if (isChild?.child1 && !isChild?.child2) {
        setIsChild({
          ...isChild,
          ["child2"]: true
        })
      } else if (isChild?.child2 && !isChild?.child1) {
        setIsChild({
          ...isChild,
          ["child1"]: true
        })
      }
    }
  }
  const handleDelete = (data: any) => {
    setIsChild({
      ...isChild,
      [data]: false
    })
    if (data === "child1") {
      setFormData2({
        ...formData2,
        ["another_child_gender_1"]: ""
      })
    }
    if (data === "child2") {
      setFormData2({
        ...formData2,
        ["another_child_gender_2"]: ""
      })
    }
  }

  const selectAnotherChild = (e: any) => {
    setFormData2({
      ...formData2,
      [e.target.name]: e.target.value
    })
  }

  const handleChildOther = (param?: any) => {
    if (param === "add") {
      if (!noGender.gender1 && !noGender.gender2) {
        setNoGender({
          ...noGender,
          ["gender1"]: true
        })
      } else if (!noGender.gender1) {
        setNoGender({
          ...noGender,
          ["gender1"]: true,
        })
      } else if (!noGender.gender2) {
        setNoGender({
          ...noGender,
          ["gender2"]: true
        })
      }
    }

    if (param === "gender1") {
      setFormData2({
        ...formData2,
        ["baby_first_name1"]: "",
        ["first_gender"]: "",
        ["dob_anothor_child1"]: ""
      })
      setNoGender({
        ...noGender,
        ["gender1"]: false
      })
    }
    if (param === "gender2") {
      setFormData2({
        ...formData2,
        ["baby_first_name2"]: "",
        ["second_gender"]: "",
        ["dob_anothor_child2"]: ""
      })
      setNoGender({
        ...noGender,
        ["gender2"]: false
      })
    }
    // setFormData2({
    //   ...formData2,
    //   ["baby_first_name2"]: ""
    // })
  }

  useEffect(() => {
    handleBabyNoCond()
  }, [profileData?.about_your_baby, formNo])
  const handleInputTextNo = (e: any) => {
    let { name, value } = e.target;
    setError({
      ...error,
      [name]: ""
    })
    setFormData2({
      ...formData2,
      [name]: value
    })
  }
  const handleChild = () => {

    if (formData2?.another_child_gender_1 || formData2?.another_child_gender_2) {
      let isChild: any = {}
      if (formData2?.another_child_gender_1) {
        isChild.child1 = true

      }
      if (formData2?.another_child_gender_2) {
        isChild.child2 = true
      }
      setIsChild(isChild)
    }
  }
  useEffect(handleChild, [profileData?.about_your_baby, formNo])
  
  const handleOhterChild = (param: any) => {
    if (param === "remove") {
      let other_baby = formData2?.other_baby.slice(0, -1)
      setFormData2({
        ...formData2,
        ["other_baby"]: other_baby
      })
    }
    if (param === "add") {
      formData2.other_baby.push({})
      setFormData2({ ...formData2 })
    }
  }
  const handleOtherChildData = (e: any, index: number, other?: any) => {
    let name = e?.target?.name;
    let value = e?.target?.value;
    //ish
    console.log(name + index)
    if (other === "gender") {
      setError({
        ...error,
        ["gender" + index]: ""
      })
    } else if (other === "dob") {
      setError({
        ...error,
        ["dob" + index]: ""
      })
    } else {
      setError({
        ...error,
        [name + index]: ""
      })
    }

    let modify = formData2?.other_baby.map((res: any, id: number) => {
      if (index === id) {
        if (other === "dob") {
          res[other] = e?.$d
        } else if (other === "gender") {

          res[other] = e.label
        } else {
          res[name] = value
        }
      }
      return res;
    })
    setFormData2({
      ...formData2,
      ["other_baby"]: modify
    })
  }
  return (
    <>
      <ImagePopup image={imageDetail} open={isCrop} setOpen={setIsCrop} imagebase64={image} setImageBase64={setImage} value={formData1} setValue={setFormData1} />
      {formNo == 0 &&
        <div className='col-lg-12 col-md-12'>
          <h5 className='section-title'>Tell us about yourself</h5>
          <div className='edit-profile-container py-5 mb-4'>
            <div className="step-innercontent px-3 step2">
              <div className="yourself-main">

                <div className="profile-updaterdiv text-center">
                  <div className=" mt-5 mb-3 position-relative" onClick={() => userImageref.current.click()}>
                    <div className="img-upload" style={{ 'backgroundImage': `url(${image ? image : profileData?.about_you?.photo || Profile})` }}></div>
                    {/* <img className="img-upload" alt="" src={image ? image : profileData?.about_you?.photo || Profile} /> */}
                    <img src={Camera} className="img-upload-icon" alt="" />
                  </div>
                  <input type="file" accept="image/png, image/gif, image/jpeg" name="photo"
                    onChange={(e) => handleSelectOption(e, '', 'photo')} className="d-none" ref={userImageref} />
                  <h4 className="error-msgtext text-center"></h4>
                </div>

                <form className="step-form row">

                  <div className="form-group-m col-lg-12 mb-3">
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>First Name</label>
                        <input type="text"
                          name='first_name'
                          className='form-control'
                          value={formData1?.first_name}
                          onChange={handleInputText}
                          id=""
                          placeholder='First Name'
                        />
                        <h4 className="error-msgtext">{error?.first_name}</h4>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Last Name</label>
                        <input type="text"
                          name='last_name'
                          className='form-control'
                          value={formData1?.last_name}
                          onChange={handleInputText}
                          id=""
                          placeholder='Last Name'
                        />
                        <h4 className="error-msgtext">{error?.last_name}</h4>
                      </div>
                    </div>
                  </div>

                  <div className="form-group-m col-lg-12 col-md-12 mb-3">
                    <div className="row">
                      <div className="form-group col-lg-12 col-md-12 gender-box ">
                        <label>Email</label>
                        <input type="email" name="email"
                          value={formData1?.email}
                          disabled
                          // onChange={handleChange} value={value?.email}
                          className=' form-control'
                          id="" placeholder="Email" />
                        <h4 className="error-msgtext"></h4>
                      </div>
                    </div>
                  </div>
                  <div className="form-group-m col-lg-12 col-md-12 mb-3">
                    <div className="row">
                      <div className="form-group col-lg-12 col-md-12 gender-box ">
                        <label>Contact number</label>
                        <input type="number" name="phone"
                          value={formData1?.phone}
                          className=' form-control'
                          id="" onChange={handleInputText} placeholder="Contact no." />
                        <h4 className="error-msgtext">{error?.phone}</h4>
                      </div>
                    </div>
                  </div>

                  {user?.is_stripe_membership ?
                    <> <div className="form-group-m col-lg-12 mb-3">
                      <div className="row">
                        <div className={`form-group birthday-box col-lg-6 col-md-6`}>
                          <label>What’s your birthdate?</label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <MobileDatePicker
                                // value={user.dob}                              
                                value={formData1?.dob}
                                toolbarTitle="Choose date"
                                disableFuture
                                onChange={(date: any) => handleDateChange(date, 'dob')}
                                renderInput={(params: any) => <TextField error={false} placeholder="MM-DD-YYYY"  {...params} />}
                              />
                            </Stack>
                          </LocalizationProvider>
                          <h4 className="error-msgtext"></h4>
                        </div>

                        <div className="form-group col-lg-6 col-md-6">
                          <label>What’s your zip code?</label>
                          <input type="number"
                            name='zip_code'
                            className='form-control'
                            value={formData1?.zip_code}
                            onChange={handleInputText}
                            id=""
                          />
                          <h4 className="error-msgtext">{error?.zip_code}</h4>
                        </div>
                      </div>

                    </div></> : <></>
                  }
                  {user?.is_stripe_membership ? <div className="form-group-m col-lg-12 col-md-12 mb-3">
                    <div className="row">
                      <div className="form-group col-lg-12 col-md-12 gender-box ">
                        <label>What are your preferred pronouns?</label>
                        <Autocomplete
                          disablePortal
                          id="Gender"
                          // name='gender'                          
                          // className={`${error?.gender && "input-errorborder"}`}
                          options={Gender}
                          value={formData1?.gender || ""}
                          sx={{ width: 300 }}
                          onChange={(e, value) => handleSelectOption(e, value, 'gender')}
                          renderInput={(params) => <TextField placeholder='Gender' {...params} name="gender" />}
                        />
                        <h4 className="error-msgtext"></h4>
                      </div>
                    </div>
                  </div> : <></>
                  }
                </form>

                {user?.is_stripe_membership ? <div className="checkbox-controler radio-area mb-3">
                  <h4>What best describes you?</h4>
                  <div className="d-flex w-100 justify-content-start flex-wrap flex-sm-row flex-column">
                    <div className="custom-checkbox">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="type_mom" id="customCheck1" onChange={handleRadioBtn} value="full time working parent" checked={Array.isArray(formData1?.type_mom) && formData1?.type_mom?.some((res: any) => res === "full time working parent")} /><label className="form-check-label">Full-time working parent</label>
                      </div>
                    </div>
                    <div className="custom-checkbox">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="type_mom" id="flexRadioDefault2" onChange={handleRadioBtn} value="part time working parent" checked={Array.isArray(formData1?.type_mom) && formData1?.type_mom?.some((res: any) => res === "part time working parent")} /><label className="form-check-label" >Part-time working parent</label>
                      </div>
                    </div>
                    <div className="custom-checkbox">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="type_mom" id="flexRadioDefault2" onChange={handleRadioBtn} value="Stay at home parent" checked={Array.isArray(formData1?.type_mom) && formData1?.type_mom?.some((res: any) => res === "Stay at home parent")} /><label className="form-check-label" >Stay at home parent</label>
                      </div>
                    </div>
                    <div className="custom-checkbox">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="type_mom" id="flexRadioDefault2" onChange={handleRadioBtn} value="Self employed" checked={Array.isArray(formData1?.type_mom) && formData1?.type_mom?.some((res: any) => res === "Self employed")} /><label className="form-check-label">Self employed</label>
                      </div>
                    </div>
                    <div className="custom-checkbox">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="type_mom" id="flexRadioDefault2" onChange={handleRadioBtn} value="unemployed" checked={Array.isArray(formData1?.type_mom) && formData1?.type_mom?.some((res: any) => res === "unemployed")} /><label className="form-check-label">Unemployed</label>
                      </div>
                    </div>
                    <div className="custom-checkbox">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="type_mom" id="flexRadioDefault2" onChange={handleRadioBtn} value="other" checked={Array.isArray(formData1?.type_mom) && formData1?.type_mom?.some((res: any) => res === "other")} /><label className="form-check-label" >Other</label>
                      </div>
                    </div>
                    <div className="custom-checkbox">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="type_mom" id="flexRadioDefault2" onChange={handleRadioBtn} value="I prefer not to say" checked={Array.isArray(formData1?.type_mom) && formData1?.type_mom?.some((res: any) => res === "I prefer not to say")} />
                        <label className="form-check-label">I prefer not to say</label>
                      </div>
                    </div><h4 className="error-msgtext"></h4>
                  </div>
                </div> : <></>
                }
              </div>


              {user?.is_stripe_membership ? <div className="radio-area">
                <h4>What’s your current life status?</h4>
                <div className="custom-control custom-radio">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="current_life_status" value="I’m Single" checked={formData1?.current_life_status === "I’m Single"} onChange={handleRadioBtn} id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      I’m Single
                    </label>
                  </div>
                </div>
                <div className="custom-control custom-radio">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="current_life_status" value='I have a Partner or Support Person' checked={formData1?.current_life_status === 'I have a Partner or Support Person'} onChange={handleRadioBtn} />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      I have a Partner or Support Person
                    </label>
                  </div>
                </div>

                <h4 className="error-msgtext"></h4>
              </div> : <></>
              }
              <div className='d-flex justify-content-between mt-5'>
                <button type="button" className="primary-blue-small-btn btn w-50 me-2" disabled={loading} onClick={handleAboutYou} >{loading ? <CircularProgress /> : 'Save Changes'}</button>
                <button type="button" className="primary-outline-btn h50 btn w-50 ms-2" onClick={handleCancel}>Cancel</button>
              </div>
            </div>

          </div>

        </div>
      }

      {
        formNo == 1 &&
        <div className='col-lg-12 col-md-12'>
          <h5 className='section-title'>About your  Baby</h5>
          <div className='edit-profile-container py-5 mb-4'>
            <div className='step-innercontent step4'>
              <form className="step-form">
                <div className="radio-area pb-4 ">
                  <h4>Are you currently expecting?</h4>
                  <div className="custom-radio custom-control-inline">
                    <input type="radio" id="customRadio1" name="pregnant" checked={formData2?.pregnant === 'yes'} value="yes" onChange={handleRadioBtn}
                      className="custom-control-input form-check-input" />
                    <label className="custom-control-label step_two me-3" htmlFor="customRadio1">yes</label>
                    <input
                      type="radio"
                      id="customRadio2"
                      name="pregnant"
                      checked={formData2?.pregnant === 'no'}
                      value="no" className="custom-control-input form-check-input"
                      onChange={handleRadioBtn}
                    />
                    <label className="custom-control-label step_two" htmlFor="customRadio2">no</label>
                  </div>
                  <h5 className="error-msgtext"></h5>
                </div>

                {
                  formData2?.pregnant === 'yes' &&
                  <div>
                    <div className="form-group birthday-box mb-3">
                      <label>Expected due date</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <MobileDatePicker
                            disablePast
                            label=""
                            // className="error-input"
                            // inputFormat="MM/dd/yyyy"
                            value={formData2?.due_date}
                            onChange={(date: any) => handleDateChange(date, 'due_date')}
                            // value={valueYes?.due_date || ""}
                            // onChange={(date) => yesHandleChange(date)}
                            renderInput={(params: any) => <TextField placeholder="MM-DD-YYYY" {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                      <h5 className="error-msgtext">{error?.due_date}</h5>
                    </div>
                    <div className="form-group mb-3 baby-gender">
                      <label>Baby’s Gender</label>
                      <Autocomplete
                        disablePortal
                        id="Gender"
                        // value={formData2?.gender}
                        // className={`${error?.gender && "input-errorborder"}`}
                        options={Gender1}
                        // onChange={yesHandleChange}
                        value={formData2?.expected_baby_gender}
                        onChange={(e, value) => handleSelectOption(e, value, 'expected_baby_gender')}
                        renderInput={(params) => <TextField placeholder='Baby’s Gender' {...params} name="gender" />}
                      />
                      <h4 className="error-msgtext">{error?.expected_baby_gender}</h4>
                    </div>
                    {formData2?.expected_baby_gender === "It’s multiples" &&
                      < div className="multiple-main-container">
                        {
                          (formData2?.first_child_gender && formData2?.expected_baby_gender === "it’s multiples") &&
                          <div className="d-flex flex-row w-100">
                            <div className="me-3">
                              <label className="row-label">Select First Child Gender</label>
                            </div>

                            <div className="d-flex">
                              <div className="custom-radio custom-control-inline">
                                <input type="radio" className="form-check-input me-0 ms-auto" name="first_child_gender"
                                  checked={formData2?.first_child_gender === "male"}
                                  value="male"
                                  onChange={selectAnotherChild}
                                />
                                <label className="custom-control-label step_two me-3" >Boy</label>

                                <input type="radio" className="form-check-input me-0 ms-auto" name="first_child_gender"
                                  checked={formData2?.first_child_gender === "female"}
                                  value="female"
                                  onChange={selectAnotherChild}
                                />
                                <label className="custom-control-label step_two me-3" >Girl</label>
                              </div>
                            </div>
                          </div>
                        }

                        {formData2?.expected_baby_gender === "it’s multiples" &&
                          <div className="d-flex flex-md-row flex-column w-100">
                            <div className="me-3">
                              <label className="row-label">Select Second Child Gender</label>
                            </div>

                            <div className="d-flex">
                              <div className="custom-radio custom-control-inline">
                                <input type="radio" className="form-check-input me-0 ms-auto" name="second_child_gender"
                                  checked={formData2?.second_child_gender === "male"}
                                  value="male" onChange={selectAnotherChild}
                                />
                                <label className="custom-control-label step_two me-3" >Boy</label>

                                <input type="radio" className="form-check-input me-0 ms-auto" name="second_child_gender"
                                  checked={formData2?.second_child_gender === "female"}
                                  value="female" onChange={selectAnotherChild}
                                />
                                <label className="custom-control-label step_two me-3" >Girl</label>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          // addChild?.includes(1) &&
                          isChild.child1 && <div className="d-flex flex-md-row flex-column w-100">
                            <div className="me-3">
                              <label className="row-label">Select Another Child Gender</label>
                            </div>

                            <div className="d-flex">
                              <div className="custom-radio custom-control-inline">
                                <input type="radio" className="form-check-input me-0 ms-auto"
                                  checked={formData2?.another_child_gender_1 === "male"}
                                  name="another_child_gender_1" value="male" onChange={selectAnotherChild}
                                />
                                <label className="custom-control-label step_two me-3" >Boy</label>

                                <input type="radio" className="form-check-input me-0 ms-auto"
                                  checked={formData2?.another_child_gender_1 === "female"}
                                  name="another_child_gender_1" value="female" onChange={selectAnotherChild}
                                />
                                <label className="custom-control-label step_two me-3" >Girl</label>
                              </div>
                            </div>
                            <DeleteIcon onClick={() => handleDelete("child1")} />
                          </div>
                        }
                        {
                          // addChild?.includes(2) &&
                          isChild.child2 &&
                          <div className="d-flex flex-md-row flex-cnoGender.gender1olumn w-100">
                            <div className="me-3">
                              <label className="row-label">Select Another Child Gender</label>
                            </div>

                            <div className="d-flex">
                              <div className="custom-radio custom-control-inline">
                                <input type="radio" className="form-check-input me-0 ms-auto"
                                  checked={formData2?.another_child_gender_2 === "male"}
                                  name="another_child_gender_2" value="male" onChange={selectAnotherChild}
                                />
                                <label className="custom-control-label step_two me-3" >Boy</label>

                                <input type="radio" className="form-check-input me-0 ms-auto"
                                  checked={formData2?.another_child_gender_2 === "female"}
                                  name="another_child_gender_2" value="female" onChange={selectAnotherChild}
                                />
                                <label className="custom-control-label step_two me-3" >Girl</label>
                              </div>
                            </div>
                            <DeleteIcon onClick={() => handleDelete("child2")} />
                          </div>
                        }
                        {
                          (!isChild.child1 || !isChild.child2) &&
                          <a className="add-child-info secondary_hyperlink"
                            onClick={() => handleAddChild()}
                          >+ Add child</a>
                        }


                      </div>}

                    <div className="radio-area mb-3">
                      <h4>How are you welcoming your baby?</h4>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio3" name="c_section" value="Natural conception" onChange={handleRadioBtn2} checked={formData2?.c_section === 'Natural conception'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio3">Natural conception</label>

                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio4" name="c_section" value="Fertility assisted methods" onChange={handleRadioBtn2} checked={formData2?.c_section === 'Fertility assisted methods'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio4">Conception through IUI, IVF, or other fertility assisted methods</label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio5" name="c_section" value="Surrogacy" onChange={handleRadioBtn2} checked={formData2?.c_section === 'Surrogacy'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio5">Surrogacy</label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio5" name="c_section" value="Adoption" onChange={handleRadioBtn2} checked={formData2?.c_section === 'Adoption'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio5">Adoption</label>
                      </div>

                      <h5 className="error-msgtext">{error?.c_section}</h5>
                    </div>
                    <h4>Is this your first baby?</h4>
                    <div className="custom-radio custom-control-inline">
                      <input type="radio" id="fbaby1" name="is_this_your_first_baby"
                        value="yes"
                        checked={formData2?.is_this_your_first_baby === "yes"}
                        className="custom-control-input form-check-input"
                        onChange={handleRadioBtn}

                      />
                      <label className="custom-control-label step_two me-3" htmlFor="fbaby1">yes</label>
                      <input
                        type="radio"
                        id="fbaby2"
                        name="is_this_your_first_baby"
                        checked={formData2?.is_this_your_first_baby === "no"}
                        onChange={handleRadioBtn}
                        value="no" className="custom-control-input form-check-input"

                      />
                      <label className="custom-control-label step_two" htmlFor="fbaby2">no</label>
                    </div>

                    {

                      formData2?.is_this_your_first_baby === "no" && Array.isArray(formData2?.other_baby) &&
                      formData2?.other_baby.map((baby: any, index: number) => (
                        <div key={index}>
                          <h4>Child {index + 1}</h4>

                          <div className="form-group mb-3">
                            <label>Baby’s First Name</label>
                            <input type="text" name="name" value={baby?.name}
                              onChange={(e) => handleOtherChildData(e, index)}
                              className="form-control" id=""
                              placeholder="First-name" />
                            <h5 className="error-msgtext">
                              {error?.["name" + index]}
                            </h5>
                          </div>

                          <div className="form-group birthday-box mb-3">
                            <label>Baby’s Birthdate</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Stack spacing={3}>
                                <MobileDatePicker
                                  disableFuture
                                  label=""
                                  // inputFormat="MM/dd/yyyy"
                                  value={baby?.dob || ""}
                                  onChange={(date: any) => handleOtherChildData(date, index, 'dob')}
                                  renderInput={(params: any) => <TextField placeholder="mm/dd/yyyy" {...params} />}
                                />
                              </Stack>
                            </LocalizationProvider>
                            <h5 className="error-msgtext">
                              {error?.["dob" + index]}
                            </h5>
                          </div>

                          <div className="form-group mb-3 baby-gender">
                            <label>Baby’s Gender</label>
                            <Autocomplete
                              disablePortal
                              id="Gender"
                              value={baby?.gender}
                              className={`${error?.gender && "input-errorborder"}`}
                              options={Gender}
                              onChange={(val: any, gneder) => handleOtherChildData(gneder, index, 'gender')}

                              renderInput={(params) => <TextField {...params} name="gender" />}
                            />
                            <h4 className="error-msgtext">
                              {error?.["gender" + index]}
                            </h4>
                          </div>

                        </div>
                      ))
                    }
                    {
                      formData2?.is_this_your_first_baby === "no" && <>
                        {formData2?.other_baby?.length > 1 && <p><a className="add-child-info secondary_hyperlink" onClick={() => handleOhterChild("remove")}>- Remove child</a></p>}
                        {formData2?.other_baby?.length < 3 && <p><a className="add-child-info secondary_hyperlink" onClick={() => handleOhterChild("add")}>+ Add child</a></p>}
                      </>
                    }
                  </div>

                }
                {
                  formData2?.pregnant === 'no' &&
                  <div>
                    <div className="form-group mb-3">
                      <label>Baby’s First Name</label>
                      <input type="text" name="baby_first_name"
                        className="form-control" onChange={handleInputTextNo}
                        value={formData2?.baby_first_name}
                        placeholder="First-name" />
                      <h5 className="error-msgtext">{error?.baby_first_name}</h5>
                    </div>

                    <div className="form-group birthday-box mb-3">
                      <label>Baby’s Birthdate</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <MobileDatePicker
                            disableFuture
                            label=""
                            // className="error-input"
                            // inputFormat="MM/dd/yyyy"
                            value={formData2?.baby_dob}
                            onChange={(date: any) => handleDateChange(date, 'baby_dob')}
                            // value={valueYes?.due_date || ""}
                            // onChange={(date) => yesHandleChange(date)}
                            renderInput={(params: any) => <TextField placeholder="MM-DD-YYYY" {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                      <h5 className="error-msgtext">{error?.baby_dob}</h5>
                    </div>

                    <div className="form-group mb-3 baby-gender">
                      <label>Baby’s Gender</label>
                      <Autocomplete
                        disablePortal
                        id="Gender"
                        // value={formData2?.baby_gender}
                        // className={`${error?.gender && "input-errorborder"}`}
                        options={Gender4}
                        value={formData2?.baby_gender}
                        onChange={(e, value) => handleSelectOption(e, value, 'baby_gender')}
                        renderInput={(params) => <TextField placeholder='Baby’s Gender' {...params} name="gender" />}
                      />
                      <h4 className="error-msgtext">{error?.baby_gender}</h4>
                    </div>
                    {noGender.gender1 && <>
                      <div className="form-group mb-3">
                        <label>Baby’s First Name</label>
                        <input type="text" name="baby_first_name1"
                          value={formData2?.baby_first_name1}
                          onChange={handleInputTextNo} className="form-control" id=""
                          placeholder="First-name" />
                        <h5 className="error-msgtext">
                          {error?.baby_first_name1}
                        </h5>
                      </div>

                      <div className="form-group birthday-box mb-3">
                        <label>Baby’s Birthdate</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <MobileDatePicker
                              disableFuture
                              label=""
                              // inputFormat="MM/dd/yyyy"
                              onChange={(date: any) => handleDateChangeOther(date, "dob_anothor_child1")}
                              value={formData2?.dob_anothor_child1}
                              renderInput={(params: any) => <TextField placeholder="mm/dd/yyyy" {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                        <h5 className="error-msgtext">
                          {error?.dob_anothor_child1}
                        </h5>
                      </div>

                      <div className="form-group mb-3 baby-gender">
                        <label>Baby’s Gender</label>
                        <Autocomplete
                          disablePortal
                          id="Gender"
                          value={formData2?.first_gender}
                          className={`${error?.gender && "input-errorborder"}`}
                          options={Gender2}
                          onChange={(e: any, value: any) => handleSelectOption1(e, value, "first_gender")}
                          renderInput={(params) => <TextField {...params} name="gender" />}
                        />
                        <h4 className="error-msgtext">{error?.first_gender}</h4>
                      </div>
                      <a className="add-child-info secondary_hyperlink" onClick={() => handleChildOther("gender1")}>- Remove Child</a>

                    </>}
                    {noGender.gender2 && <>

                      <div className="form-group mb-3">
                        <label>Baby’s First Name</label>
                        <input type="text" name="baby_first_name2"
                          value={formData2?.baby_first_name2}
                          onChange={handleInputTextNo} className="form-control" id=""
                          placeholder="First-name" />
                        <h5 className="error-msgtext">
                          {error?.baby_first_name2}
                        </h5>
                      </div>

                      <div className="form-group birthday-box mb-3">
                        <label>Baby’s Birthdate</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <MobileDatePicker
                              disableFuture
                              label=""
                              // inputFormat="MM/dd/yyyy"
                              value={formData2?.dob_anothor_child2}
                              // value={""}
                              onChange={(date: any) => handleDateChangeOther(date, "dob_anothor_child2")}
                              renderInput={(params: any) => <TextField placeholder="mm/dd/yyyy" {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                        <h5 className="error-msgtext">
                          {error?.dob_anothor_child2}
                        </h5>
                      </div>

                      <div className="form-group mb-3 baby-gender">
                        <label>Baby’s Gender</label>
                        <Autocomplete
                          disablePortal
                          id="Gender"
                          value={formData2?.second_gender}
                          className={`${error?.gender && "input-errorborder"}`}
                          options={Gender2}
                          onChange={(e: any, value: any) => handleSelectOption1(e, value, "second_gender")}
                          renderInput={(params) => <TextField {...params} name="gender" />}
                        />
                        <h4 className="error-msgtext">{error?.second_gender}</h4>
                      </div>
                      <a className="add-child-info secondary_hyperlink" onClick={() => handleChildOther("gender2")}>- Remove Child</a>
                    </>}
                    <div>
                      {(!noGender.gender1 || !noGender.gender2) && <a className="add-child-info secondary_hyperlink" onClick={() => handleChildOther("add")}>+ Add child</a>}
                    </div>
                    <div className="checkbox-controler mb-3">
                      <h4>How are you feeding your baby? </h4>
                      <div className="d-flex w-100 justify-content-start flex-wrap flex-sm-row flex-column">
                        <div className="custom-checkbox">
                          <input type="checkbox" id="customRadio3" name="feeding_choice" value="Breastfeeding"
                            checked={Array.isArray(formData2?.feeding_choice) && formData2.feeding_choice.some((item: string) => item === 'Breastfeeding')}
                            onChange={handleFeedingChoice} className="custom-control-input form-check-input" />
                          <label className="custom-control-label step_two" htmlFor="customRadio3">Breast-feeding</label>

                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input type="checkbox" id="customRadio4" checked={Array.isArray(formData2.feeding_choice) && formData2.feeding_choice?.some((item: string) => item === 'Pumping')}
                            name="feeding_choice" value="Pumping" onChange={handleFeedingChoice} className="custom-control-input form-check-input" />
                          <label className="custom-control-label step_two" htmlFor="customRadio4">Pumping</label>
                        </div>
                        <div className="custom-checkbox">
                          <input type="checkbox" id="customRadio5" name="feeding_choice"
                            checked={Array.isArray(formData2.feeding_choice) && formData2.feeding_choice?.some((item: string) => item === 'Formulafeeding')}
                            value="Formulafeeding" onChange={handleFeedingChoice} className="custom-control-input form-check-input" />
                          <label className="custom-control-label step_two" htmlFor="customRadio4">Formula Feeding</label>
                        </div>

                        <div className="custom-checkbox">
                          <input type="checkbox" id="customRadio5" name="feeding_choice" onChange={handleFeedingChoice} value="Donorbreastmilk"
                            checked={Array.isArray(formData2.feeding_choice) && formData2.feeding_choice?.some((item: string) => item === 'Donorbreastmilk')}
                            className="custom-control-input form-check-input" />
                          <label className="custom-control-label step_two" htmlFor="customRadio4">Donor Breastmilk</label>
                        </div>
                        <div className="custom-checkbox">
                          <input type="checkbox" id="customRadio5" name="feeding_choice" onChange={handleFeedingChoice} value="solid_foods"
                            checked={Array.isArray(formData2.feeding_choice) && formData2.feeding_choice?.some((item: string) => item === 'solid_foods')}
                            className="custom-control-input form-check-input" />
                          <label className="custom-control-label step_two" htmlFor="customRadio4">Solid Foods</label>
                        </div>
                      </div>

                      {/* <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio5" name="feeding_choice" onChange={handleRadioBtn} checked={formData2?.feeding_choice === "All"} value="All" className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio5">Select All</label>
                      </div> */}
                      <h5 className="error-msgtext">{error?.feeding_choice}</h5>
                    </div>
                    <div className="radio-area mb-3">
                      <h4>Was your baby born prematurely (prior to 37 weeks)?</h4>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio6" name="baby_premature" value="yes" onChange={handleRadioBtn} checked={formData2?.baby_premature === 'yes'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio6">yes</label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio7" name="baby_premature" value='no' onChange={handleRadioBtn} checked={formData2?.baby_premature === 'no'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio7">no</label>
                      </div>
                      <h5 className="error-msgtext">{error?.baby_premature}</h5>
                    </div>

                    <div className="radio-area mb-3">
                      <h4>How did you welcome your baby?</h4>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio3" name="c_section" value="Natural conception" onChange={handleRadioBtn} checked={formData2?.c_section === 'Natural conception'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio3">Natural conception</label>

                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio4" name="c_section" value="Fertility assisted methods" onChange={handleRadioBtn} checked={formData2?.c_section === 'Fertility assisted methods'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio4">Conception through IUI, IVF, or other fertility assisted methods</label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio5" name="c_section" value="Surrogacy" onChange={handleRadioBtn} checked={formData2?.c_section === 'Surrogacy'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio5">Surrogacy</label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio5" name="c_section" value="Adoption" onChange={handleRadioBtn} checked={formData2?.c_section === 'Adoption'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio5">Adoption</label>
                      </div>

                      {/* <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio3" name="delivery_choice" checked={formData2?.delivery_choice === "Natural conception"} onChange={handleRadioBtn} value="Natural conception" className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio3">Natural conception</label>

                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio4" name="delivery_choice" onChange={handleRadioBtn} checked={formData2?.delivery_choice === "Fertility assisted methods"} value="Fertility assisted methods" className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio4">Conception through IUI, IVF, or other fertility assisted methods</label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio5" name="delivery_choice" onChange={handleRadioBtn} checked={formData2?.delivery_choice === "Surrogacy"} value="Surrogacy" className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio5">Surrogacy</label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio5" name="delivery_choice" onChange={handleRadioBtn} checked={formData2?.delivery_choice === "Adoption"} value="Adoption" className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio5">Adoption</label>
                      </div> */}

                      <h5 className="error-msgtext">{error?.c_section}</h5>
                    </div>

                    <div className="radio-area mb-3">
                      <h4>Did you struggle with fertility?</h4>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio6" name="fertility_question" value="yes" onChange={handleRadioBtn} checked={formData2?.fertility_question === 'yes'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio6">yes</label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadio7" name="fertility_question" value="no" onChange={handleRadioBtn} checked={formData2?.fertility_question === 'no'} className="custom-control-input form-check-input" />
                        <label className="custom-control-label step_two" htmlFor="customRadio7">no</label>
                      </div>
                      <h5 className="error-msgtext">{error?.fertility_question}</h5>
                    </div>

                  </div>

                }

                <div className='d-flex justify-content-between mt-5'>
                  <button type="button" className="primary-blue-small-btn btn w-50 me-2" disabled={loading} onClick={handleBabyProfile} >{loading ? <CircularProgress /> : 'Save Changes'}</button>
                  <button type="button" className="primary-outline-btn h50 btn w-50 ms-2" onClick={handleCancel1} >Cancel</button>
                </div>

              </form>
            </div>
          </div>
        </div >
      }

      {
        formNo == 2 &&
        <div className='col-lg-12 col-md-12'>
          <h5 className='section-title'>About your Partner</h5>
          <div className='edit-profile-container py-5 mb-4'>
            <div className="step-innercontent step4">
              <form className="step-form">
                <div className="form-group mb-3">
                  <label>Partner or Support Person’s First Name</label>
                  <input type="text" name="family_first_name"
                    value={formData3?.family_first_name}
                    onChange={handleInputText}
                    className=' form-control' id=""
                    placeholder="Name" />
                  <h5 className="error-msgtext">{error?.family_first_name}</h5>
                </div>

                <div className="form-group mb-3">
                  <label>Partner or Support Person’s Last Name</label>
                  <input type="text" name="family_last_name"
                    value={formData3?.family_last_name}
                    onChange={handleInputText}
                    className='form-control' id=""
                    placeholder="Last Name" />
                  <h5 className="error-msgtext">{error?.family_last_name}</h5>
                </div>
                <div className="form-group mb-3">
                  <label>Partner or Support Person’s Email</label>
                  <input type="email" name="family_email" value={formData3?.family_email}
                    // onChange={handleChange} 
                    className='form-control'
                    id=""
                    onChange={handleInputText}
                    placeholder="Email" />
                  <h5 className="error-msgtext">{error.family_email}</h5>
                </div>

                <div className='d-flex justify-content-between mt-5'>
                  <button type="button" className="primary-blue-small-btn btn w-50 me-2" disabled={loading} onClick={handleProfileFamily} >{loading ? <CircularProgress /> : 'Save Changes'}</button>
                  <button type="button" className="primary-outline-btn h50 btn w-50 ms-2" onClick={handleCancel2} >Cancel</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      }

      {
        formNo == 3 &&
        <div className='col-lg-12 col-md-12'>
          <h5 className='section-title'>Account settings</h5>
          <div className='edit-profile-container py-5 mb-4'>
            <div className="step-innercontent step4">
              <form className="step-form">
                <div className="form-group mb-3 position-relative">
                  <label>Current Password</label>
                  <input
                    // type="password"
                    type={isPasswordShow ? 'text' : 'password'}
                    value={formData4?.current_password}
                    name="current_password"
                    className='form-control'
                    onChange={handleInputText}
                    id="exampleInputPassword1"
                    placeholder="Enter your current password"
                  // onChange={handleChange}
                  />
                  {/* <img src={Eye} alt="" onClick={toggleIsPasswordShowValue} className="eye-passimg" />
                   */}
                  <img src={Eye} alt="" onClick={toggleIsCurrPassShowValue} className="eye-passimg" />
                  <h5 className="error-msgtext">{error?.current_password}</h5>
                </div>

                <div className="form-group mb-3 position-relative">
                  <label>New Password</label>
                  <input
                    // type="password"
                    type={isPasswordShow1 ? 'text' : 'password'}
                    name="password"
                    className='form-control'
                    onChange={handleInputText}
                    value={formData4?.password}
                    id="exampleInputPassword1"
                    placeholder="Enter new password"
                  // onChange={handleChange}
                  />
                  <img src={Eye} alt="" onClick={toggleIsPassShowValue} className="eye-passimg" />
                  <h5 className="error-msgtext">{error?.password}</h5>
                </div>
                <div className="form-group mb-3 position-relative">
                  <label>Confirm Password</label>
                  <input
                    // type="password"
                    type={isPasswordShow2 ? 'text' : 'password'}
                    name="password_confirmation"
                    className='form-control'
                    onChange={handleInputText}
                    value={formData4?.password_confirmation}
                    id="exampleInputPassword1"
                    placeholder="Enter confirm password"
                  // onChange={handleChange}
                  />
                  <img src={Eye} alt="" onClick={toggleIsConPassShowValue} className="eye-passimg" />
                  <h5 className="error-msgtext">{error?.password_confirmation}</h5>
                </div>

                <div className='d-flex justify-content-between mt-5'>
                  <button type="button" className="primary-blue-small-btn btn w-50 me-2" disabled={loading} onClick={handleChangePass}>{loading ? <CircularProgress /> : 'Save Changes'}</button>
                  <button type="button" className="primary-outline-btn h50 btn w-50 ms-2" onClick={handleCancel3} >Cancel</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Step1

const Gender = [
  { label: 'She/her/hers', value: 'She/her/hers' },
  { label: 'he/him/his', value: 'he/him/his' },
  { label: 'they/them/theirs', value: 'they/them/theirs' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
]
const Gender4 = [
  { label: 'Boy', value: "Boy" },
  { label: 'Girl', value: "Girl" },
  { label: 'Twins', value: "Twins" },
  { label: 'Prefer not to say', value: "Prefer not to say" },
]
const Gender5 = [
  { label: 'Boy', value: "Boy", name: 'gender' },
  { label: 'Girl', value: "Girl", name: 'gender' },
  { label: 'It’s a Surprise', value: "It’s a Surprise", name: 'gender' },
  { label: 'it’s multiples', value: "it’s multiples", name: 'gender' },
]
const Gender1 = [
  { label: 'Boy', value: "Boy", name: 'first_gender' },
  { label: 'Girl', value: "Girl", name: 'first_gender' },
  { label: 'It’s a Surprise', value: "It’s a Surprise", name: 'first_gender' },
  { label: 'It’s multiples', value: "It’s multiples", name: 'first_gender' },
]
const Gender2 = [
  { label: 'Boy', value: "Boy", name: 'second_gender' },
  { label: 'Girl', value: "Girl", name: 'second_gender' },
  { label: 'It’s a Surprise', value: "It’s a Surprise", name: 'second_gender' },
  { label: 'it’s multiples', value: "it’s multiples", name: 'second_gender' },
]