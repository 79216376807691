import React from "react";
import "../Components/PaymentMethodForm/PaymentMethodForm.scss";
import { Row } from "react-bootstrap";
import { formatter } from "../Components/PaymentMethodForm/helpers";
import { AsyncState } from "../../../Utils/asyncState";
import usePaymentMethodForm from "./usePaymentMethodForm";
import { Autocomplete, TextField } from "@mui/material";

type usePaymentMethodFormReturnType = ReturnType<typeof usePaymentMethodForm>;

interface PaymentMethodFormProps extends usePaymentMethodFormReturnType {
  paymentAsyncState: AsyncState;
  maxWidth?: string;
  disableAll?: boolean;
}

const PaymentMethodForm = ({
  fields,
  errors,
  handleChange,
  handleNumber,
  handleExpirationDate,
  maxWidth,
  disableAll,
  statesList,
  countriesList,
  changeState,
  changeCountry,
}: PaymentMethodFormProps) => {

  return (
    <div
      className="PaymentMethodFormMain"
      style={{ maxWidth, opacity: disableAll ? "0.4" : "1" }}
    >
      <Row className="mt-3">
        <div className="col-md-6">
          <label>First Name</label>
          <input
            disabled={disableAll}
            type="text"
            name="firstName"
            value={fields.firstName}
            onChange={handleChange}
            className="stripeFormInput"
            placeholder="First name"
          />
          <p className="error">{errors.firstName}</p>
        </div>
        <div className="col-md-6">
          <label>Last Name</label>
          <input
            disabled={disableAll}
            type="text"
            name="lastName"
            value={fields.lastName}
            className="stripeFormInput"
            onChange={handleChange}
            placeholder="Last name"
          />
          <p className="error">{errors.lastName}</p>
        </div>
      </Row>
      <div className="col-sm-12 mt-3">
        <label> Credit card number</label>
        <input
          disabled={disableAll}
          type="text"
          name="cardNumber"
          value={fields.cardNumber}
          className="stripeFormInput"
          onChange={handleNumber(16)}
          placeholder="xxxx xxxx xxxx xxxx"
        />
        <p className="error">{errors.cardNumber}</p>
      </div>

      <Row className="mt-3">
        <div className="col-md-4">
          <label>Expiration</label>
          <input
            disabled={disableAll}
            type="text"
            name="exp"
            value={formatter(fields.exp)}
            onChange={handleExpirationDate}
            className="stripeFormInput"
            placeholder="MM / YY"
          />
          <p className="error">{errors.exp}</p>
        </div>
        <div className="col-md-4">
          <label>CVV</label>
          <input
            disabled={disableAll}
            type="text"
            name="cvv"
            value={fields.cvv}
            className="stripeFormInput"
            placeholder="xxx"
            onChange={handleNumber(4)}
          />
          <p className="error">{errors.cvv}</p>
        </div>
        <div className="col-md-4">
          <label>Billing Zip Code</label>
          <input
            disabled={disableAll}
            type="text"
            name="zipCode"
            value={fields.zipCode}
            className="stripeFormInput"
            onChange={handleNumber(5)}
            placeholder="xxxxx"
          />
          <p className="error">{errors.zipCode}</p>
        </div>
      </Row>

      <Row className="mt-3">
        <div className="col-md-12">
          <label>Address</label>
          <input
            type="text"
            name="address"
            value={fields?.address}
            className="form-control"
            onChange={handleChange}
          />

          <p className="error">{errors?.address} </p>
        </div>
      </Row>
      <Row className="mt-3">
        <div className="col-md-4">
          <label>City</label>
          <input
            type="text"
            name="city"
            value={fields?.city}
            className="form-control"
            onChange={handleChange}
          />
          <p className="error">{errors?.city}</p>
        </div>

        <div className="col-md-4">
          <label>State</label>
          <Autocomplete
            className={"mt-0"}
            options={statesList}
            onChange={changeState}
            clearOnBlur={true}
            value={fields.state}
            renderInput={(params) => <TextField {...params} />}
          />
          <p className="error">{errors?.state}</p>
        </div>

        <div className="col-md-4">
          <label>Country</label>

          <Autocomplete
            className={"mt-0"}
            options={countriesList}
            value={fields.country}
            clearOnBlur={true}
            onChange={changeCountry}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params?.inputProps,
                  autoComplete: "off",
                  form: {
                    autocomplete: "off",
                  },
                }}
              />
            )}
          />
          <p className="error">{errors?.country}</p>
        </div>
      </Row>
    </div>
  );
};

export default PaymentMethodForm;
