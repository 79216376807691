import * as Yup from "yup";

export interface Scheme {
  [key: string]: any;
}

export interface Fields {
  [key: string]: any;
}

export const scheme: Scheme = {
  firstName: Yup.string()
    .required("First name is required"),
  lastName: Yup.string()
    .required("Last name is required"),
  cardNumber: Yup.string()
    .min(14, "Card number must be at least 14 characters")
    .max(16)
    .required("Please enter a valid card number"),
  exp: Yup.string()
    .min(4, "Please enter a valid date format")
    .max(4, "Please enter a valid date format")
    .required("Please enter a valid date format"),
  cvv: Yup.string()
    .min(3, "CVV must be at least 3 characters")
    .max(4)
    .required("Please enter a valid security code"),
  zipCode: Yup.string()
    .matches(/\b\d{5}\b/, {
      message: "Billing Zip Code must be exactly 5 numbers",
      excludeEmptyString: true,
    })
    .required("Billing Zip Code is a required field"),
};

export const addressScheme: Scheme = {
  country: Yup.string()
    .required("Country is required"),
  state: Yup.string()
    .required("State is required"),
  address: Yup.string()
    .required("Address is required"),
  city: Yup.string()
    .required("City is required"),
};

export function formatter(value: any) {
  const arr = value?.match(/.{1,2}/g);
  if (arr?.length > 1) {
    return arr.join(" / ");
  }
  return value;
}

export const handleDate = (value: any) => {
  const prepareValue = value.replace(/\D/g, "").slice(0, 4);
  const arr = prepareValue.match(/.{1,2}/g);
  if (prepareValue[0] > 1 || (arr && arr[0] === "00")) {
    return "";
  }
  if (arr?.length >= 2 && arr[1].length >= 2) {
    const expiryMonth = arr[0];
    const expiryYear = arr[1];
    const currentYear = new Date().getFullYear();
    const expiryYearLong = parseInt(`20${expiryYear}`);
    if (
      expiryYearLong < currentYear ||
      (expiryYearLong === currentYear &&
        parseInt(expiryMonth) < new Date().getMonth() + 1)
    ) {
      return prepareValue.slice(0, 3);
    }
    return prepareValue;
  }
  if (!arr || (arr?.length > 0 && arr[0] <= 12)) {
    return prepareValue;
  }
};

export const yupValidation = (fields: Fields, scheme: Scheme) => {
  return Promise.allSettled(
    Object.entries(fields).map(([key, value]: [string, any]) =>
      Yup.object()
        .shape({ [key]: scheme[key] })
        .validate({ [key]: value })
    )
  ).then((values) => {
    const errorsEntries: any = values
      .map(({ reason }: any) => {
        if (reason) {
          return [reason.path, reason.message];
        }
        return null;
      })
      .filter((v) => v);

    const nextErrors = Object.fromEntries(errorsEntries);

    return { valid: !Object.values(nextErrors).length, errors: nextErrors };
  });
};
