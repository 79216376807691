import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap';
import podCastImg from "../../Assets/img/podCast.svg"
import "./PodcastQuestion.scss";
import { TextFieldProps } from '@mui/material/TextField';
import { podCastSubmission } from '../../Utils/validation';
import { CreateQuestion, Question } from '../../Interfaces/userInterfaces';
import { podCastSubmissionData } from '../../Service/blog';
import { CircularProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { NURSECONSULTS } from '../../Routes/RouteConstent';
import { Link, useNavigate } from 'react-router-dom';
import { nursing_consult, phoneRegex, usPhoneRegex } from '../../Utils/constant';
import { GetPrefilledData } from '../../Utils/getUserData/getUserData';
import CongratsModel2 from '../../Common/CongratsPopup2/CongratsPopup2';
import isMebershipAndActiveCheck from '../../Utils/membership_check';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { HeroBanner}  from '../../Common/HeroBanner';


let initialState: CreateQuestion = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  dob: "",
  subject: "",
  question: "",
  level: "medium"
}

interface IProps {

}

const PodcastQuestion: React.FC<IProps> = () => {

  const [questionData, setQuestionData] = useState<CreateQuestion>(initialState);
  const [error, setError] = useState<Question>({})
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [user_data] = GetPrefilledData()
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  let user: any = localStorage.getItem("Nurture_user_data")
  user = JSON.parse(user)

  const handleChange = (e: any) => {

    let { name, value } = e.target;
    let newData = { [name]: value };

    setQuestionData({
      ...questionData,
      [name]: value
    })
    const { errors } = podCastSubmission(newData)
    setError({
      ...error,
      ...errors
    })
  }

  const handleDateChange = async (val: Date | any) => {
    setQuestionData({
      ...questionData,
      ['dob']: val
      // ['dob']: moment(val).format('L')
    })
    setError({
      ...error,
      ["dob"]: ""
    })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    try {
      if (
        !questionData?.first_name || !questionData?.last_name || !questionData?.email || !questionData?.phone || !phoneRegex.test(questionData?.phone) || !questionData?.subject ||
        !questionData.question || !questionData?.dob || questionData?.dob === "Invalid date") {

        let errors: any = {}

        if (!questionData?.first_name) {
          errors.first_name = "Please enter your first name"
        }

        if (!questionData?.last_name) {
          errors.last_name = "Please enter your last name"
        }

        if (!questionData?.email) {
          errors.email = "Please enter your email"
        }
        if (!phoneRegex.test(questionData?.phone)) {
          errors.phone = "Please enter valid number"
        }
        if (!questionData?.phone) {
          errors.phone = "Please enter your phone number"
        }

        if (!questionData?.subject) {
          errors.subject = "Please enter your subject"
        }

        if (!questionData?.question) {
          errors.question = "Please enter your question"
        }
        if (!questionData?.dob) {
          errors.dob = "Please select date"
        }

        setError(errors)


      } else {
        setLoading(true)

        let param = {
          first_name: questionData?.first_name,
          last_name: questionData?.last_name,
          email: questionData?.email,
          phone: questionData?.phone,
          dob: questionData?.dob,
          subject: questionData?.subject,
          question: questionData?.question,
          user_id: user?.id
        }

        let res: any = await podCastSubmissionData(param)
        isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
        if (res?.data?.success) {
          setLoading(false)
          setOpen(true)
          // setQuestionData(initialState)
        }
      }
    }
    catch (err) {
      console.log(err)
      setLoading(false)
    }

  }
  useEffect(() => {
    if (user_data) {
      setQuestionData(user_data)
    }
  }, [user_data]);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     const scrollTargetElement = document.getElementById(
  //       "scrollTargetElement"
  //     );
  //     scrollTargetElement?.scrollIntoView({ behavior: "smooth" });
  //   });
  // }, []);

  return (
    <div>

      <CongratsModel2
        open={open}
        setOpen={setOpen}
        btnText="Book a 30 min Nursing consult"
        Page={NURSECONSULTS}
        otherPageSetting={{ param: nursing_consult }}
        text={'Thanks for submitting the podcast question. Someone from NAPS may reach out to you soon. If you do not wish to have your question answered live on our podcast, you may be interested more in a nursing consultation call.'} />
      <Container fluid className='Podcast-container'>
        <div className="py-3">
          <HeroBanner
            hero={podCastImg}
            maxDesktopHeight={500}
          />
        </div>
        <Row no-gutter className='pale-bg py-4' >
          <div className='col-lg-12 col-md-12 podcast-discription-section'>
            <div className='container'>
              <Row className='justify-content-center'>
                <div className='col-lg-12 col-md-12'>
                  <h3 className="text-center podcast-section-title my-3">Podcast Question <span className="strong red">Submission</span></h3>
                  <div className='px-5 podcast_description'>
                    <p className='text-center'>Thank you for your interest in submitting a question to be answers on our podcast, <a href='https://podcasts.apple.com/us/podcast/unswaddled/id1560213381'target='_blank'> Unswaddled: The Truth About Parenting.</a></p>
                    <p className='text-center'><strong>By submitting this form, you are agreeing that if your question is chosen to be answered on the podcast, that the format for getting your question answered with be through a live call. This live call with be recorded, including both the audio and video, and will be posted on all of our podcast platforms, as well as on our YouTube channel.</strong> </p>
                    <p className='text-center'>There is no guarantee that your question will be answered, but if you have been chosen, we will reach out to you directly via email or phone to set up a time for your call. </p>
                    <p className='text-center'> If you do not wish to have your question answered live on our podcast, you may be interested more in a <Link to={NURSECONSULTS} state={{ param: nursing_consult }}> 1:1 nursing consultation call. </Link> </p>
                  </div>
                </div>
              </Row>
            </div>

          </div >
        </Row >
        <Row className='podcast-form my-4'>
          <div className='col-lg-12 col-md-12'>
            <div className='container'>
              <Row className='justify-content-center'>
                <div className='col-lg-8 col-md-8'>
                  <form className='row'>
                    <div className='form-group col-lg-12 mb-3'>
                      <div className='row'>
                        <div className='form-group col-lg-6 col-md-6' id="scrollTargetElement">
                          <label>First Name <span className='required'>*</span></label>
                          <input type="text" className="form-control"
                            onChange={handleChange}
                            value={questionData?.first_name}
                            name="first_name"
                            placeholder="First Name" />
                          <h4 className="error-msgtext">{error.first_name}</h4>
                        </div>
                        <div className='form-group col-lg-6 col-md-6'>
                          <label>Last Name <span className='required'>*</span></label>
                          <input type="text" name="last_name" className="form-control"
                            onChange={handleChange}
                            value={questionData?.last_name} placeholder="Last name" />
                          <h4 className="error-msgtext">{error.last_name}</h4>
                        </div>
                      </div>
                    </div>

                    <div className='form-group col-lg-12 mb-3'>
                      <div className='row'>
                        <div className='form-group col-lg-6 col-md-6'>
                          <label>Email <span className='required'>*</span></label>
                          <input type="email" name="email" className="form-control" value={questionData?.email} id="" onChange={handleChange} placeholder='Email' />
                          <h4 className="error">{error?.email}</h4>
                        </div>
                        <div className='form-group col-lg-6 col-md-6'>
                          <label>Phone number <span className='required'>*</span></label>
                          <input type="number" name="phone" className="form-control" id="" value={questionData?.phone} onChange={handleChange} placeholder='Phone number' />
                          <h4 className="error">{error?.phone}</h4>
                        </div>
                      </div>
                    </div>

                    <div className='form-group col-lg-12 mb-3'>
                      <div className='row'>
                        <div className='form-group col-lg-6 col-md-6'>
                          <label>Due Date / Child's Birthday <span className='required'>*</span></label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <MobileDatePicker
                                value={questionData?.dob || ""}
                                toolbarTitle="Choose date"
                                // disableFuture

                                onChange={(val: any) => handleDateChange(val)}
                                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField error={false} placeholder="MM-DD-YYYY"  {...params} />}
                              />

                            </Stack>
                          </LocalizationProvider>
                          <h4 className="error">{error?.dob}</h4>
                        </div>
                        <div className='form-group col-lg-6 col-md-6'>
                          <label>Subject<span className='required'>*</span></label>
                          <input type="text" name="subject" className="form-control" onChange={handleChange} id="" value={questionData?.subject} placeholder="Subject" />
                          <h4 className="error">{error?.subject}</h4>
                        </div>
                      </div>
                    </div>

                    <div className='form-group col-lg-12 mb-3'>
                      <div className='row'>
                        <div className='form-group col-lg-12 col-md-12'>
                          <label>What is your question? <span className='required'>*</span></label>
                          <textarea name='question' className="form-control" onChange={handleChange} value={questionData?.question} placeholder='Question' />
                          <h4 className="error">{error?.question}</h4>
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-12 col-md-12'>
                      <button className="primary-blue-btn mb-5 w-100" disabled={loading} onClick={handleSubmit}>{loading ? <CircularProgress /> : "Submit"}</button>
                    </div>
                  </form>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Container >
    </div >
  )
}

export default PodcastQuestion