import React from "react";
import "./stripeForm.scss";
import { Row } from "react-bootstrap";
import { scheme, formatter, handleDate, yupValidation } from "./helpers";
import { CircularProgress } from "@mui/material";

const StripeForm = ({ addPaymentMethodRequest, postNewPaymentMethod }: any) => {
  const [fields, setFields] = React.useState({
    first_name: "",
    last_name: "",
    exp: "",
    cvv: "",
    zip_code: "",
    cardNumber: "",
  });

  const [errors, setErrors] = React.useState<Errors>({});

  const handleChange = (evt: any) => {
    setFields((v) => ({ ...v, [evt.target.name]: evt.target.value }));
  };

  const handleNumber = (max: number) => (evt: any) => {
    setFields((v) => ({
      ...v,
      [evt.target.name]: evt.target.value.slice(0, max),
    }));
  };

  const handleExpirationDate = (evt: any) => {
    setFields((v) => ({
      ...v,
      [evt.target.name]: handleDate(evt.target.value),
    }));
  };

  const submit = async () => {
    const { valid, errors } = await yupValidation(fields, scheme);

    setErrors(errors);

    if (valid) {
      const { first_name, last_name, exp, ...restFields } = fields;
      const [expMonth, expYear] = [exp.slice(0, 2), exp.slice(2)];

      const params = {
        ...restFields,
        cardholderName: `${first_name} ${last_name}`,
        expMonth,
        expYear,
      };

      addPaymentMethodRequest(params);
    }
  };

  return (
    <>
      <Row className="mt-3">
        <div className="col-md-6">
          <label>First Name</label>
          <input
            type="text"
            name="first_name"
            value={fields.first_name}
            onChange={handleChange}
            className="stripeFormInput"
            placeholder="First name"
          />
          <p className="error">{errors.first_name}</p>
        </div>
        <div className="col-md-6">
          <label>Last Name</label>
          <input
            type="text"
            name="last_name"
            value={fields.last_name}
            className="stripeFormInput"
            onChange={handleChange}
            placeholder="Last name"
          />
          <p className="error">{errors.last_name}</p>
        </div>
      </Row>
      <div className="col-sm-12 mt-3">
        <label> Credit card number</label>
        <input
          type="text"
          name="cardNumber"
          value={fields.cardNumber}
          className="stripeFormInput"
          onChange={handleNumber(16)}
          placeholder="xxxx xxxx xxxx xxxx"
        />
        <p className="error">{errors.cardNumber}</p>
      </div>

      <Row className="mt-3">
        <div className="col-md-4">
          <label>Expiration</label>
          <input
            type="text"
            name="exp"
            value={formatter(fields.exp)}
            onChange={handleExpirationDate}
            className="stripeFormInput"
            placeholder="MM / YYYY"
          />
          <p className="error">{errors.exp}</p>
        </div>
        <div className="col-md-4">
          <label>CVV</label>
          <input
            type="text"
            name="cvv"
            value={fields.cvv}
            className="stripeFormInput"
            placeholder="xxx"
            onChange={handleNumber(4)}
          />
          <p className="error">{errors.cvv}</p>
        </div>
        <div className="col-md-4">
          <label>Billing Zip Code</label>
          <input
            type="text"
            name="zip_code"
            value={fields.zip_code}
            className="stripeFormInput"
            onChange={handleNumber(5)}
            placeholder="xxxxx"
          />
          <p className="error">{errors.zip_code}</p>
        </div>
      </Row>

      <div className="col-sm-12 mt-5">
        <button
          className="btn primary-blue-btn"
          onClick={submit}
          disabled={postNewPaymentMethod.pending}
        >
          {postNewPaymentMethod.pending ? (
            <CircularProgress size={30} />
          ) : (
            "Add card"
          )}
        </button>
      </div>
    </>
  );
};

interface Errors {
  [key: string]: number;
}

export default StripeForm;
