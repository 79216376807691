import Swal from "sweetalert2";
import * as React from "react";
import Step4 from "./Step4";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step5 from "./Step5";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as signup from "../../Service/signup";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { QUICK_SCREENS } from "../../Routes/RouteConstent";
import { getMembershipPlan, onBoardingFamily } from "../../Service/membership";
import Popup from "./Popup";
import "./Membership.scss";
import moment from "moment";
import {
  getUserBillingAPI,
  getUserProfileEdit,
} from "../../Service/update_profile";
import { findLastIndex } from "lodash";
import {
  getUser,
  getUserID,
  getDataFromLocalStorage,
} from "../../Service/getLocalStorage";
import CongratsPopUp from "../../Common/CongratsPopUp";
import useModalWindowControls from "../../Hooks/useModal";
import * as asyncStates from "../../Utils/asyncState";
import { toast } from "react-toastify";
import { postBuyMembership } from "../../Common/StripeForms/paymentsAPI";
import { purchaseEvent } from "../../Utils/gtm";
import { usePostHog } from "posthog-js/react";

export default function Membership() {
  const membershipId = useParams<{ id: string }>().id;
  const posthog = usePostHog();

  let Navigate = useNavigate();
  let userData: any = localStorage.getItem("Nurture_user_data");
  let user_id = JSON.parse(userData)?.id;
  const [step1Value, setStep1Value] = React.useState<any>({});
  const [membershipPlan, setMembershipPlan] = React.useState<{ id: number }[]>(
    []
  );
  const [stepValue1, setStepValue1] = React.useState<any>({
    type_mom: [],
  });
  const [stepValue2, setStepValue2] = React.useState<any>({});
  const [step5Value, setStep5Value] = React.useState<any>("no");
  const [step5ValueYes, setStep5ValueYes] = React.useState<any>({
    first_child_gender: "",
    second_child_gender: "",
    another_child_gender_1: "",
    another_child_gender_2: "",
  });
  const [step5ValueNo, setStep5ValueNo] = React.useState<any>({});
  const [step1Err, setStep1Err] = React.useState<any>({});
  const [step2Err, setStep2Err] = React.useState<any>({});
  const [stepErr2, setStepErr2] = React.useState<any>({});
  const [step5Err, setStep5Err] = React.useState<any>({});
  const [step5NoErr, setStep5NoErr] = React.useState<any>({});
  const [valueNoStep2, setValueNoStep2] = React.useState<any>({
    baby_first_name: "",
    dob: "",
    gender: "",
    baby_first_name1: "",
    dob_anothor_child1: "",
    first_gender: "",
    baby_first_name2: "",
    dob_anothor_child2: "",
    second_gender: "",
  });
  const [selectId, setSelectedId] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);

  const [realValue, setRealValue] = React.useState<any>({});
  const [feedingChoice, setFeedingChoice] = React.useState<any>([]);
  const [image, setImage] = React.useState<any>("");
  const [addOption, setAddOption] = React.useState<any>([]);
  const [addChild, setAddChild] = React.useState<any>("");

  let { state }: any = useLocation();

  const allStep = () => {
    return [
      "Basics",
      "Tell us about yourself",
      "Tell us about your family",
      "Tell us about your baby",
    ]; //'Select your membership',
  };

  const [isSubscriptionCancelled, setIsSubscriptionCancelled] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    const getUserBillingData = async () => {
      try {
        let res: any = await getUserBillingAPI({ user_id: getUserID() });

        if (res?.data?.user_membership) {
          setIsSubscriptionCancelled(
            !res?.data?.user_membership?.subscription?.cancel_at
          );
        }
      } catch (err) {}
    };
    getUserBillingData();
  }, []);

  React.useEffect(() => {
    const user = getUser();

    if (
      (!user || user?.is_stripe_membership) &&
      !state?.isCancelled &&
      isSubscriptionCancelled
    ) {
      Navigate("/");
    }
  }, [Navigate, isSubscriptionCancelled, state?.isCancelled]);

  let steps = allStep();

  const selectedSubscription = React.useMemo(() => {
    return membershipPlan?.find((m) => m?.id === +membershipId!);
  }, [membershipPlan, membershipId]);

  const annualSubscription = React.useMemo(() => {
    return membershipPlan?.find((m) => m?.id === 2);
  }, [membershipPlan]);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Step1
            handleNext={stepHandler1}
            value={stepValue1}
            setValue={setStepValue1}
            error={step2Err}
            setError={setStep2Err}
            loading={loading}
            selectId={selectId}
            setSelectedId={setSelectedId}
            image={image}
            setImage={setImage}
          />
        );
      case 1:
        return (
          <Step2
            value={stepValue2}
            addChild={addChild}
            setAddChild={setAddChild}
            setValue={setStepValue2}
            error={stepErr2}
            setError={setStepErr2}
            handleNext={setpHandler2}
            loading={loading}
            handleSkip={handleSkip}
            setvalueYes={setStep5ValueYes}
            valueYes={step5ValueYes}
            setNoValue={setStep5ValueNo}
            setValueNo={setValueNoStep2}
            valueNo={valueNoStep2}
            noError={step5NoErr}
            setNoError={setStep5NoErr}
            realValue={realValue}
            setRealValue={setRealValue}
            feedingChoice={feedingChoice}
            setFeedingChoice={setFeedingChoice}
            prevValue={stepValue1}
            setAddOption={setAddOption}
            addOption={addOption}
          />
        );
      // case 2:
      //   return (
      //     <Step3
      //       handleNext={step3Handler}
      //       setSubsDetail={setSubsDetail}
      //       membershipPlan={membershipPlan}
      //       isMembership={isMembership}
      //       mId={param.get("id")}
      //       wasTrialInUse={wasTrialInUse}
      //     />
      //   )
      // case 3:
      case 2:
        return (
          <Step4
            value={step1Value}
            data={selectedSubscription || state.membership}
            setValue={setStep1Value}
            error={step1Err}
            setError={setStep1Err}
            handleNext={stepHandle4}
            loading={loading}
            earlySubscribeAsyncState={earlySubscribeAsyncState}
            annualSubscription={annualSubscription}
          />
        );
      // case 4:
      case 3:
        return (
          <Step5
            value={step5Value}
            setValue={setStep5Value}
            handleNext={setp5Handler}
            setYesValue={setStep5ValueYes}
            yesValue={step5ValueYes}
            setNoValue={setStep5ValueNo}
            noValue={step5ValueNo}
            setError={setStep5Err}
            error={step5Err}
            noError={step5NoErr}
            setNoError={setStep5NoErr}
            loading={loading}
          />
        );
      default:
        return "Unknown step";
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const getMembershipid = async () => {
    let res: any = await getMembershipPlan();

    if (res.data.success) {
      setMembershipPlan(res.data.data);
    }
  };

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };
  React.useEffect(() => {
    getMembershipid();
  }, []);

  const allField: any = document.getElementsByClassName("error-input");
  // All steps submit handler
  // step 1 api intigration

  // step 1 submit handler
  const stepHandler1 = async () => {
    try {
      if (Object.keys(stepValue1).length === 0) {
        setStep2Err({
          current_life_status: "Choose your status",
          zip_code: "Zip code is required",
          dob: "Date of birth is required",
          gender: "Please select gender",
          type_mom: "Choose what type of parent you are",
          // phone: "Phone number is required"
        });
        window.scroll(0, 300);
        return;
      }
      let date = moment(stepValue1?.dob);

      if (
        !stepValue1?.dob ||
        (stepValue1?.dob && !date.isValid()) ||
        !stepValue1?.zip_code ||
        !stepValue1?.gender ||
        !stepValue1?.type_mom ||
        stepValue1?.zip_code?.length !== 5 ||
        !stepValue1?.current_life_status
      ) {
        let errors: any = {};

        if (!stepValue1?.dob || (stepValue1?.dob && !date.isValid())) {
          errors.dob = "Date of birth is required";
        }
        if (!stepValue1?.zip_code) {
          errors.zip_code = "Zip code is required";
        }
        if (stepValue1?.zip_code?.length !== 5) {
          errors.zip_code = "Zip code should be 5 digit";
        }
        if (!stepValue1.gender) {
          errors.gender = "Please select gender";
        }
        if (!stepValue1?.type_mom) {
          errors.type_mom = "Choose what type of parent you are";
        }
        if (!stepValue1?.current_life_status) {
          errors.current_life_status = "Choose your status";
        }

        await setStep2Err(errors);
        allField[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
      setLoading(true);
      let formData = new FormData();
      stepValue1.photo && formData.append("photo", stepValue1.photo);
      formData.append(
        "dob",
        stepValue1.dob?.$d ? stepValue1.dob?.$d : stepValue1.dob
      );
      formData.append("gender", stepValue1.gender);
      formData.append("type_mom", stepValue1.type_mom);
      formData.append("current_life_status", stepValue1.current_life_status);
      formData.append("user_id", user_id);
      formData.append("zip_code", stepValue1?.zip_code);
      formData.append("phone", stepValue1.phone);

      let res: any = await signup.aboutUs(formData);

      if (res.data.success === false) {
        let text = "";
        let errors: any = res.data.message;
        for (let i = 0; i < errors.length; i++) {
          text += `${i + 1}.  ${errors[i]} \n`;
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          text: text,
        });
      }
      if (res.data.success) {
        posthog.capture("set_person_property/membership_step_1", {
          $set: {
            user_birthday: res?.data?.data?.dob,
            user_zip_code: res?.data?.data?.zip_code,
            what_best_describes_you: res?.data?.data?.type_mom,
            what_is_your_current_life_status:
              res?.data?.data?.current_life_status,
          },
        });

        if (res?.data?.data?.membership?.membership_id) {
          let newData: any = membershipPlan.map((data: any) => {
            if (res?.data?.data?.membership?.membership_id === data.id) {
              data.confirmId = res?.data?.data?.membership?.id;
              data.isSubscription = true;
            } else {
              data.classes = "disable_member";
            }
            return data;
          });

          setMembershipPlan(newData);
        } else {
          let newData: any = membershipPlan.map((data: any) => {
            data.confirmId = "";
            data.isSubscription = false;

            data.classes = "";

            return data;
          });

          setMembershipPlan(newData);
        }

        handleNext();
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong",
      });
    }
  };

  const setpHandler2 = async (value: any) => {
    try {
      setLoading(true);

      value.user_id = user_id;
      // setStepValue2(stepValue2)
      value.due_date = moment(value.due_date).format("YYYY-MM-DD");

      if (value?.dob_anothor_child1)
        value.dob_anothor_child1 = moment(value?.dob_anothor_child1?.$d).format(
          "YYYY-MM-DD"
        );
      if (value?.dob_anothor_child2)
        value.dob_anothor_child2 = moment(value?.dob_anothor_child2?.$d).format(
          "YYYY-MM-DD"
        );

      let res: any = await onBoardingFamily(value);

      if (res.data.success === false) {
        let text = "";
        let errors: any = res.data.message;

        for (let i = 0; i < errors.length; i++) {
          text += `${i + 1}.  ${errors[i]} \n`;
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          text: text,
        });
      }
      if (res.data.success) {
        posthog.capture("set_person_property/membership_step_1", {
          $set: {
            partner_first_name: res?.data?.data?.family?.first_name,
            partner_last_name: res?.data?.data?.family?.last_name,
            partner_email: res?.data?.data?.family?.email,
            are_you_currently_expecting: res?.data?.data?.baby?.pregnant,
            expected_due_date: res?.data?.data?.baby?.due_date,
            is_this_your_first_baby:
              res?.data?.data?.baby?.is_this_your_first_baby,
          },
        });
        
        if (res?.data?.data?.is_stripe_membership) {
          localStorage.setItem(
            "Nurture_user_data",
            JSON.stringify(res?.data?.data)
          );
        }
        handleNext();
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong",
      });
    }
  };

  const earlySubscribedSuccessModal = useModalWindowControls();
  const closeEarlySubscribedSuccessModal = () => {
    earlySubscribedSuccessModal.closeModal();
    localStorage.setItem(
      "Nurture_user_data",
      JSON.stringify({ ...getDataFromLocalStorage(), welcome_screen_status: 0 })
    );
    Navigate("/");
  };

  const [earlySubscribeAsyncState, setEarlySubscribeAsyncState] =
    React.useState<asyncStates.AsyncState>(asyncStates.initState);

  const buyMembershipRequest = async (data: any) => {
    let params = {
      user_id: getUserID(),
      email: getUser()?.email,
      amount: data.amount,
      total_amount: data.total_amount,
      tax: data.tax,
      membership_id: data.membership_id,
      coupon_amount: data.coupon_amount,
      coupon_id: data.coupon_id,
      tax_amount: data.tax_amount,
      forever_free: 1,
    };

    try {
      setEarlySubscribeAsyncState(asyncStates.pendingState);

      const res: any = await postBuyMembership(params);

      if (res.status !== 200) {
        throw { message: res?.data?.message?.join(" ") };
      }

      setEarlySubscribeAsyncState(asyncStates.successState);
      earlySubscribedSuccessModal.openModal();

      localStorage.setItem("Nurture_user_data", JSON.stringify(res.data.data));

      purchaseEvent({
        ecommerce: {
          value: data?.amount,
          transaction_id: `${res?.data?.transaction_id}`,
          coupon: data?.coupon_id || "",
          finalAmount: data?.total_amount,
        },
        items: [
          {
            item_name:
              data?.membership_id === 1
                ? "MONTHLY MEMBERSHIP"
                : "ANNUAL MEMBERSHIP",
            item_id: data?.membership_id,
            price: data?.amount,
            quantity: "1",
            forever_free: 1,
          },
        ],
      });

      posthog.capture("user-purchases-a-membership", {
        frequency:
          data?.membership_id === 1
            ? "MONTHLY MEMBERSHIP"
            : "ANNUAL MEMBERSHIP",
        membershipId: data?.membership_id,
        price: data?.amount,
        userId: getUserID(),
        coupon: data?.coupon_id || "",
        finalAmount: data?.total_amount,

        $set: {
          free_membership: false,
          monthly_membership: data?.membership_id === 1,
          annual_membership: data?.membership_id === 2,
        }
      });

      if (["BUMPFREE", "BUMP150"].includes(data?.coupon_code)) {
        posthog.capture(
          `used-${data?.coupon_code}-membership-coupon`,
          {
            $set: {
              BUMP_coupon: data?.coupon_code,
            },
          }
        );
      }

      window.dispatchEvent(new Event("storage"));
    } catch (err: any) {
      toast.error(err?.message);
      setEarlySubscribeAsyncState({
        ...asyncStates.failedState,
        failedMessage: err?.message,
      });
    }
  };

  let stepHandle4 = (data: any) => {
    if (data.couponRepeatable) {
      buyMembershipRequest(data);
    } else {
      setStep5Value(data);
      handleNext();
    }
  };

  const setp5Handler = async () => {
    try {
      if (step5Value === "yes") {
        if (Object.keys(step5ValueYes).length === 0) {
          Swal.fire({
            icon: "error",
            title: "input",
            text: "Please fill all input!",
          });
          return;
        }
        if (!step5ValueYes.due_date || !step5ValueYes.gender) {
          let errors: any = {};
          if (!step5ValueYes.due_date) {
            errors.due_date = "Please select due date";
          }
          if (!step5ValueYes.gender) {
            errors.gender = "Please select gender";
          }
          await setStep5Err(errors);
          allField[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
          return;
        }
        setLoading(true);
        step5ValueYes.user_id = "";
        step5ValueYes.pregnant = "yes";
        setStep5ValueYes(step5ValueYes);

        let res: any = await signup.aboutBaby(step5ValueYes);

        if (res.data.success === false) {
          let text = "";
          let errors: any = res.data.message;

          for (let i = 0; i < errors.length; i++) {
            text += `${i + 1}.  ${errors[i]} \n`;
          }
          Swal.fire({
            icon: "error",
            title: "Error",
            text: text,
          });
        }
        if (res.data.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "successfully registered",
          });
        }
        setLoading(false);
      }
      if (step5Value === "no") {
        if (Object.keys(step5ValueNo).length === 0) {
          Swal.fire({
            icon: "error",
            title: "input",
            text: "Please fill all input!",
          });
          return;
        }

        if (
          !step5ValueNo.first_name ||
          !step5ValueNo.c_section ||
          !step5ValueNo.due_date ||
          !step5ValueNo.feeding_choice ||
          !step5ValueNo.fertility_question ||
          !step5ValueNo.premature
        ) {
          let errors: any = {};
          if (!step5ValueNo.first_name) {
            errors.first_name = "First name is required";
          }
          if (!step5ValueNo.c_section) {
            errors.c_section = "Please tick the value";
          }
          if (!step5ValueNo.due_date) {
            errors.due_date = "Please select date";
          }
          if (!step5ValueNo.feeding_choice) {
            errors.feeding_choice = "Please tick the value";
          }
          if (!step5ValueNo.fertility_question) {
            errors.fertility_question = "Please tick the value";
          }
          if (!step5ValueNo.premature) {
            errors.premature = "Please tick the value";
          }
          setStep5NoErr(errors);
        } else {
          setLoading(true);
          step5ValueNo.user_id = "";
          step5ValueNo.pregnant = "no";
          setStep5ValueNo(step5ValueNo);

          let res: any = await signup.aboutBaby(step5ValueNo);
          if (res.data.success === false) {
            let text = "";
            let errors: any = res.data.message;

            for (let i = 0; i < errors.length; i++) {
              text += `${i + 1}.  ${errors[i]} \n`;
            }
            Swal.fire({
              icon: "error",
              title: "Error",
              text: text,
            });
          }
          if (res?.data?.success === true) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "successfully registered",
            });
          }
        }
        setLoading(false);
      }
      Navigate(QUICK_SCREENS);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong",
      });
    }
  };

  const isStepOptional = (step: number) => {
    return step === 3;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      //@ts-ignore
      behavior: "instant",
    });
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  React.useEffect(() => {
    if (state?.tab && state?.membership) {
      setActiveStep(state?.tab);
    }
  }, [state]);

  const setAutoPopulateData = async () => {
    try {
      let userId: any = localStorage.getItem("Nurture_user_data");
      userId = JSON.parse(userId);
      if (userId?.id) {
        let res: any = await getUserProfileEdit({ user_id: userId?.id });
        if (res.data.success) {
          let memberData = {
            about_you: res?.data?.about_you,
            about_your_baby: res?.data?.about_your_baby,
            about_your_partner: res?.data?.about_your_partner,
          };
          setStepValue1(memberData?.about_you);
          setStepValue2({
            ...memberData?.about_your_partner,
            pregnant: memberData?.about_your_baby?.pregnant,
          });
          if (memberData?.about_your_baby) {
            let { pregnant, ...rest } = memberData?.about_your_baby;
            if (memberData?.about_your_baby?.pregnant === "yes") {
              const { another_child_gender_1, another_child_gender_2 } = rest;

              const childIndex = findLastIndex([
                another_child_gender_1,
                another_child_gender_2,
              ]);
              setAddChild(
                childIndex > -1
                  ? [...new Array(childIndex + 1)].map((_, i) => i + 1)
                  : []
              );

              setStep5ValueYes(rest);
            }
            if (memberData?.about_your_baby?.pregnant === "no") {
              const {
                baby_first_name1,
                dob_anothor_child1,
                first_gender,
                baby_first_name2,
                dob_anothor_child2,
                second_gender,
              } = rest;

              setAddOption(
                [
                  baby_first_name1 || dob_anothor_child1 || first_gender,
                  baby_first_name2 || dob_anothor_child2 || second_gender,
                ]
                  .filter((v) => v)
                  .map((_, i) => i + 1)
              );

              setValueNoStep2(rest);
              setFeedingChoice(rest?.feeding_choice);
            }
          }
        }
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    setAutoPopulateData();
  }, []);

  return (
    <div>
      <CongratsPopUp
        isVisible={earlySubscribedSuccessModal.isVisible}
        closeModal={closeEarlySubscribedSuccessModal}
        doneBtnTitle="Show Me Around"
      >
        <h3>Congratulations</h3>
        <p>You are now part of the club!</p>
      </CongratsPopUp>
      <section className={`steps-section position-relative`}>
        <Popup callBack={() => setAutoPopulateData()} />
        <Box sx={{ width: "100%" }} className={`position-relative`}>
          {activeStep > 0 && (
            <button className="membership-back " onClick={handleBack}>
              BACK
            </button>
          )}
          <Stepper activeStep={activeStep}>
            {steps?.map((label: any, index: any) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption"></Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}></StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className="container">
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {getStepContent(activeStep)}
                </Typography>
              </React.Fragment>
            )}
          </div>
        </Box>
      </section>
    </div>
  );
}
