import React, { useState } from "react";
import "./Membership.scss";
import Profile from "../../Assets/img/signup/profile.png";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { useRef } from "react";
import ConvertFileTobase64 from "../../Utils/ConverBase64";
import { CircularProgress } from "@mui/material";
import Camera from "../../Assets/img/icons/camera.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ImagePopup from "../../Utils/imageCrop/imagePopup";
import { subYears } from "date-fns";

const isOptionEqualToValue = (option: any, value: any): boolean =>
  option.label === value.label;

const Step1 = ({
  handleNext,
  value,
  setValue,
  loading,
  error,
  setError,
  image,
  setImage,
  selectId,
  setSelectedId,
}: any): JSX.Element => {

  const [isCrop, setIsCrop] = useState(false);
  const [imageFile, setImageFile] = useState<any>("");

  let userImageref: any = useRef();

  // Date change handler
  const handleDateChange = (newValue: Date | null | any) => {
    // var date: any = moment(newValue?.$d).format("MMMM Do YYYY");

    // var now = moment();
    if (newValue) {
      setValue({
        ...value,
        ["dob"]: newValue,
      });
      setError({
        ...error,
        ["dob"]: "",
      });
      return;
    } else {
      setValue({
        ...value,
        ["dob"]: "",
      });
      setError({
        ...error,
        ["dob"]: "",
      });
      return;
      // toast.error("Invalid Date")
    }

    // setValue({
    //     ...value,
    //     ["dob"]: newValue
    // })
  };

  const updateAutocompleteByKey =
    (key: string) => (e: any, dValues: any, reason?: any) => {
      if (reason === "clear") {
        setValue({
          ...value,
          [key]: "",
        });
      }

      if (reason === "selectOption") {
        setError({
          ...error,
          [key]: "",
        });
        setValue({
          ...value,
          [key]: dValues.label,
        });
      }
    };

  let HandleDataChange = async (e: any, dValues: any, reason?: any) => {
    let name = e.target.name;
    let inputValue = e.target.value;

    if (name === "zip_code") {
      if (inputValue.length === 5) {
        setError({
          ...error,
          [name]: "",
        });
      } else {
        setError({
          ...error,
          [name]: "Invalid zip code",
        });
      }
    }

    if (dValues === "data") {
      if (name !== "zip_code") {
        setError({
          ...error,
          [name]: "",
        });
      }
      setValue({
        ...value,
        [name]: inputValue,
      });
    } else if (dValues === "image") {
      let base64: any = await ConvertFileTobase64(e.target.files[0]);
      setImage(base64);
      setImageFile(e?.target?.files[0]);
      setIsCrop(true);
      setError({
        ...error,
        [name]: "",
      });
    } else {
    }
  };

  let radioButtonHandler = (e: any, id: any) => {
    // if (selectId.includes(id)) {
    //     let newValue = selectId.filter((data: any) => data !== id)
    //     setSelectedId(newValue)
    // } else if (id === 1) {
    //     setSelectedId([id])
    // } else if (id === 2) {
    //     setSelectedId([id])
    // } else if (id === 4) {
    //     setSelectedId([id])
    // } else {
    //     setSelectedId([])
    // }

    setError({
      ...error,
      [e.target.name]: "",
    });

    setValue((s: any) => ({
      ...s,
      type_mom: s.type_mom?.includes(e.target.value) ? [] : [e.target.value],
    }));
  };

  let onStatusChange = (e: any) => {
    setError({
      ...error,
      current_life_status: "",
    });

    setValue({
      ...value,
      current_life_status: e.target.value,
    });
  };

  // console.log("all value", value?.dob)
  return (
    <div>
      <ImagePopup
        imagebase64={image}
        image={imageFile}
        value={value}
        setValue={setValue}
        open={isCrop}
        setImageBase64={setImage}
        setImage={setImageFile}
        setOpen={setIsCrop}
      />
      <div className="step-innercontent step2">
        <div className="yourself-main">
          <h3>
            Tell us <span className="strong">about yourself</span>
          </h3>
          <p className="mb-5 text-center mx-5 px-2 yourself-desc">
            In these next few sections, we are going to ask you information
            about you and your family to better customize your experience. We do{" "}
            <span className="strong">Not</span> share any of this information
            with any third party providers, or anyone outside of our
            organization.{" "}
          </p>
          <div className="profile-updaterdiv text-center">
            <div
              className=" mt-5 mb-3 position-relative"
              onClick={() => userImageref.current.click()}
            >
              <div
                className="img-upload"
                style={{
                  backgroundImage: `url(${
                    image ? image : value?.photo ? value?.photo : Profile
                  })`,
                }}
              ></div>
              {/* <img className="img-upload" src={image ? image : Profile} alt="" /> */}
              <img src={Camera} className="img-upload-icon" alt="" />
            </div>
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              name="photo"
              onChange={(e) => HandleDataChange(e, "image")}
              className="d-none"
              ref={userImageref}
            />
            <h4 className="error-msgtext text-center">{error?.photo}</h4>
          </div>

          <form className="step-form row">
            <div className="form-group-m col-lg-12 mb-3">
              <div className="row">
                <div
                  className={`form-group birthday-box ${
                    error?.dob && "input-errorborder"
                  } col-lg-6 col-md-6`}
                >
                  <label>What’s your birthdate?</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <MobileDatePicker
                        value={value?.dob || ""}
                        toolbarTitle="Choose date"
                        disableFuture
                        onChange={handleDateChange}
                        renderInput={(params: any) => (
                          <TextField
                            error={false}
                            placeholder="MM-DD-YYYY"
                            {...params}
                          />
                        )}
                        maxDate={subYears(new Date(), 13)}
                      />
                    </Stack>
                  </LocalizationProvider>
                  <h4 className="error-msgtext">{error?.dob}</h4>
                </div>

                <div className="form-group col-lg-6 col-md-6">
                  <label>What’s your zip code?</label>
                  <input
                    type="number"
                    onChange={(e) => HandleDataChange(e, "data")}
                    name="zip_code"
                    className={`${
                      error?.zip_code && "error-input"
                    } form-control`}
                    value={value?.zip_code}
                    id=""
                  />
                  <h4 className="error-msgtext">{error?.zip_code}</h4>
                </div>
              </div>
            </div>
            <div className="form-group-m col-lg-12 col-md-12 mb-3">
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 gender-box ">
                  <label>What are your preferred pronouns?</label>
                  <Autocomplete
                    disablePortal
                    id="Gender"
                    value={value?.gender ? { label: value?.gender } : null}
                    isOptionEqualToValue={isOptionEqualToValue}
                    className={`${error?.gender && "input-errorborder"}`}
                    options={Gender}
                    sx={{ width: 300 }}
                    onChange={updateAutocompleteByKey("gender")}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <h4 className="error-msgtext">{error?.gender}</h4>
                </div>
                {/* <div className="form-group col-lg-6 col-md-6">
                                    <label>What is your contact number?</label>
                                    <input type="number" name="phone"
                                        className={`${error?.phone && 'error-input'} form-control`}
                                        id="user_contact_no" placeholder="Contact no."
                                        onChange={(e) => HandleDataChange(e, "data")}
                                        value={value?.phone}
                                    />
                                    <h4 className="error-msgtext">{error?.phone}</h4>
                                </div> */}
              </div>
            </div>
          </form>
          <div className="checkbox-controler radio-area mb-4">
            <h4>What best describes you?</h4>
            <div className="d-flex w-100 justify-content-start flex-wrap flex-sm-row flex-column">
              <div className="custom-checkbox">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="type_mom"
                    checked={value?.type_mom?.some(
                      (data: any) => data === "full time working parent"
                    )}
                    // checked={Array.isArray(value?.type_mom) && value?.type_mom?.some((data: any) => data === "full time working parent")}
                    value="full time working parent"
                    onChange={(e) => radioButtonHandler(e, 1)}
                    id="customCheck1"
                  />
                  <label className="form-check-label">
                    Full-time working parent
                  </label>
                </div>
              </div>

              <div className="custom-checkbox">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="type_mom"
                    checked={value?.type_mom?.some(
                      (data: any) => data === "part time working parent"
                    )}
                    value="part time working parent"
                    onChange={(e) => radioButtonHandler(e, 2)}
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label">
                    Part-time working parent
                  </label>
                </div>
              </div>

              <div className="custom-checkbox">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="type_mom"
                    checked={value?.type_mom?.some(
                      (data: any) => data === "Stay at home parent"
                    )}
                    value="Stay at home parent"
                    onChange={(e) => radioButtonHandler(e, 3)}
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label">
                    Stay at home parent
                  </label>
                </div>
              </div>

              <div className="custom-checkbox">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="type_mom"
                    checked={value?.type_mom?.some(
                      (data: any) => data === "Self employed"
                    )}
                    value="Self employed"
                    onChange={(e) => radioButtonHandler(e, 4)}
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label">Self employed</label>
                </div>
              </div>

              <div className="custom-checkbox">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="type_mom"
                    checked={value?.type_mom?.some(
                      (data: any) => data === "unemployed"
                    )}
                    value="unemployed"
                    onChange={(e) => radioButtonHandler(e, 5)}
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label">Unemployed</label>
                </div>
              </div>

              <div className="custom-checkbox">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="type_mom"
                    checked={value?.type_mom?.some(
                      (data: any) => data === "other"
                    )}
                    value="other"
                    onChange={(e) => radioButtonHandler(e, 6)}
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label">Other</label>
                </div>
              </div>

              <div className="custom-checkbox">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="type_mom"
                    checked={value?.type_mom?.some(
                      (data: any) => data === "I prefer not to say"
                    )}
                    value="I prefer not to say"
                    onChange={(e) =>
                      radioButtonHandler(e, "I_prefer_not_to_say")
                    }
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label">
                    I prefer not to say
                  </label>
                </div>
              </div>
              <h3 className="error-msgtext">{error?.type_mom}</h3>
            </div>

            <div className="radio-area my-3">
              <h4>What’s your current life status?</h4>
              <div className="custom-control custom-radio">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="current_life_status"
                    checked={value?.current_life_status === "I’m Single"}
                    value="I’m Single"
                    id="iAmSingle"
                    onChange={onStatusChange}
                  />
                  <label className="form-check-label" htmlFor="iAmSingle">
                    I’m Single
                  </label>
                </div>
              </div>
              <div className="custom-control custom-radio">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="current_life_status"
                    checked={
                      value?.current_life_status ===
                      "I have a Partner or Support Person"
                    }
                    value="I have a Partner or Support Person"
                    id="iHaveAPartner"
                    onChange={onStatusChange}
                  />
                  <label className="form-check-label" htmlFor="iHaveAPartner">
                    I have a Partner or Support Person
                  </label>
                </div>
              </div>
              {/* <div className="custom-control custom-radio">
                                <input type="radio" id="customRadio1" name="customRadio" 
                                checked={value?.current_life_status === "I’m Single"}
                                className="custom-control-input" onChange={onStatusChange} value="I’m Single" />
                                <label className="custom-control-label" htmlFor="customRadio1">I’m Single</label>
                            </div>
                            <div className="custom-control custom-radio">
                                <input type="radio" id="customRadio2" 
                                checked={value?.current_life_status === "I have a Partner or Support Person"}
                                value="I have a Partner or Support Person" name="customRadio" onChange={onStatusChange} className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadio2">I have a Partner or Support Person</label>
                            </div> */}
              <h3 className="error-msgtext">{error?.current_life_status}</h3>
            </div>
          </div>
        </div>
        <button
          className="btn primary-blue-btn mb-5"
          disabled={loading}
          onClick={handleNext}
        >
          {loading ? <CircularProgress /> : "Next Step"}
        </button>
      </div>
    </div>
  );
};

export default Step1;

const Gender = [
  { label: "She/her/hers" },
  { label: "He/him/his" },
  { label: "They/them/theirs" },
  { label: "Prefer not to say" },
];
