import React from 'react'
import { Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { PODCASTQUESTION } from '../../Routes/RouteConstent'
import unswaddled_main from "../../Assets/img/Prelogin/unswaddled-main.jpg";
import "./PodcastUI.scss";

const PodcastUI = () => {
    const Navigate = useNavigate()
    return (
        <div className='pale-bg row'>
            <section className="unswaddled position-relative py-3 py-md-5 mb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="unswaddled-img text-end">
                                <img src={unswaddled_main} className="w-75" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 text-center text-md-start">
                            <div className="unswaddled-content px-0 px-lg-5 py-3 py-md-0">
                                <h2 className="unswaddled-heading text-center text-md-start m-0 py-3"><span className="fw-bold  d-block strong">Unswaddled:</span> The podcast</h2>
                                <p className="cursive pink p-0 mt-3 ">uncensored & unscripted</p>
                                <p> Get even more up-close-and-personal with weekly 
                                    hot takes from real moms, real nurses, and real NAPS 
                                    co-founders, Emily and Jamie. Plus guests, answers to 
                                    questions submitted by listeners, and the more-than-
                                    occasional f-bomb.</p>
                                <button className="w-auto btn-small pink-btn-small" onClick={() => Navigate(PODCASTQUESTION)}>Ask a Question</button>
                                <button className="w-auto btn-small pink-btn-small-outline ms-1" onClick={() => window.open("https://podcasts.apple.com/us/podcast/unswaddled/id1560213381")}>Subscribe to Podcast</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PodcastUI