import React, { useEffect, useState } from 'react'
import "./PreLogin.scss";
import { Alert, Carousel, Row } from 'react-bootstrap';
import service_opt1 from "../../Assets/img/Prelogin/service-1.png";
import service_opt2 from "../../Assets/img/Prelogin/service-2.png";
import service_opt3 from "../../Assets/img/Prelogin/service-3.png";
import service_opt4 from "../../Assets/img/Prelogin/service-4.png";
import service_opt5 from "../../Assets/img/Prelogin/service-5.png";
import service_opt6 from "../../Assets/img/Prelogin/service-6.png";
// import offer_prebaby from "../../Assets/img/Prelogin/Pre-baby-ft.jpg";
import Prebaby_boot_ft from "../../Assets/img/Prelogin/Pre-baby-ft.jpg";
import support_grp from "../../Assets/img/Prelogin/Support group.jpg";
import lactation_consult_ft from "../../Assets/img/Prelogin/lactation-consult-ft.jpg";
import day_time_overnight_ft from "../../Assets/img/Prelogin/day-time-overnight-ft.jpg";
import sleep_consult_ft from "../../Assets/img/Prelogin/sleep-consult-ft.jpg";
import live_event_ft from "../../Assets/img/Prelogin/live-event-ft.jpg";
import seen_banner from "../../Assets/img/Prelogin/seen.png";
import spoiler_alert from "../../Assets/img/Prelogin/spoiler-alert-icon.svg";
import { useNavigate } from 'react-router-dom';
import { OURSTORY, CONTACT, CRPROGRAMS, LACTATIONCONSULT, LIVEWEBINAR, SLEEPCONSULT, STAGE1, STAGE2, STAGE3, STAGE4, STAGE5, STAGE6, SUPPORTGROUP, VERIFYEMAIL } from '../../Routes/RouteConstent';
import { getPreLoginApi } from '../../Service/signup';
import * as service from "../../Utils/ServiceUrl";
import { getDataFromLocalStorage } from '../../Service/getLocalStorage';
import BlogsBlock from '../../Common/BlogCard/BlogsBlock';
import BecomeAMemberNow from '../../Common/BecomeAMemberNow';
import PodcastUI from '../../Common/PodcastUi/PodcastUI';
import Sponsors from '../../Common/sponsors';

const PreLogin = () => {

    const Navigate = useNavigate();
    const [news, setNews] = useState([])

    const userData = getDataFromLocalStorage()
    const getData = async () => {
        try {
            let res: any = await getPreLoginApi()
            if (res?.data?.success) {
                setNews(res?.data?.blogs)
            }
        } catch (error: any) {
            console.log("errors", error)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='prelogin'>
            <section className="banner mob-banner d-flex align-items-center m-3">
                {/* <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="banner-content-box py-5 py-md-0 pe-0 pe-md-5">
                                <h1 className="fs-1">Newborn & Parenting Support</h1>
                                <p className="cursive pink-txt fs-1 lh-1 my-4">that’s actually supportive</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            {userData && userData.is_email_verify == 0 && <Alert key="danger" variant="danger">
                Please <a style={{ textDecoration: "underline", cursor: "pointer", color: "blue" }} onClick={() => Navigate(VERIFYEMAIL)}>verify</a> your email to go further
            </Alert>}
            <section className="py-4 py-md-3">
                <div className="container">
                    <div className="row align-items-center text-center text-md-start">
                        <div className="col-md-2 col-sm-12 d-none d-sm-none d-md-block d-lg-block d-xl-block ">
                            <div className="spoiler pt-2 pb-2 position-relative d-flex align-items-center">
                                <div className='me-2'>
                                    <img src={spoiler_alert} className="img-fluid w-75"></img>
                                </div>

                                <div className="alert-box w-85">
                                    <p className="fw-bold text-uppercase green lh-1 m-0 p-0">Spoiler Alert</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12 d-sm-block d-md-none  ">
                            <div className="alert-box mb-3 mb-md-0">
                                <p className="fw-bold text-uppercase green lh-1 m-0 p-0">Spoiler Alert</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="perfect-parent">
                                <p className="fs-4 lh-sm">The perfect parent <span className="fw-bold d-md-inline-block d-block doesnt-exist">doesn’t exist.</span></p>
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-12">
                            <div className="parents-content">
                                <p>As Registered Nurses and parents ourselves, we would’ve seen one by now. Instead, we believe in confident parents. Ones who make decisions that are right for themselves and their growing family. And we’re here to support you in finding that confidence in the chaos that is parenting.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='pale-bg position-relative'>
                <section className="services py-3 py-md-5">
                    <div className="container border-bottom">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header text-center">
                                    <h2 className="text-center home-section-title p-0">Support for <span className="strong d-block "> Every Stage </span></h2>
                                    <p className="dark-grey w-50 mx-auto my-4 px-0 px-md-5">We’re here for you from expecting to birth to toddlerhood—nap time, feeding time, any time. Choose your stage to learn about services designed for your needs.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row d-none d-sm-none d-md-block d-lg-block d-xl-block">
                            <div className="col-md-12">
                                <div className="d-md-flex align-items-center justify-content-center py-5">
                                    <div className="service-block text-center px-3" onClick={() => Navigate(STAGE1)}>
                                        <img src={service_opt1} className='img-fluid' />
                                        <p className="py-4">Expecting</p>
                                    </div>

                                    <div className="service-block text-center px-3" onClick={() => Navigate(STAGE2)}>
                                        <img src={service_opt2} className='img-fluid' />
                                        <p className="py-4">0-3 months</p>
                                    </div>

                                    <div className="service-block text-center px-3" onClick={() => Navigate(STAGE3)}>
                                        <img src={service_opt3} className='img-fluid' />
                                        <p className="py-4">3-6 months</p>
                                    </div>

                                    <div className="service-block text-center px-3" onClick={() => Navigate(STAGE4)}>
                                        <img src={service_opt4} className='img-fluid' />
                                        <p className="py-4">6-12 months</p>
                                    </div>

                                    <div className="service-block text-center px-3" onClick={() => Navigate(STAGE5)}>
                                        <img src={service_opt5} className='img-fluid' />
                                        <p className="py-4">12-24 months</p>
                                    </div>

                                    <div className="service-block text-center px-3" onClick={() => Navigate(STAGE6)}>
                                        <img src={service_opt6} className='img-fluid' />
                                        <p className="py-4">2-3 Years</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row d-block d-sm-block d-md-none d-lg-none d-xl-none'>
                            <Carousel className='carousel-services' controls={false}>
                                <Carousel.Item>
                                    <div className="col-md-12">
                                        <div className="d-flex align-items-start justify-content-center py-5">
                                            <div className="service-block text-center px-2 " onClick={() => Navigate(STAGE1)}>
                                                <img src={service_opt1} className='img-fluid' />
                                                <p className="py-3 py-md-4 m-0">Expecting</p>
                                            </div>

                                            <div className="service-block text-center px-2" onClick={() => Navigate(STAGE2)}>
                                                <img src={service_opt2} className='img-fluid' />
                                                <p className="py-3 py-md-4 m-0">0-3 months</p>
                                            </div>

                                            <div className="service-block text-center px-2" onClick={() => Navigate(STAGE3)}>
                                                <img src={service_opt3} className='img-fluid' />
                                                <p className="py-3 py-md-4 m-0">3-6 months</p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="col-md-12">
                                        <div className="d-flex align-items-start justify-content-center py-5">
                                            <div className="service-block text-center px-2" onClick={() => Navigate(STAGE4)}>
                                                <img src={service_opt4} className='img-fluid' />
                                                <p className="py-3 py-md-4 m-0">6-12 months</p>
                                            </div>

                                            <div className="service-block text-center px-2" onClick={() => Navigate(STAGE5)}>
                                                <img src={service_opt5} className='img-fluid' />
                                                <p className="py-3 py-md-4 m-0">12-24 months</p>
                                            </div>

                                            <div className="service-block text-center px-2" onClick={() => Navigate(STAGE6)}>
                                                <img src={service_opt6} className='img-fluid' />
                                                <p className="py-3 py-md-4 m-0">2-3 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>

                            </Carousel>
                        </div>
                    </div>
                </section>

                <section className="offerings py-3 py-md-5">
                    <div className="container border-bottom">
                        <div className='row justify-content-center'>
                            <div className='col-lg-12 col-md-12'>
                                <div className='container'>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="section-header text-center mb-0 mb-md-5">
                                                <h2 className="home-section-title py-0">Our<span className="strong pink"> Offerings</span></h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-3 my-lg-3 py-3 py-lg-5 col-reverse offer-row position-relative justify-content-center">
                                        <div className="col-md-5 col-sm-12 order-1 order-md-0">
                                            <div className="offering-block-content">
                                                <h3 className="lh-1 mb-0 w-100 d-flex align-items-end justify-contnet-start">
                                                    <p className="d-md-inline-block d-block">
                                                        <span className="number pink">1. </span>
                                                        <span className="content">PreBaby Bootcamp</span>
                                                        <span className="cursive pink bestsellerv ms-3 Bestseller keep-all">Bestseller!</span>
                                                    </p>

                                                </h3>
                                                <p className="lh-md pb-1 pb-md-2 pe-0 pe-lg-4 me-0">It’s your one-stop-shop for everything you’ll want to know before the big day (or night). In just one weekend, learn all the essentials plus everything we wish traditional hospital classes taught. All delivered in a non-terrifying way by Registered Nurses with lots of opportunities for questions.</p>
                                                <button className="w-auto btn-small pink-btn-small" onClick={() => { Navigate(service.PREBABYBOOT_URL) }}>Learn More</button>
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 order-0 order-md-1 position-relative">
                                            <div className="offering-block-content mb-3 pe-0 pe-lg-5">
                                                <img src={Prebaby_boot_ft} className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-3 my-lg-3 py-3 py-lg-5 offer-row position-relative justify-content-center">
                                        <div className="col-md-5 position-relative">
                                            <div className="offering-block-content mb-3 mb-md-5 pe-0 pe-lg-4">
                                                <img src={support_grp} className='img-fluid' />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="offering-block-content">
                                                <h3 className="lh-1 mb-0 w-100 d-flex align-items-end justify-contnet-start">
                                                    <p className="d-md-inline-block d-block">
                                                        <span className="number orange">2. </span>
                                                        <span className="content"> Support Groups</span>
                                                    </p>
                                                </h3>
                                                <p className="dark-grey lh-md pb-1 pb-md-2 pe-0 pe-lg-4 me-0">We call our support groups “survival groups” for a reason. Whether you are a new, seasoned, or working parent, our nurse-led support groups create community and belonging when you need it most. These virtual sessions meet 6 times, for 75 minutes, to help you make it through the different challenges of parenthood.  </p>
                                                <button className="w-auto btn-small yellow-btn-small" onClick={() => { Navigate(SUPPORTGROUP) }}>Learn More</button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row my-3 my-lg-3 py-3 py-lg-5 col-reverse offer-row position-relative justify-content-center">
                                        <div className="col-md-5 order-1 order-md-0">
                                            <div className="offering-block-content">
                                                <h3 className="lh-1 mb-0 consultations w-100">
                                                    <p className="d-md-inline-block d-block">
                                                        <span className="number lavender">3. </span>
                                                        <span className="content">Lactation Consultation</span>
                                                    </p>
                                                </h3>
                                                <p className="dark-grey lh-md pb-1 pb-md-2 pe-0 pe-lg-4 me-0">Perhaps you are searching for breastfeeding support or guidance on how to pump, supplement with formula, or address any small or large feeding issues in general. You’re in the right place because at NAPS, lactation support doesn’t just mean just one thing.</p>
                                                <button className="w-auto btn-small purple-btn-small"
                                                    onClick={() => { Navigate(LACTATIONCONSULT) }}
                                                >Learn More</button>
                                            </div>
                                        </div>
                                        <div className="col-md-5 order-0 order-md-1 position-relative">
                                            <div className="offering-block-content mb-3 pe-0 pe-lg-5">
                                                <img src={lactation_consult_ft} className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-3 my-lg-3 py-3 py-lg-5 offer-row position-relative justify-content-center">
                                        <div className="col-md-5">
                                            <div className="offering-block-content pb-3 pb-md-5 pe-0 pe-lg-4 position-relative">
                                                <img src={day_time_overnight_ft} className='img-fluid' />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="offering-block-content pe-0 pe-lg-3">
                                                <h3 className="lh-1 mb-0 w-100">
                                                    <p className="d-md-inline-block d-block">
                                                        <span className="number green">4. </span>
                                                        <span className="content">Daytime And Overnight Nursing Care</span>
                                                        {/* <sup>TM</sup> */}
                                                    </p>
                                                </h3>
                                                <p className="dark-grey lh-md pb-1 pb-md-2 pe-0 me-0">Day or night, our team of Registered Nurses are here to help support and care for you and your baby. Since we only employ Registered Nurses on our care team, you can feel confident leaving your newborn with a qualified expert with real experience.</p>
                                                <button className="w-auto btn-small teal-btn-small" onClick={() => { Navigate(service.CALL30MIN_URL) }}>Learn More</button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row my-3 my-lg-3 py-3 py-lg-5 col-reverse offer-row position-relative justify-content-center">
                                        <div className="col-md-5 order-1 order-md-0">
                                            <div className="offering-block-content">
                                                <h3 className="lh-1 mb-0 w-100 d-flex align-items-end justify-contnet-start">
                                                    <p className="d-md-inline-block d-block">
                                                        <span className="number pink">5.</span>
                                                        <span className="content"> Sleep Consults</span>
                                                    </p>

                                                </h3>
                                                <p className="dark-grey lh-md pb-1 pb-md-2 pe-0 pe-lg-4 me-0 me-lg-3">If you can’t sleep, you can’t think (well you can think, just not clearly!) Let us take the thought out of it for you, and work directly with our pediatric nurse, and expert in pediatric sleep, to formulate a plan to get you and your baby sleeping better!</p>

                                                <button className="w-auto btn-small pink-btn-small" onClick={() => { Navigate(SLEEPCONSULT) }}>Learn More</button>
                                            </div>
                                        </div>
                                        <div className="col-md-5 order-0 order-md-1 position-relative">
                                            <div className="offering-block-content mb-3 mb-md-5 pe-0 pe-lg-4">
                                                <img src={sleep_consult_ft} className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-3 my-lg-3 py-3 py-lg-5 col-reverse offer-row position-relative justify-content-center">
                                        <div className="col-md-5 position-relative">
                                            <div className="offering-block-content mb-3 mb-md-5 pe-0 pe-lg-4">
                                                <img src={live_event_ft} className='img-fluid' />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="offering-block-content">
                                                <h3 className="lh-1 mb-0 w-100 d-flex align-items-end justify-contnet-start">
                                                    <p className="d-md-inline-block d-block">
                                                        <span className="number lavender">6.</span>
                                                        <span className="content"> Live Events</span>
                                                    </p>
                                                </h3>
                                                <p className="dark-grey lh-md pb-1 pb-md-2 pe-0 pe-lg-4 me-0 me-lg-3">Whether it’s an in person event or virtual workshop, we are here to support you and create community around parentings biggest challenges and greatest joys! Check out our calendar to learn more about upcoming events.</p>
                                                <button className="w-auto btn-small purple-btn-small" onClick={() => { Navigate(LIVEWEBINAR) }}>Learn More</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="we-seen py-0 py-md-0">
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 all-seen-row my-5 '>
                                <div className='container'>
                                    <div className='row justify-content-center seen-container-main'>
                                        <div className='col-lg-10'>
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <div className="section-header text-center mb-3 mb-md-5">
                                                        <h2 className="mx-auto home-section-title lh-1 font-80">We’ve<span className="fw-bold position-relative strong"> seen it all!</span></h2>
                                                        <p className="cursive lavender font-80">except &nbsp;true support</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-4 mb-md-0">
                                                <div className="col-md-12 col-lg-12 text-center">
                                                    <img src={seen_banner} className="img-fluid" alt="" />
                                                </div>
                                            </div>

                                            <div className="row py-3 py-md-5">
                                                <div className="col-md-12 col-lg-12 text-center">
                                                    <p className="content lh-sm we-seen-p text-center">With more than a decade as <span className="fw-bold strong">labor and delivery nurses,</span> we’ve worked with thousands of new parents. And we got really f!cking tired of a system that feeds off parental doubt and “expert” advice.</p>
                                                    <p className="content lh-sm we-seen-p text-center">So we founded <span className="fw-bold strong">NAPS™</span> to empower parents to trust their gut. Whether you’re listening to podcast Unswaddled, taking a class, reading a post, or working with one of our nurses directly, we promise unfiltered, judgment-free support<span className="fw-bold strong">—however that looks to you.</span></p>
                                                    <button className="w-auto primary-blue-btn px-5 my-4" onClick={() => Navigate(OURSTORY)}>More about us</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='row'> */}
                                        <BecomeAMemberNow />
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <div className='container-fluid'>
                        <Row>
                            <div className='col-lg-12 col-md-12'>
                                <PodcastUI />
                            </div>
                        </Row>
                    </div>
                </section>
               

                {/* <section className="unswaddled position-relative py-3 py-md-5 mb-0 mb-md-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="unswaddled-img text-end">
                                    <img src={unswaddled_main} className="w-75" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6 text-center text-md-start">
                                <div className="unswaddled-content px-0 px-lg-5 py-3 py-md-0">
                                    <h2 className="unswaddled-heading lh-1 text-center text-md-start m-0 py-3"><span className="fw-bold  d-block strong">Unswaddled:</span> The podcast</h2>
                                    <p className="cursive pink p-0 mt-2 mt-md-0">uncensored & unscripted</p>
                                    <p> Get even more up-close-and-personal with weekly hot takes from real moms, real nurses, and real NAPS co-founders, Emily and Jamie. Plus guests, answers to questions submitted by listeners, and the more-than-occasional f-bomb.</p>
                                    <button className="w-auto btn-small pink-btn-small" onClick={() => Navigate(PODCASTQUESTION)}>Ask a Question</button>
                                    <button className="w-auto btn-small pink-btn-small-outline ms-1">Subscribe to Podcast</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <div className='container d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <hr ></hr>
                        </div>
                    </div>
                </div>
                <BlogsBlock news={news} />
            </div>

            <section className="corporate g-0">
                <div className='corporate-container position-relative container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='corporate-container-main'>
                                <div className="corporate-program rounded p-3 p-md-5 text-center blue-bg position-absolute">
                                    <h2 className="text-white home-section-title p-0"><span className='strong'>Corporate</span> Programs</h2>
                                    <p className="text-white lh-sm py-3 px-0 px-md-5">72% of new parents don’t feel supported in the workplace. Show your employees their job at home is just as important as their job in your organization with benefits like a Nurture By Naps membership or individual services.</p>
                                    <button className="secondary-teal-btn-small py-2 px-4 d-block w-auto mx-auto" onClick={() => Navigate(CRPROGRAMS)}>Learn More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sponsor-container '>
                <div className='container py-5'>
                    <Row className='justify-content-center'>
                        <div className='col-lg-8 col-md-8 col-sm-12'>
                            <div className="section-header text-centersection-header text-center mt-0 mt-md-3 mb-0 mb-md-5 mx-md-0">
                                <h2 className="home-section-title py-0">Sponsorships & <span className="strong ">Collabs </span></h2>
                                <p className="mt-2" style={{maxWidth: '400px', margin: '0 auto'}}>For Unswaddled sponsorship information and business collaboration requests, please reach out.</p>
                            </div>
                        </div>
                    </Row>
                    <Row className='my-4 all-sponsor-container'>
                        <div className='col-lg-12 col-md-12'>
                            <Sponsors />
                        </div>
                        <div className='col-lg-12 col-md-12 text-center'>
                            <button className="w-auto primary-blue-btn px-5 my-4" onClick={() => Navigate(CONTACT)}>Contact</button>
                        </div>
                    </Row>
                </div>
            </section>
        </div>
    )
}

export default PreLogin