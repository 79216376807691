import React from 'react'

const CityStateZip = ({ value, handleChange, error }: any) => {
    return (
        <div className='row'>
            <div className="form-group col-md-4 col-sm-12 mb-3">
                <label>City <span className='mandatory text-danger mx-2'>*</span></label>
                <input type="text" name='city'
                    value={value?.city}
                    className={`payment-input`}
                    onChange={handleChange}
                />

                <p className='error'>{error?.city}</p>



            </div>
            <div className="form-group col-md-4 col-sm-12 mb-3">
                <label>State <span className='mandatory text-danger mx-2'>*</span></label>
                <input type="text" name='state'
                    value={value?.state}
                    className={`payment-input`}
                    onChange={handleChange}
                />

                <p className='error'>{error?.state}</p>



            </div>
            <div className="form-group col-md-4 col-sm-12 mb-3">
                <label>Zip Code <span className='mandatory text-danger mx-2'>*</span></label>
                <input type="number" name='zip_code'
                    value={value?.zip_code}
                    className={`payment-input`}
                    onChange={handleChange}
                />

                <p className='error'>{error?.zip_code}</p>



            </div>

        </div>
    )
}

export default CityStateZip;