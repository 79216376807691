import React, { useEffect, useState } from "react";
import { Container, Row, ProgressBar } from "react-bootstrap";
import "./EventsDetails.scss";
import Like from "../../Assets/img/icons/like.svg";
import Liked from "../../Assets/img/icons/liked.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LiveEventSIcon from "../../Assets/img/liveevents/LiveEventSIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  EventDetailApi,
  EventLikeApi,
  EventVideoProgressApi,
} from "../../Service/Events";
import moment from "moment";
import { EventFavSetSate } from "../../Utils/Events/Fav";
import { favCoursesetState } from "../../Utils/course/fav";
import { EventLikeSetState } from "../../Utils/Events/Like";
import { likeCourseSetState } from "../../Utils/course/like";
import DetailPopup from "./detailFormPopup";
import { toast } from "react-toastify";
import CongratsModel from "../../Common/CongratsPopoup/congratsModel";
import Vimeo from "@u-wave/react-vimeo";
import parse from "html-react-parser";
import CourseCard from "../../Common/CourseCard/CourseCard";
import { useDispatch } from "react-redux";
import { getCourse, getFavCourse } from "../../Redux/Course/CourseReducer";
import EventCard from "../../Common/EventCard/EventCard";
import {
  getFavEvent,
  getUpComingEvent,
} from "../../Redux/Events/EventsReducer";
import { ToEst } from "../../Utils/imageCrop/TimeConvert/convertToEst";
import isMebershipAndActiveCheck from "../../Utils/membership_check";
import MemberPopUp from "../../Utils/Popup/MemberPopUp";
import useModalWindowControls from "../../Hooks/useModal";

const eventType: any = {
  past: "tag5",
  Past: "tag5",
  live: "tag2",
  Live: "tag2",
};

const EventsDetails = () => {
  const param = useParams();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  let user: any = localStorage.getItem("Nurture_user_data");
  user = JSON.parse(user);
  const userID = user?.id;

  const [show, setShow] = useState<any>(false);
  const handleClose = () => {
    setShow(false);
  };

  const [data, setData] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [upcomingEvent, setUpcomingEvent] = useState<any>([]);
  const [courseData, setCourseData] = useState<any>([]);

  const getData = async () => {
    try {
      let data = {
        event_id: param?.id,
        user_id: userID,
      };
      let res: any = await EventDetailApi(data);
      isMebershipAndActiveCheck(res, Navigate, toast, dispatch);
      if (res?.data?.success) {
        setData(res?.data?.data);
        setUpcomingEvent(res.data.upcoming_event);
        setCourseData(res?.data?.course_rows);
      }
      // dispatch(getEventDetail(data))
    } catch (error) {
      console.log(error);
    }
  };
  const handleEventLike = async (datas: any) => {
    try {
      let newData = {
        user_id: userID,
        event_id: data?.id,
      };

      data.like_count = !data.like ? data.like_count + 1 : data.like_count - 1;

      data.like = !data.like;
      setData({ ...data });
      let res: any = await EventLikeApi(newData);
      if (!res?.data?.success) {
        data.like_count = !data.like
          ? data.like_count + 1
          : data.like_count - 1;
        data.like = !data.like;
        setData({ ...data });
      }
    } catch (err) {
      data.like_count = !data.like ? data.like_count + 1 : data.like_count - 1;

      data.like = !data.like;
      setData({ ...data });
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, [Navigate]);

  const trigPopup = () => {
    setOpen(true);
  };

  const courseLikeFav = (
    type: any,
    data: any,
    isUpdate: boolean,
    isSuccess: boolean
  ) => {
    if (isSuccess) {
      dispatch(getCourse({ user_id: userID }));
      dispatch(getFavCourse({ user_id: userID }));
      return;
    }
    if (type === "fav") {
      favCoursesetState(data, courseData, setCourseData);
    } else if (type === "like") {
      likeCourseSetState(data, courseData, setCourseData);
    }
  };

  const handleBack = () => {
    Navigate(-1);
  };

  const onVidePause = async (info: any) => {
    let videoProgress = await EventVideoProgressApi({
      user_id: userID,
      event_id: data?.id,
      duration: info.duration,
      progress: info.seconds,
    });
    console.log("-osofofofosofos==>", videoProgress);
  };

  const eventLikeFav = (
    type: string,
    crrdata: any,
    isUpdate: boolean,
    isSuccess: boolean
  ) => {
    if (isSuccess) {
      dispatch(getUpComingEvent());
      dispatch(getFavEvent());
      return;
    }
    if (type === "fav") {
      EventFavSetSate(crrdata, upcomingEvent, setUpcomingEvent);
    } else if (type === "like")
      EventLikeSetState(crrdata, upcomingEvent, setUpcomingEvent, isUpdate);
  };

  const memberPopUp = useModalWindowControls();

  const ddddHHmm = moment(ToEst(data?.event_date_time))?.format("dddd HH:mm");

  const qALinks: { [k: string]: string } = {
    "Monday 12:00": "https://us06web.zoom.us/j/83174618019",
    "Monday 15:00": "https://us06web.zoom.us/j/83174618019",
    "Thursday 14:30": "https://us06web.zoom.us/j/83342699096",
    "Tuesday 19:30": "https://us06web.zoom.us/j/81912272650",
    "Thursday 19:30": "https://us06web.zoom.us/j/86941252197",
    "Thursday 12:00": "https://us06web.zoom.us/j/83342699096",
  };

  let zoomLink = "";

  if (user?.is_stripe_membership && data?.title?.includes("Q&A")) {
    if (qALinks[ddddHHmm]) {
      zoomLink = qALinks[ddddHHmm];
    }
    if (
      // isNthDayOfWeek(new Date(data?.event_date_time), 5, 1) &&
      ddddHHmm === "Friday 12:00"
    ) {
      zoomLink = "https://us06web.zoom.us/j/89706440612";
    }
    if (
      isNthDayOfWeek(new Date(data?.event_date_time), 5, 2) &&
      ddddHHmm === "Friday 12:00"
    ) {
      zoomLink = "https://us06web.zoom.us/j/83375772350";
    }
    if (
      isNthDayOfWeek(new Date(data?.event_date_time), 5, 3) &&
      ddddHHmm === "Friday 12:00"
    ) {
      zoomLink = "https://us06web.zoom.us/j/87369244796";
    }
  }
  const showZoomButton = !!zoomLink;

  const joinEvent = () => {
    if (data?.id === 15) {
      window.open(
        "https://app.acuityscheduling.com/schedule.php?owner=14289123&appointmentType=44781345"
      );
      return;
    }
    user?.is_stripe_membership ? setShow(true) : memberPopUp.openModal();
  };

  return (
    <div className="events-detail-container">
      <CongratsModel
        open={open}
        setOpen={setOpen}
        text="Thanks for registering on this event"
      />
      <MemberPopUp
        closeModal={memberPopUp.closeModal}
        isVisible={memberPopUp.isVisible}
        title="Join our upcoming events!"
        description="Become a Nurture member to join our upcoming events!"
      />

      <DetailPopup
        show={show}
        setData={setData}
        setOpen={setOpen}
        trigPopup={trigPopup}
        handleClose={handleClose}
        value={data}
        toast={toast}
      />
      <Container fluid>
        <Row>
          <div className="col-lg-12 py-2">
            <div className="back-pg" onClick={handleBack}>
              {" "}
              <ArrowBackIosNewIcon />
              Back
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-lg-12 selected-event-fulldetail">
            <Container>
              <Row>
                <div className="col-lg-12">
                  <Row className="pt-3 pb-4">
                    <div className="col-lg-6 col-md-6">
                      <div className="d-felx flex-column selected-event-video-sec">
                        <div>
                          {data?.type == ("Past" || "past") ? (
                            <>
                              {data?.video ? (
                                <Vimeo
                                  className="w-100"
                                  video={data?.video}
                                  onPause={(e) => {
                                    onVidePause(e);
                                  }}
                                  onEnd={(e) => {
                                    onVidePause(e);
                                  }}
                                  onSeeked={(e) => {
                                    onVidePause(e);
                                  }}
                                  start={Number(
                                    data?.course_video_progress?.progress
                                  )}
                                  // width={ 100% }
                                  // video="https://vimeo.com/644908467"
                                />
                              ) : (
                                <img
                                  src={data?.image}
                                  className="img-responsive"
                                />
                              )}
                            </>
                          ) : (
                            <img src={data?.image} className="img-responsive" />
                          )}
                        </div>
                        <div className="live-event-footer d-flex justify-content-between my-3">
                          <div className="like">
                            <img
                              src={data?.like ? Liked : Like}
                              onClick={(datas) => handleEventLike(datas)}
                            />{" "}
                            {data?.like_count} Parents like this
                          </div>
                        </div>
                        <div>
                          {data?.event_video_progress && (
                            <ProgressBar
                              now={
                                (data?.event_video_progress?.progress *
                                  data?.event_video_progress?.duration) /
                                100
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 selected-event-details">
                      <div className="d-flex flex-column">
                        <div className="tags-row">
                          <span className={`${eventType[data.type]} tags`}>
                            {data.type === "Past" ? "Past Events" : data.type}
                          </span>
                        </div>
                        <div className="event-title my-2">
                          {/* Pediatric Dietitian, Ashley */}
                          {data?.title}
                        </div>
                        {data?.type === ("Live" || "live") && (
                          <div className="live-event-desc-sche py-3 px-5">
                            <div className="d-flex justify-content-center">
                              <div className="me-4">
                                <img src={LiveEventSIcon} />
                              </div>
                              <div className="event-time">
                                {moment(ToEst(data?.event_date_time)).format(
                                  "LLLL"
                                )}{" "}
                                EST <br />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="event-description">
                          <p>
                            {/* Help your family transition into toddlerhood with less meal time stress and more feeding success! Ashley will equip you with the feeding tools you need to tackle any meal time challenge. Learn how to apply her Love it, Like it, Learning it ® framework so that you feel confident in structuring meals, offering variety, and minimizing mental fatigue around food. You will leave the time together with expert advice and encouragement that keeps your family out of a feeding rut but rather moving forward to a healthier relationship with food.  Join us live so we can answer YOUR personal questions as well!Ashley is a Pediatric Dietitian, mom to 3 children and founder of @veggiesandvirtue (follow her page on Instagram!) */}
                            {parse(String(data?.description))}&nbsp;
                          </p>
                        </div>
                        {!showZoomButton &&
                          data?.type &&
                          data?.type !== ("Past" || "past") && (
                            <button
                              className="primary-blue-btn w-100"
                              disabled={
                                data?.is_book
                                  ? true
                                  : false ||
                                    (data?.type == ("past" || "Past") && true)
                              }
                              onClick={joinEvent}
                            >
                              {data?.is_book ? "Registered" : "Register Now"}
                            </button>
                          )}
                        {showZoomButton && (
                          <a href={zoomLink} target="_blank" rel="noreferrer">
                            <button className="primary-blue-btn w-100">
                              Join now
                            </button>
                          </a>
                        )}
                        {/* <button onClick={() => setShow(true)}>Fire popup</button> */}
                      </div>
                    </div>
                  </Row>
                </div>
              </Row>
            </Container>
          </div>
        </Row>
        <Row>
          <div className="col-lg-12 paddingLR-0">
            <div className="Upcoming-event-container pt-5 pb-3">
              <Container>
                <Row>
                  <div className="col-lg-12 text-center">
                    <h2 className="section-heading">
                      Upcoming <span className="head-bold">Events</span>
                    </h2>
                  </div>
                </Row>

                <EventCard data={upcomingEvent} upDateData={eventLikeFav} />
              </Container>
            </div>
          </div>
        </Row>
        <Row className="mt-3 mb-3">
          <div className="col-lg-12 get-readyf-baby-container pt-5 pb-3">
            <Container>
              <Row>
                <div className="col-lg-12 text-left">
                  <h2 className="section-heading mb-2 pb-0">
                    Get ready <span className="head-bold">for Baby!</span>
                  </h2>
                  <h4 className="mb-4">Courses for your stage</h4>
                </div>
              </Row>
              <Row>
                <CourseCard data={courseData} upDateData={courseLikeFav} />
              </Row>
              <Row>
                {/* <div className='col-lg-12 col-md-12 text-center'>
                  <button className='btn primary-blue-small-btn me-auto ms-auto'>View All</button>
                </div> */}
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
    </div>
  );
};

function isNthDayOfWeek(date: Date, dayOfWeek: number, nth: number) {
  const adjustedDayOfWeek = dayOfWeek % 7;

  if (date.getDay() !== adjustedDayOfWeek) {
    return false;
  }

  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstDayOccurrence =
    (7 + adjustedDayOfWeek - firstDayOfMonth.getDay()) % 7;
  const nthDayDate = 1 + firstDayOccurrence + 7 * (nth - 1);

  return date.getDate() === nthDayDate;
}

export default EventsDetails;
