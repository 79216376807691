import React from "react";
import { Modal } from "react-bootstrap";
import "./ConfirmModal.scss";

interface Props {
  children: React.ReactNode;
  isVisible: boolean;
  closeModal: () => void;
  confirmBtnTitle: string;
  confirmBtnWhiteTitle?: string;
  confirmBtnCB: () => void;
  confirmBtnWhiteCB: () => void;
}

const ConfirmModal = ({
  children,
  isVisible,
  closeModal,
  confirmBtnTitle = "Done",
  confirmBtnWhiteTitle = "Done",
  confirmBtnCB,
  confirmBtnWhiteCB,
}: Props) => {
  return (
    <Modal
      show={isVisible}
      onHide={() => {}}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="confirmModalWindow"
    >
      <Modal.Body className="confirmModal">
        <button className="confirmModalCross" onClick={closeModal}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5307 13.2694C16.1494 13.888 16.1494 14.912 15.5307 15.5307C15.2107 15.8507 14.8054 16 14.4 16C13.9947 16 13.5894 15.8507 13.2694 15.5307L8.00004 10.2614L2.7307 15.5307C2.4107 15.8507 2.00537 16 1.60004 16C1.1947 16 0.789371 15.8507 0.469371 15.5307C-0.149296 14.912 -0.149296 13.888 0.469371 13.2694L5.7387 8.00004L0.469371 2.7307C-0.149296 2.11204 -0.149296 1.08804 0.469371 0.469371C1.08804 -0.149296 2.11204 -0.149296 2.7307 0.469371L8.00004 5.7387L13.2694 0.469371C13.888 -0.149296 14.912 -0.149296 15.5307 0.469371C16.1494 1.08804 16.1494 2.11204 15.5307 2.7307L10.2614 8.00004L15.5307 13.2694V13.2694Z"
              fill="#FBF8EF"
            />
          </svg>
        </button>
        {children}
        <div>
          {confirmBtnWhiteTitle && (
            <button
              className="btn primary-blue-small-btn-40 white"
              onClick={confirmBtnWhiteCB}
            >
              {confirmBtnWhiteTitle}
            </button>
          )}

          <button
            className="btn primary-blue-small-btn-40"
            onClick={confirmBtnCB}
          >
            {confirmBtnTitle}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
