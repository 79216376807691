import React from "react";
import "./unswaddled.scss";
import { Link } from "react-router-dom";
import { PODCASTQUESTION } from "../../Routes/RouteConstent";
import musicSvg from "./music.svg";
import amazonMusicSvg from "./amazonMusic.svg";
import spotifySvg from "./spotify.svg";
import melodySvg from "./melody.svg";
import podcast from "../../Assets/img/Prelogin/unswaddled-main.jpg";

const Unswaddled = () => {
  return (
    <div className="unswaddledPodcast">
      <img
        className="unswaddledPodcastMelodySvgLayer"
        src={melodySvg}
        alt="melodySvg"
      />
      <div className="unswaddledPodcastLeftWrapper">
        <div className="unswaddledPodcastLayer">
          <svg
            viewBox="0 0 381 420"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M217.754 264.486C191.509 263.566 165.895 266.76 141.603 273.975C114.797 281.94 93.8085 293.696 79.1964 308.916C61.6591 327.198 53.4621 351.743 57.8175 372.966C58.0343 374.009 58.2801 375.054 58.5593 376.082C64.6219 398.393 85.4574 415.376 107.029 415.593C127.109 415.792 147.264 403.214 166.918 378.19C193.127 344.834 211.282 306.225 219.415 266.528L219.822 264.551L217.743 264.48L217.754 264.486ZM379.857 332.467C379.33 332.915 378.824 333.367 378.311 333.83C366.037 321.333 352.53 310.332 338.052 301.129C310.038 283.313 277.131 271.386 242.91 266.661C236.976 265.845 230.935 265.231 224.946 264.844L223.392 264.741L223.098 266.219C214.942 306.78 196.496 346.211 169.749 380.258C149.378 406.184 128.271 419.226 107.006 419.014C83.8967 418.792 61.5929 400.687 55.1315 376.908C54.8382 375.829 54.5759 374.723 54.3475 373.636C49.7706 351.353 58.3042 325.66 76.5989 306.586C91.6325 290.917 113.154 278.841 140.56 270.695C165.549 263.275 191.905 260.041 218.904 261.101L220.455 261.159L220.718 259.67C223.168 245.735 224.376 231.654 224.331 217.837C224.253 198.176 221.598 178.579 216.443 159.609C206.198 121.903 186.509 88.0481 159.506 61.7041C138.768 41.465 113.028 25.4896 85.0691 15.4885C57.2042 5.51872 27.6815 1.73116 -0.429451 4.50792L-0.83044 1.10202C27.8166 -1.7373 57.9105 2.11724 86.3036 12.2757C114.748 22.4487 140.928 38.7093 162.028 59.2992C189.464 86.0778 209.468 120.461 219.876 158.764C225.105 178.01 227.799 197.892 227.877 217.83C227.935 231.589 226.747 245.609 224.348 259.485L224.025 261.349L225.968 261.482C231.776 261.869 237.642 262.472 243.399 263.269C278.147 268.072 311.54 280.171 339.993 298.271C354.728 307.649 368.491 318.853 380.971 331.583C380.59 331.865 380.222 332.162 379.851 332.478"
              fill="#FBF8EF"
            />
          </svg>
        </div>
        <img src={podcast} alt="podcast" className="unswaddledPodcastImage" />
      </div>
      <div className="unswaddledPodcastTextWrapper">
        <div className="unswaddledPodcastContent">
          <h2 className="unswaddledPodcastTitle">
            <strong>Unswaddled:</strong> The podcast
          </h2>
          <div className="unswaddledPodcastRespondent">
            uncensored & unscripted
          </div>
          <p className="unswaddledPodcastP">
            Get even more up-close-and-personal with weekly hot takes from real
            moms, real nurses, and real NAPS co-founders, Emily and Jamie. Plus
            guests, answers to questions submitted by listeners, and the
            more-than-occasional f-bomb.
          </p>
          <div className="unswaddledPodcastButtons">
            <Link to={PODCASTQUESTION} className="unswaddledPodcastButtonAsk">
              Ask a Question
            </Link>
            <a
              className="unswaddledPodcastButtonSubscribe"
              href="https://podcasts.apple.com/us/podcast/unswaddled/id1560213381"
            >
              Subscribe to Podcast
            </a>
          </div>
          <div className="unswaddledPodcastSocial">
            <a
              href="https://podcasts.apple.com/us/podcast/unswaddled/id1560213381"
              target="_blank"
              rel="noreferrer"
            >
              <img src={musicSvg} alt="musicSvg" />
            </a>
            <a
              href="https://music.amazon.com/podcasts/a5f00c06-78bb-489f-8bff-f246f7d1a695/unswaddled"
              target="_blank"
              rel="noreferrer"
            >
              <img src={amazonMusicSvg} alt="amazonMusicSvg" />
            </a>
            <a
              href="https://open.spotify.com/show/6Kg6Bi7NZySf9BUoQlxIKn?si=4e92b521710c491e"
              target="_blank"
              rel="noreferrer"
            >
              <img src={spotifySvg} alt="spotifySvg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unswaddled;
