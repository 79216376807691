import React from 'react'
import { Container, Row, Carousel } from 'react-bootstrap';
import "./LactationConsult.scss";
import In_person_lactation from "../../../Assets/img/service/in-person-lactation.png";
import Virtual_consultation from "../../../Assets/img/service/virtual-lactation.png";
import { REMOTE_LACTATION_URL } from '../../../Utils/ServiceUrl';
import { useNavigate } from 'react-router-dom';
// import explainer_demo from '../../../Assets/img/membership-main/explainer-demo.png';
// import full_length_video from '../../../Assets/img/membership-main/full-length-video.png';
// import care from '../../../Assets/img/membership-main/care.png';
// import sp_discount from '../../../Assets/img/membership-main/sp-discount.png';
// import hi_fi from '../../../Assets/img/membership-main/hi-fi.png';
// import ask_nurse from '../../../Assets/img/membership-main//ask-nurse.png';
import trueSupportHero from '../../../Assets/img/janellecarmela-270.jpg'
import StreetCred from '../../../Common/streetCred/streetCred';
import LandingDescription from '../../../Common/LandingDescription';
import { HeroBannerHorizontal}  from '../../../Common/HeroBanner';
import hero from '../../../Assets/img/service/lactation.png';  //lactation-consult-banner.jpg';
import PinkContentBox from '../../../Common/PinkContentBox';

const LactationConsult = () => {
  const Navigate = useNavigate();


  let testimonialData = [
    {
      name:"Alyssa",
      data:"“I had a zoom lactation consult today with NAPSand it was beyond helpful! Katie was full of great advice and listened to my concerns with an open mind. She made me feel both heard and validated. I left our session with new strategies to try with regards to feeding and my infants sleep patterns, and an overall better feeling about being a first time mom. This support and professional guidance was exactly what I needed right now and I plan on meeting with her again in the future."
    },
    {
      name:"NA",
      data:"I can't give enough praise to our NAPS lactation consultant, Nicole. Nicole met with us the day after we got home from the hospital with our first baby, and helped us with breast feeding, bottle feeding (w/ formula), and pumping. Nicole is kind, supportive, non-judgmental, and full of tips and wisdom. Nicole is also extremely perceptive, and worked with our pediatrician directly to share her concerns about a medical issue with our daughter -- without her intervention, it would have been weeks before we were able to diagnose her. Thanks to Nicole we've had a successful feeding journey with our daughter and are so grateful! We're excited to continue to work with Nicole and NAPS as our daughter gets older and we work on a weaning plan!"
    }
  ]
  return (
    <div className='Lactationconsult-main'>

      <Container fluid>
        <div className="py-3">
          <HeroBannerHorizontal
            hero={hero}
            title={
            <>
              <div style={{color: '#30426D'}}>In-home or</div>
                <div className="strong" style={{color: '#30426D'}}>
                virtual lactation <div>support</div>
              </div>
            </>
            }
          />
        </div>

        <LandingDescription
          ySpace
          content={[
            <>
              Feeding can come with a lot of questions - big and small. How do I make breastfeeding 
              less painful? What if I need help with baby weight gain? Can I increase my supply 
              and how? Where do I start with pumping? Is there a best way to supplement with formula? 
              Do I want to wean and if so, how?
            </>,
            <>
              You’re in the right place because at NAPS, lactation support doesn’t just mean 
              just one thing. Our lactation board certified Registered Nurses will also support 
              pump setup, bottle/formula feeding, breastfeeding, soothing techniques, sleep advice, 
              or a combo of them all. Choose from an in-home consultation (for MA residents only) 
              or a virtual consultation below.
            </>,
          ]}
        />

        <PinkContentBox
          imgSrc={trueSupportHero}
          title={
            <>
              Lactation Support{" "}
              <div className="strong">
                We’ve seen it all except true feeding support!
              </div>
            </>
          }
          paragraph={
            <>
              <>We are changing what lactation support looks like, to make it actually supportive of your feeding choices. Where once, lactation consultants used to focus only on  breastfeeding, the emphasis is now, at least for us, FED IS BEST.</>
              <br/>
              <br/>
              <>The NAPS team of Lactation Consultants have redefined the role as all of our consultants are also Registered Nurses — this isn’t the norm everywhere, but we love that our team can offer you tons of advice based on a wide range of expertise.</>
            </>
          }
          btnClick={() => Navigate(REMOTE_LACTATION_URL)}
          btnText="Book now"
        />

        <Row className='pale-bg offering-main pb-4'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <Container>
              <Row className="justify-content-center my-5">
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className="section-header text-center">
                    <h2 className="home-section-title py-0">Our<span className="strong pink"> Offerings</span></h2>
                    {/* <p>NAPS offers Sleep Consultations in two different ways for children ages <span className='d-block'>0-3+</span></p> */}
                  </div>
                </div>
              </Row>

              <Row className='justify-content-center align-items-center mt-5'>
                <div className='col-lg-5 col-md-5 col-sm-12 order-1 order-md-0 mt-4 mt-md-0'>
                  <div className='offering-block-content me-0 me-md-5'>
                    <h3 className='lh-1 w-100 '>
                      <span className="offer-number">1. </span>
                      In-home lactation consultation
                    </h3>
                    <p>Schedule a 1:1 consultation in your home with a lactation board certified registered nurse. Address any feeding concerns or questions regardless of how small and big during this consultation. </p>
                    <button className="w-auto btn-small teal-btn-small" onClick={() => Navigate(REMOTE_LACTATION_URL)}>Learn More</button>
                  </div>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 order-0 order-md-1'>
                  <div className='service-feature-img'>
                    <img src={In_person_lactation} className='img-fluid' />
                  </div>
                </div>
              </Row>

              <Row className='justify-content-center align-items-center my-5'>
                <div className='col-lg-5 col-md-5 col-sm-12'>
                  <div className='service-feature-img me-0 me-md-5'>
                    <img src={Virtual_consultation} className='img-fluid' />
                  </div>
                </div>

                <div className='col-lg-5 col-md-5 col-sm-12 mt-4 mt-md-0'>
                  <div className='offering-block-content '>
                    <h3 className='lh-1 w-100'>
                      <span className="offer-number">2. </span>
                      Virtual Consultation
                    </h3>
                    <p>Schedule a 1:1 remote consultation with a lactation board certified registered nurse. Address any feeding concerns or questions regardless of how small or big this issue, and regardless of where you live during this consultation.</p>
                    <button className="w-auto btn-small teal-btn-small" onClick={() => Navigate(REMOTE_LACTATION_URL)}>Learn More</button>
                  </div>
                </div>
              </Row>


            </Container>
          </div>
        </Row>

        <StreetCred data={testimonialData}/>
      </Container>
    </div>
  )
}

export default LactationConsult